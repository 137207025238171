import React from 'react';

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (props) => (
    <svg
        id='Layer_1'
        fill='white'
        data-name='Layer 1'
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='23'
        viewBox='0 0 28 28'
    >
        <defs>
            <style className='cls-1'>fill={props.fill || '#fff'}</style>
        </defs>
        <path
            id='Path_2706'
            data-name='Path 2706'
            className='cls-1'
            d='M3,7.52l11.51,8,10.59-8v12l-4.49-6.3-1.11.84,4.94,6.94H3.69l5.43-6.9-1.07-.89L3,19.81Zm1.54-.63H23.65l-9.22,6.93ZM1.57,5.49v17H26.42v-17Z'
        />
    </svg>
);
