import './Loginstyle.css';
import slugs from 'resources/slugs';
import React, { useEffect, useState, useContext } from 'react';
import BackgroudImage from 'assets/images/BG_Image.png';
import SiemensLogo from 'assets/logo/SE_Logo_White_RGB.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from 'hooks/GlobalContext';
import UserPool from 'components/aws/UserPool';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import LoadingComponent from 'components/loading';
import { color } from 'resources/theme';
import IconVideo from 'assets/icons/icon-video';
import showEye from 'assets/images/showEye.svg';
import hideEye from 'assets/images/hideEye.svg';
import arrow from 'assets/images/arrow_sm.png';
import { Modal, Button } from 'react-bootstrap';
import IconEmail from 'assets/icons/icon-email';
import { getCurrentYear } from 'interface/projectInterface';
import {
    getVideo,
    acceptPrivacyNotice,
    updateDataPrivacy,
    updateUserLoginInfo
} from './LoginService';
/**
 * The LoginComponent realizes the Login Page.
 *
 * @author Sivaguru
 */
function LoginComponent() {
    const [state, setState] = useContext(GlobalContext);
    const [passwordShown, setPasswordShown] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const { push } = useHistory();
    const [showLoader, setLoaderState] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const videoModalClose = () => setShowVideoModal(false);
    const [userObj, setValues] = useState({
        email: '',
        password: ''
    });
    const [videoTour, setVideoTour] = useState('');
    const [cognitoPayload, setCognitoPayload] = useState({});
    const [showDataPrivacyModal, setShowDataPrivacyModal] = useState(false);
    const closeDataPrivacy = () => setShowDataPrivacyModal(false);
    const [enableContinueBtn, setEnableContinueBtn] = useState(false);

    const set = (name) => {
        return ({ target: { value } }) => {
            setValues((oldValues) => ({ ...oldValues, [name]: value }));
        };
    };

    /**
     * use to authenticate user
     * @param {event} event
     */
    const onSubmit = async (event) => {
        setLoaderState(true);
        event.preventDefault(); // Prevent default submission
        const user = new CognitoUser({
            Username: userObj.email,
            Pool: UserPool
        });

        const autheDetails = new AuthenticationDetails({
            Username: userObj.email,
            Password: userObj.password
        });

        user.authenticateUser(autheDetails, {
            onSuccess: (data) => {
                setLoaderState(false);
                var loginPayload = {
                    email: data.idToken.payload.email,
                    firstName: data.idToken.payload.given_name,
                    lastName: data.idToken.payload.family_name,
                    phoneNumber: data.idToken.payload.phone_number
                };
                acceptPrivacyNotice(loginPayload)
                    .then((response) => {
                        if (!response.data.dataPrivacyAccepted) {
                            setShowDataPrivacyModal(true);
                            setCognitoPayload(data.idToken);
                        } else {
                            setState({
                                ...state,
                                email: data.idToken.payload.email,
                                firstName: data.idToken.payload.given_name,
                                lastName: data.idToken.payload.family_name,
                                userIsLoggedIn: true,
                                dataPrivacy: true,
                                userType: data.idToken.payload['cognito:groups']
                            });
                            localStorage.setItem('dataPrivacy', true);
                            if (data.idToken.payload['cognito:groups'].includes('ROLE_USER')) {
                                updateUserLoginInfo()
                                    .then((response) => {
                                        console.log('Success');
                                    })
                                    .catch(function (error) {
                                        console.log(error.message);
                                    });
                            }
                        }
                    })
                    .catch(function (error) {
                        setErrorMessage(error.response.data.message);
                    });
            },
            onFailure: (err) => {
                setLoaderState(false);
                setErrorMessage('Incorrect username or password.');
            },
            newPasswordRequired: (data) => {
                setLoaderState(false);
                localStorage.setItem('cognitoUserSession', user.Session);
                push({
                    pathname: slugs.resetPassword,
                    state: { userObj: data }
                });
            },
            mfaRequired: (challengeName, challengeParameters) => {
                setLoaderState(false);
                localStorage.setItem('cognitoUserSession', user.Session);
                push({
                    pathname: slugs.verifyCode,
                    state: {
                        email: userObj.email,
                        number: challengeParameters.CODE_DELIVERY_DESTINATION
                    }
                });
            }
        });
    };

    /**
     * use for password eye hide/show
     */
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const videoLink = () => {
        setShowVideoModal(true);
    };
    const clickContinue = () => {
        updateDataPrivacy(userObj.email, true)
            .then((response) => {
                setState({
                    ...state,
                    email: cognitoPayload.payload.email,
                    firstName: cognitoPayload.payload.given_name,
                    lastName: cognitoPayload.payload.family_name,
                    userType: cognitoPayload.payload['cognito:groups'],
                    userIsLoggedIn: true,
                    dataPrivacy: true
                });

                localStorage.setItem('dataPrivacy', true);
            })
            .catch(function (error) {
                setErrorMessage(error.response.data.message);
            });
    };
    function checkPrivacy(e) {
        if (e.target.checked === true) {
            setEnableContinueBtn(true);
        } else {
            setEnableContinueBtn(false);
        }
    }
    useEffect(() => {
        getVideo()
            .then((response) => {
                if (response.data) {
                    setVideoTour(response.data.videoUrl);
                }
            })
            .catch(function (error) {
                setErrorMessage(error.message);
            });
    }, []);

    return (
        <div
            className='flex-container'
            style={{
                backgroundImage: 'url(' + BackgroudImage + ')',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className='boxContainer'>
                <img src={SiemensLogo} alt='Siemens Energy' />
            </div>
            <div style={{ marginRight: 'auto', marginLeft: '-100px' }}>
                <div className='login-container'>
                    <form
                        className='login'
                        style={{
                            background: `0% 0 % no - repeat padding- box ${color.lightGrayishPurple}`
                        }}
                        onSubmit={onSubmit}
                    >
                        <LoadingComponent loading={showLoader} fullScreen='false' />
                        <div>
                            <p className='welcomeText'> Welcome to </p>

                            <p className='appName'>EcoTransparency app</p>
                        </div>
                        <div
                            style={{
                                marginTop: '23px'
                            }}
                        >
                            <input
                                className='form-control col-10'
                                type='text'
                                placeholder='Email'
                                name='uname'
                                value={userObj.email}
                                onChange={set('email')}
                                data-testid='loginEmail'
                                required
                            />
                            <br></br>
                            <input
                                className='form-control col-10'
                                type={passwordShown ? 'text' : 'password'}
                                placeholder='Password'
                                name='psw'
                                value={userObj.password}
                                onChange={set('password')}
                                data-testid='loginPwd'
                                required
                            />
                            <span className='p-viewer'>
                                <div
                                    onClick={togglePasswordVisiblity}
                                    data-testid='eyeToggle'
                                    aria-hidden='true'
                                    className={'field-icon'}
                                >
                                    {passwordShown ? (
                                        <img src={showEye} style={{ cursor: 'pointer' }} />
                                    ) : (
                                        <img src={hideEye} style={{ cursor: 'pointer' }} />
                                    )}
                                </div>
                            </span>
                        </div>
                        <div>
                            {errorMessage && (
                                <div className='errorBackground'>
                                    <label>
                                        <h3 className='errorMessagetext'> {errorMessage} </h3>
                                    </label>
                                </div>
                            )}
                        </div>
                        <br></br>
                        <div className='form-group'>
                            <button
                                className='btn btn-lg btn-primary'
                                type='submit'
                                data-testid='loginsubmitClick'
                            >
                                Log in <ArrowForwardIosIcon className='forwardIcon' />
                            </button>
                            &nbsp;
                            <a className='forgotPwdlink' href={slugs.forgotPassword}>
                                Forgot password?
                            </a>
                            <span className='row'>
                                <a
                                    className='videoText'
                                    onClick={videoLink}
                                    data-testid='linkVideo'
                                >
                                    <IconVideo></IconVideo>&nbsp;&nbsp;EcoTransparency app at a
                                    glance <img src={arrow} className='moreArrow' />
                                </a>{' '}
                            </span>
                            <span
                                className='row'
                                style={{
                                    marginTop: '3%'
                                }}
                            >
                                <a
                                    className='moreLink'
                                    href='mailto:kirsten.maciejczyk@siemens-energy.com;jessica.kilb.ext@siemens-energy.com'
                                    data-testid='linkmore'
                                >
                                    <IconEmail></IconEmail>&nbsp;&nbsp;Get in touch: SE EQS
                                    <img src={arrow} className='moreArrow' />
                                </a>
                            </span>
                            {/* </div> */}
                        </div>
                    </form>
                </div>

                <Modal show={showVideoModal} centered onHide={videoModalClose}>
                    <Modal.Header closebutton='true'>
                        <button
                            type='button'
                            className='close'
                            data-dismiss='modal'
                            onClick={videoModalClose}
                            data-testid='closeVideo'
                        >
                            &times;
                        </button>
                    </Modal.Header>
                    <video width='auto' height='235px' controls>
                        <source src={videoTour} type='video/mp4'></source>
                    </video>
                </Modal>
                <Modal
                    show={showDataPrivacyModal}
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                    size='lg'
                    onHide={closeDataPrivacy}
                >
                    <Modal.Header closebutton='true'>
                        <Modal.Title>Data privacy agreement</Modal.Title>{' '}
                        <button
                            type='button'
                            className='close'
                            data-dismiss='modal'
                            onClick={closeDataPrivacy}
                            data-testid='closePopupClick'
                        >
                            &times;
                        </button>
                    </Modal.Header>

                    <Modal.Body>
                        I hereby voluntarily consent to the collection and processing of my personal
                        data as described herein in the{' '}
                        <a
                            style={{
                                font: 'normal normal normal 14px/29px Siemens Sans',
                                letterSpacing: '0px',
                                color: color.darkGrayishPurple
                            }}
                            href='https://www.siemens.com/global/en/general/privacy-notice.html'
                            target='_blank'
                        >
                            privacy notice. 
                        </a>
                        <br></br>
                        <br></br>
                        Protecting the security and privacy of your personal data including
                        sensitive personal data is important to Siemens. Therefore, Siemens or a
                        third party engaged by the Siemens Group shall process your data in
                        accordance with the applicable Data Privacy & Protection Laws & Regulations.
                        Unless indicated otherwise at the time of collection of your personal data,
                        we erase your personal data if the retention of that personal data is no
                        longer necessary (i) for the purposes for which they were collected or
                        otherwise processed or (ii) to comply with legal obligations.  This
                        information shall only be available with the core team and treated
                        confidentially.
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='checkboxSection'>
                            <input
                                type='checkbox'
                                onChange={(e) => checkPrivacy(e)}
                                data-testid='privacyChanges'
                            />{' '}
                            I agree to the above mentioned Terms and Conditions.
                        </div>
                        <Button
                            variant='secondary'
                            disabled={!enableContinueBtn}
                            onClick={() => clickContinue()}
                            data-testid='acceptClick'
                        >
                            Continue
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className='footerText'>
                <p className='col-6 companyFootertxt'>
                    Siemens Energy is a trademark licensed by Siemens AG.
                </p>
                <p className='col-6 copyRightFooter'>© {getCurrentYear()} Siemens Energy </p>
            </div>
        </div>
    );
}

export default LoginComponent;
