import { Row } from 'simple-flexbox';
import React from 'react';
import SiemensLogo from 'assets/logo/SE_Logo_White_RGB.png';
import { color } from 'resources/theme';
import { GlobalContext } from 'hooks/GlobalContext';
import { NavDropdown, Nav, Modal } from 'react-bootstrap';
import IconLogout from 'assets/icons/icon-logout';
import IconUser from 'assets/icons/icon-user';
import IconEquipmentDetails from 'assets/icons/icon-equipment-details';
import IconContentManage from 'assets/icons/icon-content-manage';
import IconEmission from 'assets/icons/icon-emission';
import IconFAQS from 'assets/icons/icon-faqs';
import IconEvaluation from 'assets/icons/icon-evaluation';
import { useHistory } from 'react-router';
import UserPool from 'components/aws/UserPool';
import AboutIcon from 'assets/icons/icon-aboutus';
import { getArticleDocument } from './HeaderService';
import LoadingComponent from 'components/loading';
import EmissionCalculator from 'components/emissionCalculator/EmissionCalculator';
import slugs from 'resources/slugs';

/**
 * The Header Component is a shared component between all pages. It displays
 * the related header title of the selected section in the SidebarComponent changes.
 *
 * @author Abhishek
 * @returns the header title, subtitles related to the selected section in the SidebarComponent. It also displays the user name in the header bar as well.
 */

function HeaderComponent() {
    const [state, setState] = React.useContext(GlobalContext);
    const [showLoader, setLoaderState] = React.useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const history = useHistory();

    const logoutApp = async (event) => {
        event.preventDefault();
        const cognitoUser = UserPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.signOut();
            setState({ ...state, userIsLoggedIn: false });
            localStorage.removeItem('dataPrivacy');
        }
    };
    const handleClose = () => setModalShow(false);
    const userName = state.firstName + ' ' + state.lastName;

    const usersPage = () => {
        history.push('/list-user');
    };
    const redirectHome = () => {
        history.push('/dashboard');
    };
    const equipmentPage = () => {
        history.push('/equipment-settings');
    };
    const aboutusPage = () => {
        history.push('/about-us');
    };
    const contentManage = () => {
        history.push(slugs.manageContent);
    };
    const circularityConfig = () => {
        history.push(slugs.circularityConfig);
    };
    const evaluationLink = () => {
        history.push('/evaluation');
    };
    function getArticleDocumentClick(documents) {
        setLoaderState(true);
        getArticleDocument(documents)
            .then((response) => {
                const file = new Blob([response], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                // window.open(fileURL);
                // create <a> tag dinamically
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;

                // it forces the name of the downloaded file
                fileLink.download = documents;

                // triggers the click event
                fileLink.click();
                setLoaderState(false);
            })
            .catch(function (error) {
                setLoaderState(false);
            });
    }

    return (
        <Row
            vertical='center'
            horizontal='space-between'
            style={{
                background: `0% 0% no-repeat padding-box ${color.blackish}`,
                height: 50,
                position: 'fixed',
                width: '100%',
                left: 0,
                top: 0,
                zIndex: 1
            }}
        >
            <div className='HeaderTitle' vertical='center' horizontal='space-between'>
                <div className='LogoImgcontainer'>
                    <img src={SiemensLogo} alt='Siemens Energy' />
                </div>
                <LoadingComponent loading={showLoader} fullScreen='false' />
            </div>
            <div>
                <a href='' className='TitleText' data-testid='titleClick' onClick={redirectHome}>
                    EcoTransparency app
                </a>
            </div>

            <Row vertical='baseline' horizontal='flex-start'>
                <div className='HeaderIconSyle'>
                    <NavDropdown title='Help' id='basic-nav-dropdown' data-testid='Help'>
                        <Nav className='navDropdown'>
                            {' '}
                            <NavDropdown.Item
                                data-testid='userManualClick'
                                onClick={() =>
                                    getArticleDocumentClick('EcoTransparency_App_User_Manual.pdf')
                                }
                            >
                                <IconContentManage></IconContentManage>&nbsp;User manual
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => setModalShow(true)}
                                data-testid='emissionClick'
                            >
                                <IconEmission></IconEmission>&nbsp;Emission calculator
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                onClick={() =>
                                    getArticleDocumentClick('Frequently_Asked_Questions.pdf')
                                }
                                data-testid='FAQClick'
                            >
                                <IconFAQS></IconFAQS>&nbsp;Frequently asked questions
                            </NavDropdown.Item>
                        </Nav>
                    </NavDropdown>
                </div>
                <div>
                    {
                        <Nav className='HeaderUserName'>
                            <NavDropdown
                                title={userName}
                                id='basic-nav-dropdown'
                                data-testid='username'
                            >
                                <Nav className='navDropdown'>
                                    {state.userType[0] && state.userType[0] === 'ROLE_ADMIN' ? (
                                        <NavDropdown.Item
                                            onClick={usersPage}
                                            data-testid='userManagementClick'
                                        >
                                            <IconUser></IconUser>&nbsp;User management
                                        </NavDropdown.Item>
                                    ) : (
                                        ''
                                    )}
                                    <NavDropdown.Divider />
                                    {state.userType[0] && state.userType[0] === 'ROLE_ADMIN' ? (
                                        <NavDropdown.Item
                                            onClick={equipmentPage}
                                            data-testid='equipmentDetailsClick'
                                        >
                                            <IconEquipmentDetails></IconEquipmentDetails>
                                            &nbsp;Equipment management
                                        </NavDropdown.Item>
                                    ) : (
                                        ''
                                    )}
                                    <NavDropdown.Divider />
                                    {state.userType[0] && state.userType[0] === 'ROLE_ADMIN' ? (
                                        <NavDropdown.Item
                                            onClick={contentManage}
                                            data-testid='contentManagementClick'
                                        >
                                            <IconContentManage></IconContentManage>&nbsp;Content
                                            management
                                        </NavDropdown.Item>
                                    ) : (
                                        ''
                                    )}
                                    <NavDropdown.Divider />
                                    {state.userType[0] && state.userType[0] === 'ROLE_ADMIN' ? (
                                        <NavDropdown.Item
                                            onClick={circularityConfig}
                                            data-testid='ciircularityConfigClick'
                                        >
                                            <IconEquipmentDetails></IconEquipmentDetails>
                                            &nbsp;Circularity management
                                        </NavDropdown.Item>
                                    ) : (
                                        ''
                                    )}
                                    <NavDropdown.Divider />
                                    {state.userType[0] && state.userType[0] === 'ROLE_ADMIN' ? (
                                        <NavDropdown.Item
                                            onClick={evaluationLink}
                                            data-testid='evaluationClick'
                                        >
                                            <IconEvaluation></IconEvaluation>&nbsp;Evaluation
                                        </NavDropdown.Item>
                                    ) : (
                                        ''
                                    )}
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={aboutusPage} data-testid='aboutUs'>
                                        <AboutIcon></AboutIcon>&nbsp;About us
                                    </NavDropdown.Item>
                                    <NavDropdown.Divider />

                                    <NavDropdown.Item onClick={logoutApp} data-testid='logoutclick'>
                                        <IconLogout></IconLogout>&nbsp;Logout
                                    </NavDropdown.Item>
                                </Nav>
                            </NavDropdown>
                        </Nav>
                    }
                </div>
                <Modal
                    size='md'
                    aria-labelledby='contained-modal-title-vcenter'
                    show={modalShow}
                    onHide={handleClose}
                    dialogClassName='border-radius-2'
                    centered
                >
                    <Modal.Body style={{ backgroundColor: '#641e8c' }}>
                        <button
                            type='button'
                            className='close'
                            data-dismiss='modal'
                            onClick={handleClose}
                            data-testid='closeEmissionPopup'
                        >
                            &times;
                        </button>
                        <EmissionCalculator></EmissionCalculator>
                    </Modal.Body>
                </Modal>
            </Row>
        </Row>
    );
}

export default HeaderComponent;
