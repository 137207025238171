import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import './Evaluation.css';
import { getActiveUsers } from './EvaluationService';

function ActiveUsersChart() {
    const [activeUsersValue, setActiveusersChart] = useState([]);
    useEffect(() => {
        getActiveUsers()
            .then((response) => {
                setActiveusersChart(response.data);
            })
            .catch(function (error) {
                console.log(error.message);
            });
    }, []);

    const series = [{ name: 'Active Users', data: activeUsersValue }];
    const options = {
        chart: {
            type: 'line',
            toolbar: { show: false },
            foreColor: '#fff'
        },

        fill: {
            opacity: 1
        },

        dataLabels: {
            enabled: false
        },
        grid: {
            show: false
        },
        legend: {
            show: true
        },
        stroke: {
            show: true,
            curve: 'straight'
        },
        xaxis: {
            type: 'category',
            labels: {
                style: {
                    textAlign: 'center',
                    fontSize: '14px',
                    fontFamily: 'Siemens Sans',
                    letterSpacing: '0px',
                    color: '#FFFFFF'
                }
            },
            responsive: [{ breakpoint: 300, options: { chart: { width: 500 } } }]
        },
        yaxis: {
            show: true
        }
    };
    return (
        <div className='lineChartBackground' id='areaChart'>
            <ReactApexChart options={options} series={series} type='line' height={250} />
        </div>
    );
}
export default ActiveUsersChart;
