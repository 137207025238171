import React, { useRef } from 'react';
import ReactSelect from 'react-select';

export const MultiSelect = (props) => {
    const valueRef = useRef(props.value);
    valueRef.current = props.value;

    const selectAllOption = {
        value: '<SELECT_ALL>',
        label: 'Select all categories'
    };

    const isSelectAllSelected = () => valueRef.current.length === props.options.length;

    const isOptionSelected = (option) =>
        valueRef.current.some(({ value }) => value === option.value) || isSelectAllSelected();

    const getOptions = () => {
        if (props.isSelectEquipment === undefined || !props.isSelectEquipment) {
            return [selectAllOption, ...props.options];
        }
        return props.options;
    };

    const getValue = () => {
        if (props.isSelectEquipment === undefined || !props.isSelectEquipment) {
            return isSelectAllSelected() ? [selectAllOption] : props.value;
        }
        return props.value;
    };

    const onChange = (newValue, actionMeta) => {
        const { action, option, removedValue } = actionMeta;
        if (action === 'select-option' && option.value === selectAllOption.value) {
            props.onChange(props.options, actionMeta);
        } else if (
            (action === 'deselect-option' && option.value === selectAllOption.value) ||
            (action === 'remove-value' && removedValue.value === selectAllOption.value)
        ) {
            props.onChange([], actionMeta);
        } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected()) {
            props.onChange(
                props.options.filter(({ value }) => value !== option.value),
                actionMeta
            );
        } else {
            props.onChange(newValue || [], actionMeta);
        }
    };

    return (
        <div data-testid='content-select'>
            <ReactSelect
                isOptionSelected={isOptionSelected}
                options={getOptions()}
                value={getValue()}
                onChange={onChange}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                data-testid='changeMultiselect'
                isMulti
                styles={
                    props.value.length === 0 &&
                    props.isSelectEquipment &&
                    !props.showErrorHighlight &&
                    !props.clearValidation
                        ? {
                              control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: !state.isFocused ? 'red' : '#4D217A',
                                  '&:hover': {
                                      border: '1px solid #4D217A',
                                      boxShadow: '0px 0px 6px #4D217A'
                                  },
                                  '&:active': {
                                      border: '1px solid #4D217A',
                                      boxShadow: '0px 0px 6px #4D217A'
                                  }
                              })
                          }
                        : {
                              control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: '#4D217A',

                                  '&:hover': {
                                      border: '1px solid #4D217A',
                                      boxShadow: '0px 0px 6px #4D217A'
                                  },
                                  '&:active': {
                                      border: '1px solid #4D217A',
                                      boxShadow: '0px 0px 6px #4D217A'
                                  }
                              })
                          }
                }
            />
        </div>
    );
};
