/**
 * The projectInterface is the interface between frontend and backend.
 *
 * @author Sivaguru, Abhishek
 */

import theme from 'resources/theme';
import IconHome from 'assets/icons/icon-home';
import arrow from 'assets/images/Arrow.png';
import { useHistory } from 'react-router';
import React from 'react';
/**
 * * QUESTION: life cycle stages fixed?
 * @param modelName name of the model, which we want to get the Data
 */

export function getImpactCategoriesTableHeaders(modelName) {
    return [
        { key: 'header-1', value: 'Model' },
        { key: 'header-2', value: 'Materials' },
        { key: 'header-3', value: 'Manufacturing and transport' },
        { key: 'header-4', value: 'Operation and service' },
        { key: 'header-5', value: 'End of life' }
    ];
}

export function categoryPercentage(base, scenario) {
    if (base > scenario) {
        return (
            Math.abs(((base - scenario) / base) * 100).toFixed(0) +
            '% reduced from baseline scenario'
        );
    } else {
        return (
            Math.abs(((scenario - base) / base) * 100).toFixed(0) +
            '% increased from baseline scenario'
        );
    }
}
export function getModelHeader(headerName) {
    return [
        { key: 'header-1', value: 'Baseline' },
        { key: 'header-2', value: 'Scenario 1' },
        { key: 'header-3', value: 'Scenario 2' }
    ];
}
export function getFormattedDate(date) {
    let currentDate = new Date(date);
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    return month.toString().padStart(2, '0') + '/' + year.toString();
}
export function getFormattedDateandMonth(date) {
    let currentDate = new Date(date);
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    let monthName = getMonthName(month);
    return monthName + ' ' + year.toString();
}
export function getCurrentYear() {
    return new Date().getFullYear();
}
function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    // Using the english locale.
    return date.toLocaleString(['en'], { month: 'long' });
}
function impactPreviewPercentage(base, scenario) {
    if (base > scenario) {
        return '&darr;' + ' ' + Math.abs(((base - scenario) / base) * 100).toFixed(0) + '%';
    } else if (base < scenario) {
        return '&uarr;' + ' ' + Math.abs(((scenario - base) / base) * 100).toFixed(0) + '%';
    } else {
        return '';
    }
}
export function getColors() {
    return [
        '#8A00E5',
        '#6ADDFF',
        '#FFFFFF',
        '#4A1F75',
        '#685289',
        '#9287A6',
        '#AFA8BC',
        '#C7C3D0',
        '#AF5BED',
        '#FCFCB5',
        '#FFF91F',
        '#E2D000',
        '#C6AE00',
        '#83FFBE',
        '#00FD79',
        '#14DA79',
        '#27B66D',
        '#9D3BE9',
        '#21C0FF',
        '#0084E1',
        '#0057C6',
        '#009999'
    ];
}

export function getMaterialCompostionOptions(chartId) {
    return {
        maintainAspectRatio: true,
        legend: {
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Siemens Sans',
            letterSpacing: '0px',
            labels: {
                colors: '#FFF',
                useSeriesColors: false
            },
            opacity: 1,
            markers: { radius: 1 }
        },
        chart: {
            type: 'donut',
            id: chartId,
            height: 400,
            width: 400,
            background:
                'transparent radial-gradient(closest-side at 25% 52%, #502B80 0%, #070A2C 100%) 0% 0% no-repeat padding-box'
        },
        pie: { expandOnClick: true },
        labels: [],
        dataLabels: {
            fontSize: '20px',
            fontFamily: 'Siemens Sans',
            dropShadow: {
                enabled: true,
                left: 1,
                top: 1,
                blur: 2,
                opacity: 50
            }
        },
        colors: [],
        stroke: {
            show: false
        }
    };
}
export function GetUserManagementBreadCrumb(action) {
    const history = useHistory();
    return (
        <nav aria-label='breadcrumb'>
            <ol className='breadcrumb'>
                <li
                    className='breadcrumb-item'
                    data-testid='id'
                    onClick={() => history.push('/dashboard')}
                >
                    <IconHome></IconHome>
                </li>
                <img src={arrow} />
                <li
                    className='breadcrumb-item'
                    style={{ marginLeft: '5px' }}
                    aria-current='page'
                    onClick={() => history.push('/list-user')}
                >
                    User management
                </li>
                <img src={arrow} />
                <li
                    className='breadcrumb-item'
                    style={{ marginLeft: '5px', cursor: 'default' }}
                    aria-current='page'
                >
                    {action} user
                </li>
            </ol>
        </nav>
    );
}

export function getImpactAssementOptions(
    chartId,
    defaultScenario,
    isBaseline,
    scenarioImpactChartHeader,
    materialCompositionChoice
) {
    return {
        colors: isBaseline
            ? [
                  theme.uniformStyle.color.barChartColorFirst,
                  theme.uniformStyle.color.barChartColorSecond
              ]
            : [theme.uniformStyle.color.barChartColorSecond],
        chart: {
            type: 'bar',
            //will be reverted when multichart scenario will clearified
            //height: defaultScenario && materialCompositionChoice ? 550 : 900,
            height: 900,
            toolbar: {
                show: false
            },
            id: chartId,
            background:
                'transparent radial-gradient(closest-side at 25% 52%, #502B80 0%, #070A2C 100%) 0% 0% no-repeat padding-box'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    position: 'top'
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val !== 0 && val <= 0.01 ? '<0.01' : val.toLocaleString('en');
            },
            offsetY: -35,
            offsetX: 0,
            style: { fontSize: '26px', colors: ['#FFF'] },
            dropShadow: {
                enabled: true,
                left: 1,
                top: 2,
                opacity: 5
            }
        },
        grid: {
            show: false
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            fontSize: '26px',
            horizontalAlign: 'left',
            position: 'top',
            offsetY: -12,
            fontFamily: 'Siemens Sans',
            fontWeight: 'normal',
            formatter: function (seriesName, opts) {
                return seriesName === 'Baseline'
                    ? seriesName
                    : seriesName +
                          ' ' +
                          impactPreviewPercentage(
                              opts.w.globals.seriesTotals[0],
                              opts.w.globals.seriesTotals[opts.seriesIndex]
                          );
            },
            labels: {
                colors: '#FFF',
                useSeriesColors: false
            }
        },
        stroke: {
            show: true,
            width: 10,
            colors: ['transparent']
        },
        yaxis: {
            show: false,
            labels: {
                formatter: function (y) {
                    if (y) {
                        return y !== 0 && y <= 0.01 ? '<0.01' : y.toLocaleString('en');
                    }
                }
            }
        },
        xaxis: {
            type: 'category',
            labels: {
                style: {
                    colors: ['#FFF', '#FFF', '#FFF', '#FFF'],
                    textAlign: 'center',
                    fontSize: '26px',
                    fontFamily: 'Siemens Sans',
                    letterSpacing: '0px'
                }
            },
            responsive: [{ breakpoint: 300, options: { chart: { width: 500 } } }]
        },
        title: {
            text: scenarioImpactChartHeader
                ? scenarioImpactChartHeader.impactCategoryName +
                  ' [' +
                  scenarioImpactChartHeader.Unit +
                  ']'
                : '',
            align: 'left',
            offsetX: 5,
            offsetY: 10,
            style: {
                fontSize: '26px',
                fontFamily: 'Siemens Sans',
                color: '#fff',
                fontWeight: 'normal'
            }
        }
    };
}

export function getLifeCycleChartOptions(
    chartId,
    defaultScenario,
    isBaseline,
    lifeCycleChartValues
) {
    var reduceBar = lifeCycleChartValues.map((dataObj) => {
        return dataObj.data.length === 1 || dataObj.data.length === 2 ? true : false;
    });

    return {
        colors: isBaseline
            ? [
                  theme.uniformStyle.color.barChartColorFirst,
                  theme.uniformStyle.color.barChartColorSecond
              ]
            : [theme.uniformStyle.color.barChartColorSecond],

        chart: {
            type: 'bar',
            height: 600,
            toolbar: { show: false },
            id: chartId,
            background:
                'transparent radial-gradient(closest-side at 25% 52%, #502B80 0%, #070A2C 100%) 0% 0% no-repeat padding-box'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: reduceBar.includes(true) ? 20 : 75,
                dataLabels: {
                    position: 'top'
                }
            }
        },
        fill: {
            opacity: 10
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val !== 0 && val <= 0.01 ? '<0.01' : val.toLocaleString('en');
            },
            offsetY: -35,
            offsetX: 0,
            style:
                chartId === 'materialLifeCycleId'
                    ? { fontSize: '22px', colors: ['#FFF'] }
                    : { fontSize: '26px', colors: ['#FFF'] },
            dropShadow: {
                enabled: true,
                left: 1,
                top: 2,
                opacity: 5
            }
        },
        grid: {
            show: false
        },
        yaxis: {
            show: false
        },
        stroke: {
            show: true,
            width: 10,
            colors: ['transparent']
        },
        legend: {
            show: true,
            fontSize: '26px',
            showForSingleSeries: true,
            horizontalAlign: 'left',
            position: 'top',
            offsetY: -12,
            fontFamily: 'Siemens Sans',
            fontWeight: 'normal',
            labels: {
                colors: '#FFF',
                useSeriesColors: false
            }
        },
        xaxis: {
            labels: {
                rotate: 0,
                style: {
                    textAlign: 'center',
                    fontSize: '26px',
                    fontFamily: 'Siemens Sans',
                    letterSpacing: '0px',
                    colors: [
                        '#FFF',
                        '#FFF',
                        '#FFF',
                        '#FFF',
                        '#FFF',
                        '#FFF',
                        '#FFF',
                        '#FFF',
                        '#FFF',
                        '#FFF',
                        '#FFF',
                        '#FFF',
                        '#FFF',
                        '#FFF'
                    ]
                }
            }
        },
        title: {
            text: 'Global warming potential [ton CO2 eq.]',
            align: 'left',
            offsetX: 5,
            offsetY: 10,
            style: {
                fontSize: '26px',
                fontFamily: 'Siemens Sans',
                color: '#fff',
                fontWeight: 'normal'
            }
        }
    };
}
