import React from 'react';

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (props) => (
    <svg width='20' height='20' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_355_986)'>
            <path
                d='M18.5 2.51515V1H9.5V2.89394C9.5 3.9382 10.3412 4.78788 11.375 4.78788H16.625C17.5305 4.78788 18.2878 4.1361 18.4621 3.27273H22.25V23.7273H23V2.51515H18.5ZM17.75 2.89394C17.75 3.52057 17.2454 4.0303 16.625 4.0303H11.375C10.7546 4.0303 10.25 3.52057 10.25 2.89394V1.75758H17.75V2.89394ZM5.75 25.2424H23V26H5V2.51515H8V3.27273H5.75V25.2424ZM13.25 13.2781L11.1099 11.1163L11.6401 10.5807L13.25 12.2068L16.7349 8.68673L17.2651 9.22236L13.25 13.2781ZM11.1099 18.6921L11.6401 18.1564L13.25 19.7826L16.7349 16.2625L17.2651 16.7981L13.25 20.8538L11.1099 18.6921Z'
                fill='#1B1534'
            />
        </g>
        <defs>
            <clipPath id='clip0_355_986'>
                <rect width='28' height='28' fill='white' />
            </clipPath>
        </defs>
    </svg>
);
