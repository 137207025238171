import './Loginstyle.css';
import { color } from 'resources/theme';
import slugs from 'resources/slugs';
import { GlobalContext } from 'hooks/GlobalContext';
import React, { useState, useContext } from 'react';
import BackgroudImageLayout from 'assets/images/BG_Image.png';
import SiemensEnergyLogo from 'assets/logo/SE_Logo_White_RGB.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, useHistory } from 'react-router-dom';
import UserPool from 'components/aws/UserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Modal, Button } from 'react-bootstrap';
import { acceptPrivacyNotice, updateDataPrivacy } from './LoginService';
import { getCurrentYear } from 'interface/projectInterface';
import showEye from 'assets/images/showEye.svg';
import hideEye from 'assets/images/hideEye.svg';

function ResetPassword() {
    const [state, setState] = useContext(GlobalContext);
    const [allValues, togglePwdVisiblity] = React.useState({ psw: false, confirmPwd: false });
    const [errorMessage, setErrorMessage] = React.useState('');
    const [userObj, setValues] = useState({
        newPassword: ''
    });
    const userData = useLocation();
    const { push } = useHistory();
    const [showPrivacyDialog, setShowPrivacyDialog] = useState(false);
    const [showContinueButton, setShowContinueButton] = useState(false);
    const [cognitoResponse, setCognitoResponse] = useState({});
    const handleClose = () => setShowPrivacyDialog(false);

    /**
     * use to set form values with state obj
     * @param {name} name
     * @returns
     */
    const set = (name) => {
        return ({ target: { value } }) => {
            setValues((oldValues) => ({ ...oldValues, [name]: value }));
        };
    };

    /**
     * use for new password request
     * @param {event} event
     */
    const onSubmit = async (event) => {
        event.preventDefault();
        var email = userData.state.userObj.email;
        var newPassword = userObj.newPassword;
        var confirmPassword = userObj.confirmPwd;
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: UserPool
        });

        cognitoUser.Session = localStorage.getItem('cognitoUserSession');
        if (newPassword !== confirmPassword) {
            setErrorMessage('Password does not match');
        } else {
            cognitoUser.completeNewPasswordChallenge(newPassword, [], {
                onSuccess: (result) => {
                    localStorage.removeItem('cognitoUserSession');
                    var resetObj = {
                        email: result.idToken.payload.email,
                        firstName: result.idToken.payload.given_name,
                        lastName: result.idToken.payload.family_name,
                        phoneNumber: result.idToken.payload.phone_number
                    };
                    acceptPrivacyNotice(resetObj)
                        .then((response) => {
                            if (!response.data.dataPrivacyAccepted) {
                                setShowPrivacyDialog(true);
                                setCognitoResponse(result.idToken);
                            } else {
                                setState({
                                    ...state,
                                    email: result.idToken.payload.email,
                                    firstName: result.idToken.payload.given_name,
                                    lastName: result.idToken.payload.family_name,
                                    userType: result.idToken.payload['cognito:groups'],
                                    userIsLoggedIn: true,
                                    dataPrivacy: true
                                });
                                localStorage.setItem('dataPrivacy', true);
                            }
                        })
                        .catch(function (error) {
                            setErrorMessage(error.response.data.message);
                        });
                },
                onFailure: (err) => {
                    setErrorMessage(err.message);
                },
                mfaRequired: (challengeName, challengeParameters) => {
                    localStorage.setItem('cognitoUserSession', cognitoUser.Session);
                    push({
                        pathname: slugs.verifyCode,
                        state: {
                            email: email,
                            number: challengeParameters.CODE_DELIVERY_DESTINATION
                        }
                    });
                }
            });
        }
    };
    const clickContinueBtn = () => {
        updateDataPrivacy(cognitoResponse.payload.email, true)
            .then((response) => {
                setState({
                    ...state,
                    email: cognitoResponse.payload.email,
                    firstName: cognitoResponse.payload.given_name,
                    lastName: cognitoResponse.payload.family_name,
                    userType: cognitoResponse.payload['cognito:groups'],
                    userIsLoggedIn: true,
                    dataPrivacy: true
                });
                localStorage.setItem('dataPrivacy', true);
            })
            .catch(function (error) {
                setErrorMessage(error.response.data.message);
            });
    };
    function clickDataPrivacy(e) {
        if (e.target.checked === true) {
            setShowContinueButton(true);
        } else {
            setShowContinueButton(false);
        }
    }
    return (
        <div
            className='flex-container'
            style={{
                backgroundImage: 'url(' + BackgroudImageLayout + ')',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className='resetBoxContainer'>
                <img src={SiemensEnergyLogo} alt='Siemens Energy' />
            </div>

            <div style={{ marginRight: 'auto', marginLeft: '-115px' }}>
                <form
                    className='resetPwd'
                    action={slugs.categories}
                    onSubmit={onSubmit}
                    style={{
                        background: `0% 0 % no - repeat padding- box ${color.lightGrayishPurple}`
                    }}
                >
                    <div className='resetPwd-container'>
                        <div className='form-group col-12 align-self-center'>
                            <p className='welcomeText'>Welcome to </p>
                            <p className='welcomeEcoText'>EcoTransparency app </p>
                            <p className='welcomeText'> Please reset your password </p>
                            <p className='infoText'>• Min 8 characters</p>
                            <p className='infoText'>• At least one uppercase letter</p>
                            <p className='infoText'>• At least one lowercase letter</p>
                            <p className='infoText'>• At least one numeric number</p>
                            <p className='infoText'>• At least one special character</p>
                            <input
                                className='form-control'
                                type={allValues.psw ? 'text' : 'password'}
                                placeholder='New Password'
                                name='newPassword'
                                onChange={set('newPassword')}
                                data-testid='pwdChange'
                                required
                            />
                            <span className='pwd3-viewer'>
                                <div
                                    onClick={() => {
                                        togglePwdVisiblity((prevState) => ({
                                            psw: !prevState.psw
                                        }));
                                    }}
                                    data-testid='eyeToggleon'
                                    aria-hidden='true'
                                    className={'field-icon'}
                                >
                                    {allValues.psw ? (
                                        <img src={showEye} style={{ cursor: 'pointer' }} />
                                    ) : (
                                        <img src={hideEye} style={{ cursor: 'pointer' }} />
                                    )}
                                </div>
                            </span>
                            <br></br>
                            <input
                                className='form-control'
                                type={allValues.confirmPwd ? 'text' : 'password'}
                                placeholder='Confirm Password'
                                name='confirmPwd'
                                onChange={set('confirmPwd')}
                                data-testid='cnfpwdChange'
                                required
                            />
                            <span className='pwd4-viewer'>
                                <div
                                    onClick={() => {
                                        togglePwdVisiblity((prevState) => ({
                                            confirmPwd: !prevState.confirmPwd
                                        }));
                                    }}
                                    data-testid='eyeToggleoff'
                                    aria-hidden='true'
                                    className={'field-icon'}
                                >
                                    {allValues.confirmPwd ? (
                                        <img src={showEye} style={{ cursor: 'pointer' }} />
                                    ) : (
                                        <img src={hideEye} style={{ cursor: 'pointer' }} />
                                    )}
                                </div>
                            </span>
                            <br></br>
                            {errorMessage && (
                                <div>
                                    <div className='errorBackground'>
                                        <label>
                                            <h3 className='errorMessagetext'> {errorMessage} </h3>
                                        </label>
                                    </div>
                                    <br></br>
                                    <br></br>
                                </div>
                            )}
                            <button
                                className='btn btn-primary btnPos'
                                type='submit'
                                data-testid='submitVerifyCode'
                            >
                                {' '}
                                Log in <ArrowForwardIosIcon className='forwardIcon' />
                            </button>
                            <br></br>
                            <br></br>
                            <div>
                                <a className='goBackButton' href={slugs.login}>
                                    {' '}
                                    <ArrowBackIosIcon className='backwardIcon' /> Go back
                                </a>
                            </div>
                        </div>
                    </div>
                    <Modal
                        show={showPrivacyDialog}
                        aria-labelledby='contained-modal-title-vcenter'
                        centered
                        size='lg'
                        onHide={handleClose}
                    >
                        <Modal.Header closebutton='true'>
                            <Modal.Title>Data privacy agreement</Modal.Title>{' '}
                            <button
                                type='button'
                                className='close'
                                data-dismiss='modal'
                                onClick={handleClose}
                                data-testid='closePopupClick'
                            >
                                &times;
                            </button>
                        </Modal.Header>

                        <Modal.Body>
                            I hereby voluntarily consent to the collection and processing of my
                            personal data as described herein in the{' '}
                            <a
                                style={{
                                    font: 'normal normal normal 14px/29px Siemens Sans',
                                    letterSpacing: '0px',
                                    color: color.darkGrayishPurple
                                }}
                                href='https://www.siemens.com/global/en/general/privacy-notice.html'
                                target='_blank'
                            >
                                privacy notice. 
                            </a>
                            <br></br>
                            <br></br>
                            Protecting the security and privacy of your personal data including
                            sensitive personal data is important to Siemens. Therefore, Siemens or a
                            third party engaged by the Siemens Group shall process your data in
                            accordance with the applicable Data Privacy & Protection Laws &
                            Regulations. Unless indicated otherwise at the time of collection of
                            your personal data, we erase your personal data if the retention of that
                            personal data is no longer necessary (i) for the purposes for which they
                            were collected or otherwise processed or (ii) to comply with legal
                            obligations.  This information shall only be available with the core
                            team and treated confidentially.
                        </Modal.Body>

                        <Modal.Footer>
                            <div className='checkboxSection'>
                                <input
                                    type='checkbox'
                                    onChange={(e) => clickDataPrivacy(e)}
                                    data-testid='acceptPrivacy'
                                />{' '}
                                I agree to the above mentioned Terms and Conditions.
                            </div>
                            <Button
                                variant='secondary'
                                disabled={!showContinueButton}
                                onClick={() => clickContinueBtn()}
                                data-testid='continueEvent'
                            >
                                Continue
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </form>
            </div>
            <div className='footerText'>
                <p className='col-6 companyFootertxt'>
                    Siemens Energy is a trademark licensed by Siemens AG.
                </p>
                <p className='col-6 copyRightFooter'>© {getCurrentYear()} Siemens Energy </p>
            </div>
        </div>
    );
}

export default ResetPassword;
