import React from 'react';

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (props) => (
    <svg
        id='Layer_1'
        data-name='Layer 1'
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 22 22'
    >
        <defs>
            <style className='cls-1'>fill={props.fill || '#1a1433'}</style>
        </defs>
        <path
            className='cls-1'
            d='M18.48,19.45V4.69L14.38.6H3.52V21.4h15v-.65H4.18V1.25h9.75v3.9h3.9v14.3ZM14.57,4.5V1.71l2.8,2.79Z'
        />
        <rect className='cls-1' x='6.12' y='7.1' width='9.75' height='0.65' />
        <rect className='cls-1' x='6.13' y='9.05' width='9.75' height='0.65' />
        <rect className='cls-1' x='6.13' y='11' width='5.85' height='0.65' />
    </svg>
);
