import React from 'react';
import { getCurrentYear } from 'interface/projectInterface';

function FooterComponent() {
    return (
        <div className='footerExample'>
            <p>Siemens Energy is a trademark licensed by Siemens AG</p>
            <p className='copytext'>© {getCurrentYear()} Siemens Energy</p>
        </div>
    );
}
export default FooterComponent;
