import React, { useState } from 'react';
import './EmissionCalculator.css';
import { useForm } from 'react-hook-form';

function EmissionCalculator() {
    const { handleSubmit, register } = useForm();
    const [showValue, setShowvalue] = React.useState(false);
    const [costValue, setCostvalue] = useState();
    const handleChange = (e) => {
        setShowvalue(false);
    };
    const onSubmit = (data) => {
        let costData = data.equivalent * data.cost;
        setShowvalue(true);
        setCostvalue(costData);
    };
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='form-group'>
                    <div className='col-12'>
                        <h3 className='dialogHeader'>Emission calculator</h3>
                        <hr style={{ backgroundColor: 'white' }}></hr>
                        <label className='emissionLabel'>
                            Ton CO<sub>2</sub> equivalent
                        </label>
                        <input
                            name='equivalent'
                            type='number'
                            data-testid='equivalent'
                            onKeyUp={(e) => handleChange(e)}
                            min='0'
                            max='99999'
                            step='0.01'
                            className='form-control'
                            {...register('equivalent')}
                            required
                        />
                    </div>
                    <br></br>
                    <div className='col-12'>
                        <label className='emissionLabel'>
                            CO<sub>2</sub> cost per ton
                        </label>
                        <input
                            name='cost'
                            type='number'
                            data-testid='cost'
                            onKeyUp={(e) => handleChange(e)}
                            {...register('cost')}
                            step='0.01'
                            max='500'
                            min='0'
                            className='form-control'
                            required
                        />
                    </div>
                    <br></br>
                    <div className='text-center'>
                        <button type='submit' data-testid='submitCalId' className='btn btn-primary'>
                            Calculate
                        </button>
                    </div>
                    <br></br>

                    {showValue ? (
                        <div>
                            <div className='card' style={{ marginTop: '1px', padding: '20px' }}>
                                <label className='emissionCost'>Total emission cost is</label>
                                <hr className='textLine'></hr>
                                <label className='emissionCostFont'>{costValue} €</label>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </form>
        </div>
    );
}
export default EmissionCalculator;
