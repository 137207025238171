import axios from 'axios';
import { getAuthHeader } from 'interface/AuthHeader';

export async function getCircularitytemplate() {
    var token = await getAuthHeader().then((val) => {
        return val;
    });

    return axios
        .get(process.env.REACT_APP_SERVER_URL + '/circular/templateDownload', { headers: token })
        .then((response) => response.data);
}

export async function uploadMeasures(data) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .post(process.env.REACT_APP_SERVER_URL + '/circular/addMeasures', data, {
            headers: {
                Authorization: token.Authorization,
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => response.data);
}
