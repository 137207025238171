import UserPool from 'components/aws/UserPool';

async function getSession() {
    let result = await new Promise((resolve, reject) => {
        const cognitoUser = UserPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    reject();
                } else {
                    resolve(session);
                }
            });
        } else {
            reject();
        }
    });
    return result;
}
export { getSession };
