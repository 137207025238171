import React from 'react';

export default (props) => (
    <svg width='16' height='19' viewBox='0 0 16 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M16 14V0H5V2H14V14H16Z' fill='#8A00E5' />
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M12 4H0V19H12V4ZM10 6V17H2V6H10Z'
            fill='#8A00E5'
        />
    </svg>
);
