import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './Evaluation.css';

function EquipmentDurationChart() {
    const series = [
        {
            data: [
                {
                    x: 'January',
                    y: 22
                },
                {
                    x: 'February',
                    y: 55
                },
                {
                    x: 'March',
                    y: 11
                },
                {
                    x: 'April',
                    y: 77
                },
                {
                    x: 'May',
                    y: 33
                },
                {
                    x: 'June',
                    y: 20
                },
                {
                    x: 'July',
                    y: 99
                },
                {
                    x: 'August',
                    y: 66
                },
                {
                    x: 'September',
                    y: 89
                },
                {
                    x: 'October',
                    y: 18
                },
                {
                    x: 'November',
                    y: 88
                },
                {
                    x: 'December',
                    y: 0
                }
            ]
        }
    ];
    const options = {
        chart: {
            type: 'line',
            toolbar: { show: false },
            foreColor: '#fff'
        },

        fill: {
            opacity: 1
        },

        dataLabels: {
            enabled: false
        },
        grid: {
            show: false
        },
        legend: {
            show: true
        },
        stroke: {
            show: true,
            curve: 'straight'
        },
        xaxis: {
            type: 'category',
            labels: {
                style: {
                    textAlign: 'center',
                    fontSize: '14px',
                    fontFamily: 'Siemens Sans',
                    letterSpacing: '0px',
                    color: '#FFFFFF'
                }
            },
            responsive: [{ breakpoint: 300, options: { chart: { width: 500 } } }]
        },
        yaxis: {
            show: true
        }
    };
    return (
        <div className='lineChartBackground' id='areaChart'>
            <ReactApexChart options={options} series={series} type='line' height={330} />
        </div>
    );
}
export default EquipmentDurationChart;
