import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import IconHome from 'assets/icons/icon-home';
import arrow from 'assets/images/Arrow.png';
import { getMetricInfo } from './EvaluationService';
import './Evaluation.css';
import AwsCognitoIdentityServiceProvider from 'components/aws/AwsCognitoIdentityServiceProvider';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ActiveUsersChart from './ActiveUsersChart';
import EquipmentStatsChart from './EquipmentStatsChart';
import EquipmentDurationChart from './EquipmentDurationChart';
import LoadingComponent from 'components/loading';

function Evaluation() {
    const history = useHistory();
    const [activeUser, setActiveUser] = useState(0);
    const [registeredUser, setRegistereduser] = useState(0);
    const [activeEvaluationTab, setActiveEvaluationTab] = React.useState(1);
    const [showLoader, setLoaderState] = React.useState(false);
    const [currentYear, setCurrentYear] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [activeDurationTab, setActiveDurationTab] = React.useState(1);
    const [totalEquipments, setTotalEquipments] = useState(0);
    const [totalFeedbackCounts, setFeedbackCount] = useState(0);
    const [unVerifyPdfGenerate, setUnVerifyPDFCount] = useState(0);
    const [verifyPdfGenerate, setVerifyPDFCount] = useState(0);

    const getTotalUsers = async () => {
        setLoaderState(true);
        var params = {
            UserPoolId: process.env.REACT_APP_USERPOOL_ID,
            GroupName: 'ROLE_USER',
            Limit: 60
        };

        let totalActiveUsers = 0;

        do {
            var token = null;
            var countData = await getUsersCount(params);
            totalActiveUsers = totalActiveUsers + countData.Users.length;
            params.NextToken = countData.NextToken;
            token = countData.NextToken;
        } while (token);

        setRegistereduser(totalActiveUsers);
        setLoaderState(false);
    };
    function getUsersCount(reqParam) {
        return new Promise((resolve, reject) => {
            AwsCognitoIdentityServiceProvider.listUsersInGroup(reqParam, (err, result) => {
                if (err) {
                    reject(err);
                    return;
                }
                resolve(result);
            });
        });
    }

    useEffect(() => {
        getMetricInfo()
            .then((response) => {
                setActiveUser(response.data.activeUsersCount);
                setTotalEquipments(response.data.totalEquipment);
                setFeedbackCount(response.data.totalFeedbackCount);
                setUnVerifyPDFCount(response.data.unVerifyPdfGenerateCount);
                setVerifyPDFCount(response.data.verifyPdfGenerateCount);
            })
            .catch(function (error) {
                setErrorMessage(error.message);
            });
        getTotalUsers();
        getDate();
    }, []);

    const evaluationTabClick = (idValue) => {
        setActiveEvaluationTab(idValue);
    };
    function getDate() {
        const today = new Date();
        const month = today.toLocaleString('default', { month: 'long' });
        const year = today.getFullYear();
        const presentYear = month + ' ' + year;
        setCurrentYear(presentYear);
    }
    const updateDurationTab = (value) => {
        setActiveDurationTab(value);
    };
    return (
        <div className='m-10'>
            <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                    <li
                        className='breadcrumb-item'
                        data-testid='id'
                        onClick={() => history.push('/dashboard')}
                    >
                        <IconHome></IconHome>
                    </li>
                    <img src={arrow} />
                    <li
                        className='breadcrumb-item'
                        style={{ marginLeft: '5px', cursor: 'default' }}
                        aria-current='page'
                    >
                        Evaluation
                    </li>
                </ol>
            </nav>
            <div className='card-body'>
                <LoadingComponent loading={showLoader} fullScreen='false' /> <h2>Evaluation</h2>
                <br></br>
                <div>
                    {errorMessage && (
                        <div>
                            <label className='text-danger'> {errorMessage}</label>
                        </div>
                    )}
                </div>
                <div className='col-12'>
                    <div className='shadow p-3 bg-body rounded row'>
                        <div className='col-2 verticalLine'>
                            <label className='equipmentFont'>{totalEquipments}</label>
                            <br></br>Number of equipments
                        </div>
                        <div className='col-2 '>
                            <label className='usersFont'> {registeredUser}</label>
                            <br></br>Total registered users
                        </div>
                        <div className='col-2 verticalLine'>
                            <label className='usersFont'> {activeUser}</label>
                            <br></br>Active users ({currentYear})
                        </div>
                        <div className='col-2 verticalLine'>
                            <label className='feedbackFont'> {totalFeedbackCounts}</label>
                            <br></br>Feedback received
                        </div>
                        <div className='col-2'>
                            <label className='pdfFont'>{verifyPdfGenerate}</label>
                            <br></br>Pdf generated (Verified equipment)
                        </div>
                        <div className='col-2'>
                            {' '}
                            <label className='pdfFont'>{unVerifyPdfGenerate}</label>
                            <br></br>Pdf generated (Unverified equipment)
                        </div>
                    </div>
                </div>
                <Tabs>
                    <TabList>
                        <Tab
                            className={
                                activeEvaluationTab !== 1
                                    ? 'react-tabs__tab1UnSelcted'
                                    : 'react-tabs__tab1'
                            }
                            onClick={() => evaluationTabClick(1)}
                            data-testid='activeuserTab'
                        >
                            Active users
                        </Tab>
                        <Tab
                            className={
                                activeEvaluationTab !== 2
                                    ? 'react-tabs__tab1UnSelcted'
                                    : 'react-tabs__tab1'
                            }
                            // onClick={() => evaluationTabClick(2)}
                            data-testid='featuresTab'
                        >
                            Features
                        </Tab>
                        <Tab
                            className={
                                activeEvaluationTab !== 3
                                    ? 'react-tabs__tab1UnSelcted'
                                    : 'react-tabs__tab1'
                            }
                            onClick={() => evaluationTabClick(3)}
                            data-testid='equipmentTab'
                        >
                            Equipment
                        </Tab>
                    </TabList>
                    <TabPanel></TabPanel>
                    <TabPanel></TabPanel>
                    <TabPanel></TabPanel>
                </Tabs>
                <br></br>
                {activeEvaluationTab === 1 && (
                    <div>
                        <h3 className='chartTitle'>Active users this year</h3>
                        <br></br>
                        <ActiveUsersChart />
                    </div>
                )}
                {activeEvaluationTab === 3 && (
                    <div className='row'>
                        <div className='col-4'>
                            <div>
                                <Tabs>
                                    <TabList>
                                        <Tab
                                            className={
                                                activeDurationTab === 1
                                                    ? 'react-tabs__evaluation--selectedTab'
                                                    : 'react-tabs__evalationTab'
                                            }
                                            onClick={() => updateDurationTab(1)}
                                            data-testid='durationTab4'
                                        >
                                            This month
                                        </Tab>
                                        &nbsp;&nbsp;&nbsp;
                                        <Tab
                                            className={
                                                activeDurationTab === 2
                                                    ? 'react-tabs__evaluation--selectedTab'
                                                    : 'react-tabs__evalationTab'
                                            }
                                            onClick={() => updateDurationTab(2)}
                                            data-testid='durationTab5'
                                        >
                                            Last 6 months
                                        </Tab>
                                        &nbsp;&nbsp;&nbsp;
                                        <Tab
                                            className={
                                                activeDurationTab === 3
                                                    ? 'react-tabs__evaluation--selectedTab'
                                                    : 'react-tabs__evalationTab'
                                            }
                                            onClick={() => updateDurationTab(3)}
                                            data-testid='durationTab6'
                                        >
                                            This year
                                        </Tab>
                                    </TabList>
                                    <TabPanel></TabPanel>
                                </Tabs>
                            </div>
                            <br></br>
                            <EquipmentStatsChart />
                        </div>
                        <div className='col-8'>
                            <div className='d-inline-flex align-items-center chartLayout'>
                                <label className='chartLabel'>Equipment</label>&nbsp;&nbsp; &nbsp;{' '}
                                <select className='form-control'>
                                    <option>Select equipments</option> Select equipments
                                </select>
                            </div>

                            <EquipmentDurationChart />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
export default Evaluation;
