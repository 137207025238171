import React from 'react';

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='18.111'
        height='20.736'
        viewBox='0 0 18.111 20.736'
    >
        <g transform='translate(-15.875 -13.875)'>
            <path
                d='M21.639,23.656c0,.838.445,1.354,1.168,1.354a1.733,1.733,0,0,0,.885-.242l.182.516a2.376,2.376,0,0,1-1.1.242,1.687,1.687,0,0,1-1.812-1.855,1.719,1.719,0,0,1,1.779-1.908,2.523,2.523,0,0,1,1.093.243l-.214.5a1.767,1.767,0,0,0-.847-.227C22.079,22.281,21.639,22.813,21.639,23.656Zm5.445-1.312a2.06,2.06,0,0,1,.413,1.3,1.986,1.986,0,0,1-.541,1.444,1.571,1.571,0,0,1-1.179.438,1.551,1.551,0,0,1-1.308-.58,2.059,2.059,0,0,1-.413-1.323A2,2,0,0,1,24.6,22.2a1.57,1.57,0,0,1,1.179-.438A1.544,1.544,0,0,1,27.084,22.344Zm-.263,1.3c0-.838-.4-1.365-1.045-1.365s-1.045.527-1.045,1.349c0,.854.4,1.381,1.045,1.381S26.822,24.483,26.822,23.646Zm1.039.538.1.358a1.253,1.253,0,0,1,.568-.169c.209,0,.343.1.343.253s-.107.322-.364.559c-.2.179-.429.364-.649.522v.348h1.5v-.348h-.884a6.243,6.243,0,0,0,.5-.437.922.922,0,0,0,.364-.68c0-.358-.279-.569-.756-.569A1.734,1.734,0,0,0,27.86,24.183Zm-9.105,2.642a2.613,2.613,0,0,1-.328-5.067l.275-.091-.085-.272a5.181,5.181,0,0,1-.235-1.542A5.373,5.373,0,0,1,28.3,17.087l.506-.308a5.971,5.971,0,0,0-11.016,3.074,5.748,5.748,0,0,0,.185,1.45A3.206,3.206,0,0,0,16,24.243,3.238,3.238,0,0,0,18.64,27.4ZM33.861,21.9a4.433,4.433,0,0,0-4.465-4.39V18.1a3.842,3.842,0,0,1,3.87,3.8,3.772,3.772,0,0,1-1.406,2.934l-.188.152.114.211a2.273,2.273,0,0,1,.289,1.092,2.364,2.364,0,0,1-2.382,2.341,2.4,2.4,0,0,1-.942-.192l-.319-.135-.087.331a4.667,4.667,0,0,1-2.132,2.822l-.181.109.045.2a1.72,1.72,0,0,1,.043.375,1.784,1.784,0,0,1-3.565.064L22.549,32l-.2-.061a4.716,4.716,0,0,1-3.26-3.475l-.581.125a5.3,5.3,0,0,0,3.474,3.847,2.381,2.381,0,0,0,4.738-.293,2.181,2.181,0,0,0-.02-.3,5.247,5.247,0,0,0,2.134-2.758,2.992,2.992,0,0,0,.863.126,2.956,2.956,0,0,0,2.977-2.927,2.8,2.8,0,0,0-.254-1.157A4.345,4.345,0,0,0,33.861,21.9Z'
                transform='translate(0)'
                fill='#1b1534'
                stroke='#1b1534'
                strokeWidth='0.25'
            />
        </g>
    </svg>
);
