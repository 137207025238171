import React, { useEffect, useState } from 'react';
import LoadingComponent from 'components/loading';
import IconHome from 'assets/icons/icon-home';
import IconCopy from 'assets/icons/icon-copy';
import arrow from 'assets/images/Arrow.png';
import { useHistory } from 'react-router';
import { getAllEquipmentLists } from '../equipments/EquipmentService';
import IconTabinfo from 'assets/icons/icon-tabInfo';
import downloadIcon from 'assets/images/pdfgenerate.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Col, Row } from 'react-bootstrap';
import { getCircularitytemplate, uploadMeasures } from './CircularityService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import './CircularityConfig.css';

function CircularityConfig() {
    const history = useHistory();

    const [showLoader, setLoaderState] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [equipmentList, setEquipmentList] = React.useState([]);
    const [copiedSessionId, setCopiedSessionId] = useState('');
    const [templateData, setTemplateData] = useState();
    const [baselines, setBaselines] = useState();

    const [baselineMap, setBaselineMap] = useState({});
    const [showData, setShowData] = useState(false);
    const [relatedScenarios, setRelatedScenarios] = useState();
    const [selectedEquipmentId, setSelectedEquipmentId] = useState();
    const validationSchema = Yup.object().shape({
        excelsheet: Yup.mixed()
            .required('Excel file is required')
            .test('fileSize', 'File Size is too large', (item) => {
                if (item.length !== 0) {
                    const sizeInBytes = 5245676; //5MB
                    return item[0].size <= sizeInBytes;
                } else {
                    return true;
                }
            })
            .test('fileType', 'Only Excel files are allowed', (item) => {
                if (item && item.length !== 0) {
                    const fileType = item[0].type;
                    return (
                        fileType ===
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        fileType === 'application/vnd.ms-excel'
                    );
                } else {
                    return true;
                }
            })
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    useEffect(() => {
        setSuccessMessage('');
        setErrorMessage('');
        setLoaderState(true);
        getAllEquipmentLists()
            .then((response) => {
                setEquipmentList(response.data);
                transformData(response.data);
                setLoaderState(false);
            })
            .catch(function (error) {
                setLoaderState(false);
                setErrorMessage(error.response.data.message);
            });

        getCircularitytemplate()
            .then((response) => {
                setTemplateData(response.data);
                setLoaderState(false);
            })
            .catch(function (error) {
                setLoaderState(false);
                setErrorMessage(error.response.data.message);
            });
    }, []);

    const transformData = (data) => {
        const baselines = data.filter((equipment) => equipment.isBaseline);
        setBaselines(baselines);
        const nonBaselines = data.filter((equipment) => !equipment.isBaseline);

        const baselineMap = {};

        baselines.forEach((baseline) => {
            baselineMap[baseline.id] = {
                baseline,
                scenarios: nonBaselines.filter((scenario) => scenario.name === baseline.name)
            };
        });

        setBaselineMap(baselineMap);
    };

    const changeEquipmentStatus = (e) => {
        setShowData(true);
        setSuccessMessage('');
        setErrorMessage('');
        if (e.target.value) {
            const listObj = JSON.parse(e.target.value);
            const equipment = equipmentList.find((u) => u.id === listObj.id);
            setSelectedEquipmentId(equipment.id);

            if (equipment.isBaseline) {
                setRelatedScenarios(baselineMap[equipment.id].scenarios);
            } else {
                const baseline = baselines.find((baseline) => baseline.name === equipment.name);
                setRelatedScenarios(
                    baselineMap[baseline.id].scenarios.filter((u) => u.id !== baseline.id)
                );
            }
        } else {
            setShowData(false);
        }
    };
    function onSubmit(data) {
        setSuccessMessage('');
        setErrorMessage('');
        setLoaderState(true);
        var obj = { id: selectedEquipmentId };
        const formData = new FormData();
        formData.append('data', JSON.stringify(obj));

        if (data.excelsheet.length === 0) {
            setErrorMessage('Please upload XLS file');
            setLoaderState(false);
        } else if (data.excelsheet.length !== 0) {
            formData.append('file', data.excelsheet[0], data.excelsheet[0].name);
            addMeasures(formData);
        }

        function addMeasures(formObj) {
            uploadMeasures(formObj)
                .then((response) => {
                    setSuccessMessage(response.message);
                    setLoaderState(false);
                    reset();
                })
                .catch(function (error) {
                    setLoaderState(false);
                    setErrorMessage(error.response.data.message);
                });
        }
    }
    return (
        <div className='m-10'>
            <LoadingComponent loading={showLoader} fullScreen='false' />
            <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                    <li
                        className='breadcrumb-item'
                        style={{ marginLeft: '12px' }}
                        data-testid='id'
                        onClick={() => history.push('/dashboard')}
                    >
                        <IconHome></IconHome>
                    </li>
                    <img src={arrow} />
                    <li
                        className='breadcrumb-item'
                        style={{ marginLeft: '5px', cursor: 'default' }}
                        aria-current='page'
                    >
                        Circularity management
                    </li>
                </ol>
            </nav>
            <div className='card-body'>
                <h2 style={{ marginLeft: '-5px' }}>Circularity management</h2>
                <br></br>
                <div>
                    {successMessage && (
                        <div>
                            <label className='text-success'> {successMessage}</label>
                        </div>
                    )}
                </div>
                <div>
                    {errorMessage && (
                        <div>
                            <label className='text-danger' style={{ whiteSpace: 'pre-line' }}>
                                {' '}
                                {errorMessage}
                            </label>
                        </div>
                    )}
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-row'>
                        <div className='form-group' data-testid='content-select'>
                            <label className='labelBold'>Select equipment</label>
                            <label style={{ color: 'red' }}>
                                <b>*</b>
                            </label>
                            <select
                                data-testid='equipment_testId'
                                name='equipment'
                                {...register('equipment')}
                                className={'form-control'}
                                onChange={(e) => changeEquipmentStatus(e)}
                            >
                                <option value=''>Select equipments </option>
                                {equipmentList.map((list) => (
                                    <option
                                        key={list.externalId}
                                        value={JSON.stringify(list)}
                                        className={`${list.isBaseline ? 'selectFont' : ''}`}
                                    >
                                        {!list.isBaseline
                                            ? list.name + '(Scenario) :' + list.scenarioName
                                            : list.name}
                                    </option>
                                ))}
                            </select>
                            <div className='invalid-feedback'>{errors.equipment?.message}</div>
                        </div>
                    </div>
                    {showData && relatedScenarios.length > 0 && (
                        <div>
                            <div className='session-list'>
                                <Row>
                                    {relatedScenarios.map((session) => (
                                        <Col key={session.id} xs='auto' className='mb-2'>
                                            <div className='session-item d-flex align-items-center copyTextButton'>
                                                <CopyToClipboard
                                                    text={session.externalId}
                                                    onCopy={() => setCopiedSessionId(session.id)}
                                                >
                                                    <div style={{ cursor: 'pointer' }}>
                                                        <IconCopy></IconCopy>
                                                        {'  '}
                                                        <span>{session.scenarioName}</span>
                                                    </div>
                                                </CopyToClipboard>
                                                {copiedSessionId === session.id && (
                                                    <span className='ml-2 text-success'>
                                                        Copied!
                                                    </span>
                                                )}
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>

                            <div className='form-row'>
                                <div className='form-group'>
                                    <label className='labelBold'>Circularity measures</label>
                                    <label style={{ color: 'red' }}>
                                        <b>*</b>
                                    </label>
                                    <input
                                        data-testid='excelFile'
                                        name='excelsheet'
                                        type='file'
                                        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv'
                                        {...register('excelsheet')}
                                        className={`form-control ${
                                            errors.excelsheet ? 'is-invalid' : ''
                                        }`}
                                    />
                                    <div className='invalid-feedback'>
                                        {errors.excelsheet?.message}
                                    </div>
                                </div>
                                &nbsp;&nbsp;
                                <img
                                    className='topMargin'
                                    src={downloadIcon}
                                    style={{ objectFit: 'none' }}
                                />
                                <a
                                    className='template align-self-center topMargin'
                                    href={templateData}
                                    download
                                >
                                    Download template
                                </a>
                                &nbsp;&nbsp;
                                <span
                                    className='align-self-center topMargin'
                                    title='Please click "Download template" link to download the required file
                            format for adding measures.'
                                >
                                    <IconTabinfo className='topMargin'></IconTabinfo>
                                </span>
                            </div>
                            <div className='form-group'>
                                <button
                                    type='button'
                                    onClick={() => reset()}
                                    className='btn btn-secondary mr-1'
                                    data-testid='formReset'
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-primary'
                                    data-testid='submitSettings'
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
}

export default CircularityConfig;
