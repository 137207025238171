import React from 'react';

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (props) => (
    <svg
        width='30'
        height='30'
        viewBox='0 0 25 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
    >
        <path
            opacity={props.opacity || ''}
            d='M3 10v11h6v-7h6v7h6v-11L12,3z'
            fill={props.fill || '#000000'}
        />
    </svg>
);
