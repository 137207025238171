/**
 * Top level Private Component (after Login)
 * Here the look and feel for the whole webpage is defined.
 */

import React, { useRef } from 'react';
import { SidebarComponent, SidebarContext } from 'components/sidebar';
import HeaderComponent from 'components/header/HeaderComponent';
import PrivateRoutes from './PrivateRoutes';
import ListUser from 'components/usermanagement/ListUser';
import AddUser from 'components/usermanagement/AddUser';
import EditUser from 'components/usermanagement/EditUser';
import EquipmentSettings from 'components/equipments/EquipmentSettings';
import FooterComponent from 'components/footer/FooterComponent';
import AboutUs from 'components/aboutus/AboutUsComponent';
import ManageContent from 'components/contentmanagement/ManageContent';
import CircularityConfig from 'components/circularityConfig/CircularityConfig';
import AddContent from 'components/contentmanagement/AddContent';
import AutoLogoutTimer from 'components/autoLogout/AutoLagoutTimer';
import Evaluation from 'components/evaluationLink/Evaluation';
import slugs from 'resources/slugs';

/**
 * The Top-Level Component of our application, once the User is logged in.
 *
 * @returns A Page that consists out of the Sidebar, a Header and the different Components specified in PrivateRoutes.js
 */

function PrivateSection() {
    const currentPath = window.location.pathname;
    const scrollTopRef = useRef(null);

    setTimeout(function () {
        scrollTopRef.current?.scrollIntoView({
            behavior: 'auto',
            block: 'start',
            inline: 'start'
        });
    }, 10);

    if (currentPath === '/add-user') {
        return (
            <div id='outer-private-container'>
                <HeaderComponent />
                <AutoLogoutTimer ComposedClass={AddUser} />
                <FooterComponent />
            </div>
        );
    } else if (currentPath === '/list-user') {
        return (
            <div id='outer-private-container'>
                <HeaderComponent />
                <AutoLogoutTimer ComposedClass={ListUser} />
                <FooterComponent />
            </div>
        );
    } else if (currentPath === '/edit-user') {
        return (
            <div id='outer-private-container'>
                <HeaderComponent />
                <AutoLogoutTimer ComposedClass={EditUser} />
                <FooterComponent />
            </div>
        );
    } else if (currentPath === '/equipment-settings') {
        return (
            <div id='outer-private-container'>
                <HeaderComponent />
                <AutoLogoutTimer ComposedClass={EquipmentSettings} />
                <FooterComponent />
            </div>
        );
    } else if (currentPath === '/about-us') {
        return (
            <div id='outer-private-container'>
                <HeaderComponent />
                <AutoLogoutTimer ComposedClass={AboutUs} />
                <FooterComponent />
            </div>
        );
    } else if (currentPath === slugs.circularityConfig) {
        return (
            <div id='outer-private-container'>
                <HeaderComponent />
                <AutoLogoutTimer ComposedClass={CircularityConfig} />
                <FooterComponent />
            </div>
        );
    } else if (currentPath === '/content-manage') {
        return (
            <div id='outer-private-container'>
                <HeaderComponent />
                <AutoLogoutTimer ComposedClass={ManageContent} />
                <FooterComponent />
            </div>
        );
    } else if (currentPath === '/add-content') {
        return (
            <div id='outer-private-container'>
                <HeaderComponent />
                <AutoLogoutTimer ComposedClass={AddContent} />
                <FooterComponent />
            </div>
        );
    } else if (currentPath === '/evaluation') {
        return (
            <div id='outer-private-container'>
                <HeaderComponent />
                <AutoLogoutTimer ComposedClass={Evaluation} />
                <FooterComponent />
            </div>
        );
    } else {
        return (
            <SidebarContext>
                <div id='outer-private-container' ref={scrollTopRef}>
                    <HeaderComponent />

                    <SidebarComponent
                        pageWrapId={'page-wrap'}
                        outerContainerId={'outer-private-container'}
                    />
                    <div id='page-wrap' style={{ width: 'calc(100% - 300px)' }}>
                        <PrivateRoutes />
                    </div>
                </div>
                <FooterComponent />
            </SidebarContext>
        );
    }
}

export default PrivateSection;
