import './Loginstyle.css';
import React, { useContext, useState } from 'react';
import { color } from 'resources/theme';
import slugs from 'resources/slugs';
import { GlobalContext } from 'hooks/GlobalContext';
import BackgroudImage from 'assets/images/BG_Image.png';
import SiemensLogo from 'assets/logo/SE_Logo_White_RGB.png';
import UserPool from 'components/aws/UserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useLocation } from 'react-router-dom';
import LoadingComponent from 'components/loading';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Modal, Button } from 'react-bootstrap';
import { acceptPrivacyNotice, updateDataPrivacy, updateUserLoginInfo } from './LoginService';
import { getCurrentYear } from 'interface/projectInterface';

function VerifyCode() {
    /**
     * background style
     */
    var backgroundStyles = {
        backgroundImage: 'url(' + BackgroudImage + ')',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    };

    const [state, setState] = useContext(GlobalContext);
    const [userObj, setValues] = useState({
        verifyCode: ''
    });
    const [showLoader, setLoaderState] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    const [showContinueBtn, setShowContinueBtn] = useState(false);
    const [cognitoResponseData, setCognitoResponseData] = useState({});
    const handleClose = () => setShowPrivacyModal(false);
    const userData = useLocation();

    /**
     * use to set form values with state obj
     * @param {name} name
     * @returns
     */
    const set = (name) => {
        return ({ target: { value } }) => {
            setValues((oldValues) => ({ ...oldValues, [name]: value }));
        };
    };

    const onSubmit = async (event) => {
        setLoaderState(true);
        setErrorMessage('');
        event.preventDefault();

        var email = userData.state.email;
        const cognitoUser = new CognitoUser({
            Username: email,
            Pool: UserPool
        });

        cognitoUser.Session = localStorage.getItem('cognitoUserSession');
        cognitoUser.sendMFACode(userObj.verifyCode, {
            onSuccess: (userResult) => {
                setLoaderState(false);
                localStorage.removeItem('cognitoUserSession');
                var loginObj = {
                    email: userResult.idToken.payload.email,
                    firstName: userResult.idToken.payload.given_name,
                    lastName: userResult.idToken.payload.family_name,
                    phoneNumber: userResult.idToken.payload.phone_number
                };
                acceptPrivacyNotice(loginObj)
                    .then((response) => {
                        if (!response.data.dataPrivacyAccepted) {
                            setShowPrivacyModal(true);
                            setCognitoResponseData(userResult.idToken);
                        } else {
                            setState({
                                ...state,
                                email: userResult.idToken.payload.email,
                                firstName: userResult.idToken.payload.given_name,
                                lastName: userResult.idToken.payload.family_name,
                                userType: userResult.idToken.payload['cognito:groups'],
                                userIsLoggedIn: true,
                                dataPrivacy: true
                            });
                            localStorage.setItem('dataPrivacy', true);

                            if (
                                userResult.idToken.payload['cognito:groups'].includes('ROLE_USER')
                            ) {
                                updateUserLoginInfo()
                                    .then(() => {
                                        console.log('Success');
                                    })
                                    .catch(function (error) {
                                        console.log(error.message);
                                    });
                            }
                        }
                    })
                    .catch(function (error) {
                        setErrorMessage(error.response.data.message);
                    });
            },
            onFailure: (err) => {
                setLoaderState(false);
                if (err.name === 'NotAuthorizedException') {
                    setErrorMessage(err.message);
                } else {
                    setErrorMessage('Please enter a valid OTP');
                }
            }
        });
    };

    const clickContinuetoLogin = () => {
        updateDataPrivacy(userData.state.email, true)
            .then((response) => {
                setState({
                    ...state,
                    email: cognitoResponseData.payload.email,
                    firstName: cognitoResponseData.payload.given_name,
                    lastName: cognitoResponseData.payload.family_name,
                    userType: cognitoResponseData.payload['cognito:groups'],
                    userIsLoggedIn: true,
                    dataPrivacy: true
                });
                localStorage.setItem('dataPrivacy', true);
            })
            .catch(function (error) {
                setErrorMessage(error.response.data.message);
            });
    };
    function checkDataPrivacy(e) {
        if (e.target.checked === true) {
            setShowContinueBtn(true);
        } else {
            setShowContinueBtn(false);
        }
    }
    return (
        <div className='flex-container' style={backgroundStyles}>
            <div className='boxContainer'>
                <img src={SiemensLogo} alt='Siemens Energy' />
            </div>
            <div style={{ marginRight: 'auto', marginLeft: '-115px' }}>
                <form
                    className='login'
                    action={slugs.categories}
                    onSubmit={onSubmit}
                    style={{
                        background: `0% 0 % no - repeat padding- box ${color.lightGrayishPurple}`
                    }}
                >
                    <div className='login-container'>
                        <LoadingComponent loading={showLoader} fullScreen='false' />
                        <div className='form-group col-12 align-self-center'>
                            <p className='welcomeText'> Welcome to </p>
                            <p className='welcomeEcoText'> EcoTransparency app </p>
                            <p className='welcomeText'>Please enter the verification code</p>
                            <p className='userPhoneNo'>
                                A text message with a verification code was sent to{' '}
                                {userData.state.number}
                            </p>
                            <br></br>
                            <input
                                className='form-control'
                                placeholder='Enter the 6 digit OTP'
                                value={userObj.verifyCode}
                                onChange={set('verifyCode')}
                                data-testid='codeVerify'
                                required
                            />

                            {errorMessage && (
                                <div>
                                    <br></br>
                                    <div className='errorBackground'>
                                        <label>
                                            <h3 className='errorMessagetext'> {errorMessage} </h3>
                                        </label>
                                    </div>
                                    <br></br>
                                </div>
                            )}
                            <br></br>
                            <button
                                className='btn btn-primary btnPos'
                                type='submit'
                                data-testid='submitCode'
                            >
                                {' '}
                                Submit <ArrowForwardIosIcon className='forwardIcon' />
                            </button>
                            <br></br>
                            <br></br>
                            <p className='tryAgainText'>
                                Didn't receive one-time password ? Try logging in again&nbsp;
                                <a
                                    style={{
                                        font: 'normal normal normal 14px/18px Siemens Sans',
                                        letterSpacing: '0px',
                                        color: color.darkGrayishPurple
                                    }}
                                    href={slugs.login}
                                >
                                    here
                                </a>
                            </p>
                        </div>
                    </div>

                    <Modal
                        show={showPrivacyModal}
                        aria-labelledby='contained-modal-title-vcenter'
                        centered
                        size='lg'
                        onHide={handleClose}
                    >
                        <Modal.Header closebutton='true'>
                            <Modal.Title>Data privacy agreement</Modal.Title>{' '}
                            <button
                                type='button'
                                className='close'
                                data-dismiss='modal'
                                onClick={handleClose}
                                data-testid='closePrivacy'
                            >
                                &times;
                            </button>
                        </Modal.Header>

                        <Modal.Body>
                            I hereby voluntarily consent to the collection and processing of my
                            personal data as described herein in the{' '}
                            <a
                                style={{
                                    font: 'normal normal normal 14px/29px Siemens Sans',
                                    letterSpacing: '0px',
                                    color: color.darkGrayishPurple
                                }}
                                href='https://www.siemens.com/global/en/general/privacy-notice.html'
                                target='_blank'
                            >
                                privacy notice. 
                            </a>
                            <br></br>
                            <br></br>
                            Protecting the security and privacy of your personal data including
                            sensitive personal data is important to Siemens. Therefore, Siemens or a
                            third party engaged by the Siemens Group shall process your data in
                            accordance with the applicable Data Privacy & Protection Laws &
                            Regulations. Unless indicated otherwise at the time of collection of
                            your personal data, we erase your personal data if the retention of that
                            personal data is no longer necessary (i) for the purposes for which they
                            were collected or otherwise processed or (ii) to comply with legal
                            obligations.  This information shall only be available with the core
                            team and treated confidentially.
                        </Modal.Body>

                        <Modal.Footer>
                            <div className='checkboxSection'>
                                <input
                                    type='checkbox'
                                    onChange={(e) => checkDataPrivacy(e)}
                                    data-testid='privacyCheckbox'
                                />{' '}
                                I agree to the above mentioned Terms and Conditions.
                            </div>
                            <Button
                                className='btn btn-primary'
                                disabled={!showContinueBtn}
                                onClick={() => clickContinuetoLogin()}
                                data-testid='fireContinue'
                            >
                                Continue
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </form>
            </div>
            <div className='footerText'>
                <p className='col-6 companyFootertxt'>
                    Siemens Energy is a trademark licensed by Siemens AG.
                </p>
                <p className='col-6 copyRightFooter'>© {getCurrentYear()} Siemens Energy </p>
            </div>
        </div>
    );
}

export default VerifyCode;
