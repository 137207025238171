import React from 'react';
/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (props) => (
    <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 32 32'>
        <defs>
            <style>fill={props.fill || '#000028'}</style>
        </defs>
        <path
            id='reading_time_2'
            className='cls-1'
            d='M1,16C1,7.72,7.72,1,16,1s15,6.72,15,15-6.72,15-15,15S1,24.28,1,16Zm27,0c0-6.63-5.37-12-12-12S4,9.37,4,16s5.37,12,12,12c6.62,0,11.99-5.38,12-12Zm-10.5,.88V8.5h-3v9.62l4.94,4.94,2.12-2.12-4.06-4.06Z'
        />
    </svg>
);
