import axios from 'axios';
import { getAuthHeader } from 'interface/AuthHeader';

export async function getArticleDocument(articleName) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });

    return axios
        .get(process.env.REACT_APP_SERVER_URL + '/content/getDocument?articleName=' + articleName, {
            headers: token,
            responseType: 'arraybuffer'
        })
        .then((response) => response.data);
}
