import axios from 'axios';
import { getAuthHeader } from 'interface/AuthHeader';

export async function getVideo() {
    return axios
        .get(process.env.REACT_APP_SERVER_URL + '/content/video')
        .then((response) => response.data);
}

export async function acceptPrivacyNotice(data) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .post(process.env.REACT_APP_SERVER_URL + '/user/privacyNotice', data, {
            headers: {
                Authorization: token.Authorization
            }
        })
        .then((response) => response.data);
}

export async function updateDataPrivacy(email, accept) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .put(
            process.env.REACT_APP_SERVER_URL +
                '/user/privacy/update?accepted=' +
                accept +
                '&email=' +
                email,
            {},

            {
                headers: {
                    Authorization: token.Authorization
                }
            }
        )
        .then((response) => response.data);
}

export async function updateUserLoginInfo() {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .put(
            process.env.REACT_APP_SERVER_URL + '/user/updateLoginInfo',
            {},
            {
                headers: {
                    Authorization: token.Authorization
                }
            }
        )
        .then((response) => response.data);
}
