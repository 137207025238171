import React from 'react';

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (props) => (
    <svg
        id='Layer_1'
        data-name='Layer 1'
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 22 22'
    >
        <defs>
            <style className='cls-1'>fill={props.fill || '#131328'}</style>
        </defs>
        <path
            id='Path_3392'
            data-name='Path 3392'
            className='cls-1'
            d='M13.14,11.42h-.05L11,15.32,9.12,11.79l.39-1.16a3,3,0,0,0,1.49.42c1.62,0,3-1.36,3.56-3.38V7.6l-.68-.18,0,.07c-.43,1.7-1.59,2.85-2.87,2.85-1.56,0-2.89-1.72-3-3.9l.5-2a5.78,5.78,0,0,1,3.36-.36l.22,0,.67-.93a5.64,5.64,0,0,1,1.32,3.27v.07h.7l.83-1.93,0-.12c0-2-.87-3.38-2.21-3.47A4.3,4.3,0,0,0,10.83.15h-.11A4.33,4.33,0,0,0,6.4,4.46l.87,2.08a5.33,5.33,0,0,0,1.64,3.65l-.45,1.34-6,1.72H2.4l1.18,8.59.7-.1-1.1-8,5.38-1.53,2.09,3.91v5.64h.7V16.15l2.09-3.91,5.38,1.53-1.1,8,.7.1,1.17-8.52v-.07ZM8,3.87,7.86,4,7.52,5.34l-.41-1A3.63,3.63,0,0,1,10.72.85h0A3.61,3.61,0,0,1,13,1.62l.1.08h.12c1,0,1.61,1.06,1.63,2.69l-.34.8A6,6,0,0,0,13,2.43l0,0H12.4l-.66.93A6.14,6.14,0,0,0,8,3.87Z'
        />
    </svg>
);
