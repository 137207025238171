import './Loginstyle.css';
import { color } from 'resources/theme';
import slugs from 'resources/slugs';
import React, { useState } from 'react';
import BackgroudImage from 'assets/images/BG_Image.png';
import SiemensLogo from 'assets/logo/SE_Logo_White_RGB.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory, useLocation } from 'react-router-dom';
import UserPool from 'components/aws/UserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';
import LoadingComponent from 'components/loading';
import showEye from 'assets/images/showEye.svg';
import hideEye from 'assets/images/hideEye.svg';
import { getCurrentYear } from 'interface/projectInterface';

function ChangePassword() {
    const history = useHistory();
    const [allValues, togglePasswordVisiblity] = React.useState({ psw: false });
    const [showLoader, setLoaderState] = useState(false);
    const [showTextbox, setShowtextbox] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [successMessage, setSuccessMessage] = React.useState('');
    const [userObj, setValues] = useState({
        verificationCode: '',
        newPassword: ''
    });
    const userData = useLocation();

    /**
     * use to set form values with state obj
     * @param {name} name
     * @returns
     */
    const set = (name) => {
        return ({ target: { value } }) => {
            setValues((oldValues) => ({ ...oldValues, [name]: value }));
        };
    };

    /**
     * use for new password request
     * @param {event} event
     */
    const onSubmit = async (event) => {
        setLoaderState(true);
        event.preventDefault();
        const user = new CognitoUser({
            Username: userData.username,
            Pool: UserPool
        });
        if (userObj.newPassword !== userObj.cnfpsw) {
            setErrorMessage('Password does not match');
            setLoaderState(false);
        } else {
            user.confirmPassword(userObj.verificationCode, userObj.newPassword, {
                onSuccess: (data) => {
                    setLoaderState(false);
                    setErrorMessage('');
                    setSuccessMessage(
                        'Password changed successfully. Please login the application'
                    );
                    setShowtextbox(false);
                },
                onFailure: (err) => {
                    setErrorMessage(err.message);
                    setLoaderState(false);
                }
            });
        }
    };
    return (
        <div
            className='flex-container'
            style={{
                backgroundImage: 'url(' + BackgroudImage + ')',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className='resetBoxContainer'>
                <img src={SiemensLogo} alt='Siemens Energy' />
            </div>
            <div style={{ marginRight: 'auto', marginLeft: '-105px' }}>
                <form
                    className='changePwd'
                    action={slugs.categories}
                    id='changeForm'
                    onSubmit={onSubmit}
                    style={{
                        background: `0% 0 % no - repeat padding- box ${color.lightGrayishPurple}`
                    }}
                >
                    <div className='changePwd-container'>
                        <LoadingComponent loading={showLoader} fullScreen='false' />
                        <div className='form-group col-12 align-self-center'>
                            <p className='welcomeText'> Welcome to </p>
                            <p className='welcomeEcoText'> EcoTransparency app </p>
                            <p className='welcomeText'> Change password </p>
                            {showTextbox ? (
                                <div>
                                    <p className='infoText'>
                                        Please enter the OTP and new password
                                    </p>
                                    <p className='infoText'>• Min 8 characters</p>
                                    <p className='infoText'>• At least one uppercase letter</p>
                                    <p className='infoText'>• At least one lowercase letter</p>
                                    <p className='infoText'>• At least one numeric number</p>
                                    <p className='infoText'>• At least one special character</p>
                                    <input
                                        className='form-control'
                                        placeholder='Enter the 6 digit OTP'
                                        name='verificationCode'
                                        onChange={set('verificationCode')}
                                        data-testid='codeVerify'
                                        required
                                    />
                                    <br></br>
                                    <input
                                        className='form-control'
                                        type={allValues.psw ? 'text' : 'password'}
                                        placeholder='New Password'
                                        name='newPassword'
                                        onChange={set('newPassword')}
                                        data-testid='newpwd'
                                        required
                                    />
                                    <span className='pwd1-viewer'>
                                        <div
                                            onClick={() => {
                                                togglePasswordVisiblity((prevState) => ({
                                                    psw: !prevState.psw
                                                }));
                                            }}
                                            data-testid='eyeToggle'
                                            aria-hidden='true'
                                            className={'field-icon'}
                                        >
                                            {allValues.psw ? (
                                                <img src={showEye} style={{ cursor: 'pointer' }} />
                                            ) : (
                                                <img src={hideEye} style={{ cursor: 'pointer' }} />
                                            )}
                                        </div>
                                    </span>
                                    <br></br>
                                    <input
                                        className='form-control'
                                        type={allValues.cnfpsw ? 'text' : 'password'}
                                        placeholder='Confirm Password'
                                        name='cnfpsw'
                                        onChange={set('cnfpsw')}
                                        data-testid='confpwd'
                                        required
                                    />

                                    <span className='pwd2-viewer'>
                                        <div
                                            onClick={() => {
                                                togglePasswordVisiblity((prevState) => ({
                                                    cnfpsw: !prevState.cnfpsw
                                                }));
                                            }}
                                            data-testid='eyeToggled'
                                            aria-hidden='true'
                                            className={'field-icon'}
                                        >
                                            {allValues.cnfpsw ? (
                                                <img src={showEye} style={{ cursor: 'pointer' }} />
                                            ) : (
                                                <img src={hideEye} style={{ cursor: 'pointer' }} />
                                            )}
                                        </div>
                                    </span>
                                    {errorMessage && (
                                        <div>
                                            <div className='errorBackground'>
                                                <label>
                                                    <h3 className='errorMessagetext'>
                                                        {' '}
                                                        {errorMessage}{' '}
                                                    </h3>
                                                </label>
                                            </div>
                                            <br></br>
                                            <br></br>
                                        </div>
                                    )}
                                    <div>
                                        <button
                                            className='btn btn-primary btnPos'
                                            type='submit'
                                            data-testid='submitPwd'
                                        >
                                            {' '}
                                            Submit <ArrowForwardIosIcon className='forwardIcon' />
                                        </button>
                                    </div>
                                    <br></br>
                                    <div>
                                        <a className='goBackButton' href={slugs.forgotPassword}>
                                            {' '}
                                            <ArrowBackIosIcon className='backwardIcon' /> Go back
                                        </a>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                            <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                {successMessage && (
                                    <div className='successBackground'>
                                        <label>
                                            <h3 className='successMessagetext'>
                                                {' '}
                                                {successMessage}{' '}
                                            </h3>
                                        </label>
                                    </div>
                                )}
                            </div>
                            {!showTextbox ? (
                                <button
                                    className='btn btn-primary btnPos'
                                    type='button'
                                    onClick={() => history.push('/login')}
                                    data-testid='redirectLoginPage'
                                >
                                    {' '}
                                    Login <ArrowForwardIosIcon className='forwardIcon' />
                                </button>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </form>
            </div>
            <div className='footerText'>
                <p className='col-6 companyFootertxt'>
                    Siemens Energy is a trademark licensed by Siemens AG.
                </p>
                <p className='col-6 copyRightFooter'>© {getCurrentYear()} Siemens Energy </p>
            </div>
        </div>
    );
}

export default ChangePassword;
