import React, { useState } from 'react';
import { push as Menu } from 'react-burger-menu';

const getMenuStyles = () => ({
    /* Position and sizing of burger button */
    bmBurgerButton: {
        position: 'absolute',
        top: 18,
        left: 16,
        width: 18,
        height: 15,
        zIndex: 19
    },
    /* Color/shape of burger icon bars */
    bmBurgerBars: {
        background: '#ededed'
    },
    /*
      Sidebar wrapper styles
      Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
    bmMenuWrap: {
        position: 'fixed',
        height: '100%',
        top: '50px',
        zIndex: '1000'
    },
    /* General sidebar styles */
    bmMenu: {
        background: '#F0F0F0 0% 0% no-repeat padding-box', // left side bar backgroundcolor
        overflow: 'none'
    },
    bmItem: {
        outline: 'none',
        '&:focus': {
            outline: 'none'
        }
    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)',
        zIndex: 20
    }
});

function MenuComponent({ pageWrapId, outerContainerId, children, isMobile }) {
    const menuStyles = getMenuStyles();
    const [isOpen] = useState(true);

    return (
        <Menu
            width={'300px'}
            pageWrapId={pageWrapId}
            outerContainerId={outerContainerId}
            isOpen={!isMobile && isOpen}
            noOverlay={!isMobile}
            disableCloseOnEsc
            styles={menuStyles}
            customCrossIcon={false}
            noTransition={true}
        >
            {children}
        </Menu>
    );
}

export default MenuComponent;
