import React, { useState } from 'react';
import Menu from './MenuComponent';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './SidebarStyle.css';
import Equipments from 'components/equipments/Equipments';
import { equipmentType } from 'interface/EquipmentType';

/**
 * The SidebarComponent consists out of the functionality and the Look and Feel of the Left-Side-Navigationbar
 *
 * @author Sivaguru, Abhishek
 */
function SidebarComponent({ pageWrapId, outerContainerId }) {
    const isMobile = window.innerWidth <= 1080;

    const [filterValue, changeFilterValue] = React.useState([]);

    const [searchValue, setSearchValue] = useState('');
    const [filterButtonActive, setToggleFilterVisiblity] = useState({
        productActive: false,
        solutionActive: false,
        servicesActive: false
    });

    const handleFilter = (value) => {
        if (value === equipmentType.solutions) {
            setToggleFilterVisiblity({
                ...filterButtonActive,
                solutionActive: !filterButtonActive.solutionActive
            });
        }
        if (value === equipmentType.service) {
            setToggleFilterVisiblity({
                ...filterButtonActive,
                servicesActive: !filterButtonActive.servicesActive
            });
        }
        if (value === equipmentType.products) {
            setToggleFilterVisiblity({
                ...filterButtonActive,
                productActive: !filterButtonActive.productActive
            });
        }
        var array = [...filterValue];
        var index = array.indexOf(value);
        if (index !== -1) {
            array.splice(index, 1);
        } else {
            array.push(value);
        }
        changeFilterValue(array);
    };

    /**
     * Use to call when user type in search box
     * @param {event type in search box} event
     */
    const handleChange = (event) => {
        setSearchValue(event.currentTarget.value);
    };

    return (
        <Menu pageWrapId={pageWrapId} outerContainerId={outerContainerId} isMobile={isMobile}>
            <Tabs>
                <TabList className=''>
                    <Tab className=''></Tab>
                </TabList>
                <div className='container'>
                    <div className='labelText'>
                        <label>Select technology</label>
                    </div>
                    <div className='form-group'>
                        <div className='btnSelectionLayout'>
                            <button
                                data-testid='products'
                                className={`btn btn-light btn-sm ${
                                    filterButtonActive.productActive ? 'active' : ''
                                }`}
                                onClick={() => handleFilter(equipmentType.products)}
                            >
                                Products
                            </button>
                            <button
                                data-testid='service'
                                className={`btn btn-light btn-sm ${
                                    filterButtonActive.servicesActive ? 'active' : ''
                                }`}
                                onClick={() => handleFilter(equipmentType.service)}
                            >
                                Services
                            </button>
                            <button
                                data-testid='solutions'
                                className={`btn btn-light btn-sm ${
                                    filterButtonActive.solutionActive ? 'active' : ''
                                }`}
                                onClick={() => handleFilter(equipmentType.solutions)}
                            >
                                Solutions
                            </button>
                        </div>
                        <input
                            type='search'
                            placeholder='  Search'
                            className='searchbox'
                            value={searchValue}
                            onChange={handleChange}
                            data-testid='searchChange'
                        />
                    </div>
                </div>
                <TabPanel>
                    <Equipments filterValue={filterValue} searchValue={searchValue} />
                </TabPanel>
            </Tabs>
        </Menu>
    );
}

export default SidebarComponent;
