import './Loginstyle.css';
import { color } from 'resources/theme';
import slugs from 'resources/slugs';
import React, { useState } from 'react';
import BackgroudImageDesign from 'assets/images/BG_Image.png';
import SiemensEnergyImage from 'assets/logo/SE_Logo_White_RGB.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import UserPool from 'components/aws/UserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';
import LoadingComponent from 'components/loading';
import { getCurrentYear } from 'interface/projectInterface';

function ForgotPassword() {
    const [errorMessage, setErrorMessage] = React.useState('');
    const { push } = useHistory();
    const [showLoader, setLoaderState] = useState(false);
    const [userObj, setValues] = useState({
        email: ''
    });

    /**
     * use to set form values with state obj
     * @param {name} name
     * @returns
     */
    const set = (name) => {
        return ({ target: { value } }) => {
            setValues((oldValues) => ({ ...oldValues, [name]: value }));
        };
    };

    /**
     * use for new password request
     * @param {event} event
     */
    const onSubmit = async (event) => {
        setLoaderState(true);
        event.preventDefault();
        const user = new CognitoUser({
            Username: userObj.email,
            Pool: UserPool
        });

        user.forgotPassword({
            onSuccess: (data) => {
                setLoaderState(false);
            },
            onFailure: (err) => {
                setErrorMessage(err.message);
                setLoaderState(false);
            },
            inputVerificationCode: (data) => {
                push({
                    pathname: slugs.changePassword,
                    username: userObj.email
                });
                setLoaderState(false);
            }
        });
    };

    return (
        <div
            className='flex-container'
            style={{
                backgroundImage: 'url(' + BackgroudImageDesign + ')',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className='boxContainer'>
                <img src={SiemensEnergyImage} alt='Siemens Energy' />
            </div>
            <div style={{ marginRight: 'auto', marginLeft: '-115px' }}>
                <form
                    className='login'
                    action={slugs.categories}
                    onSubmit={onSubmit}
                    style={{
                        background: `0% 0 % no - repeat padding-box ${color.lightGrayishPurple}`
                    }}
                >
                    <div className='login-container'>
                        <LoadingComponent loading={showLoader} fullScreen='false' />
                        <div className='form-group col-11 align-self-center'>
                            <p className='welcomeText'> Welcome to </p>
                            <p className='welcomeEcoText'>EcoTransparency app </p>
                            <p className='welcomeText'> Forgot password </p>
                            <p className='infoText'>
                                Please enter your registered email ID, we will send you an OTP on
                                your mail ID to verify the account
                            </p>
                            <br></br>
                            <input
                                className='form-control'
                                type='email'
                                placeholder='Email'
                                name='uname'
                                value={userObj.email}
                                onChange={set('email')}
                                data-testid='emailSet'
                                pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
                                required
                            />
                            <br></br>
                            <div>
                                {errorMessage && (
                                    <div className='errorBackground'>
                                        <label>
                                            <h3 className='errorMessagetext'> {errorMessage} </h3>
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='form-group col-12'>
                            <button
                                className='btn btn-primary btnPos'
                                type='submit'
                                data-testid='submitmailid'
                            >
                                {' '}
                                Next <ArrowForwardIosIcon className='forwardIcon' />
                            </button>
                            &nbsp;
                        </div>
                        <div className='form-group col-12'>
                            <a className='goBackButton' href={slugs.login}>
                                {' '}
                                <ArrowBackIosIcon className='backwardIcon' /> Go back
                            </a>
                        </div>
                    </div>
                </form>
            </div>

            <div className='footerText'>
                <p className='col-6 companyFootertxt'>
                    Siemens Energy is a trademark licensed by Siemens AG.
                </p>
                <p className='col-6 copyRightFooter'>© {getCurrentYear()} Siemens Energy </p>
            </div>
        </div>
    );
}

export default ForgotPassword;
