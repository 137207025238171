import React from 'react';

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (props) => (
    <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
        <defs>
            <style>fill={props.fill || '#1b1534'}</style>
        </defs>
        <g id='SE_Icon_Transformer_RGB'>
            <path
                id='Path_265'
                fill='#ffffff'
                className='cls-1'
                d='M16.26,18.06V8.11h-1.56v-2.37c-.06-.58-.57-1-1.15-.94-.5,.05-.89,.44-.94,.94v2.37h-1.56v-2.37c-.06-.58-.57-1-1.15-.94-.5,.05-.89,.44-.94,.94v2.37h-1.56v-2.37c-.06-.58-.57-1-1.15-.94-.5,.05-.89,.44-.94,.94v2.37h-1.56v11.37h12.52v-.47H4.26V8.58H15.74v9.48h.52Zm-3.13-12.32c.03-.29,.28-.5,.57-.47,.25,.02,.45,.22,.47,.47v2.37h-1.04v-2.37Zm-3.65,0c.03-.29,.28-.5,.57-.47,.25,.02,.45,.22,.47,.47v2.37h-1.04v-2.37Zm-3.65,0c.03-.29,.28-.5,.57-.47,.25,.02,.45,.22,.47,.47v2.37h-1.04v-2.37Zm4.43-1.9h-.52V.52h.52V3.84Zm-4.17-.89L4.82,.87l.46-.23,1.34,2.19v1.01h-.52v-.89Zm7.3,.89v-1.01l1.34-2.19,.46,.23-1.27,2.08v.89h-.52Zm-.57,6.54l-1.92,2.47h1.71l.19,.4-4.43,4.5-.43-.26,1.39-2.74h-2.2v-.47h2.61l.24,.34-.84,1.64,2.88-2.93h-1.63l-.21-.37,1.92-2.47h-1.95l-1.81,2.96-.46-.23,1.96-3.2h2.76l.21,.37Z'
            />
        </g>
    </svg>
    // <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
    //     <defs>
    //         <style>fill={props.fill || '#1b1534'}</style>
    //     </defs>
    //     <path
    //         id='Path_266'
    //         fill='#ffffff'
    //         className='cls-1'
    //         d='M15.18,8.59l-3.77-1.88v-1.41h1.88v.94h.47v-1.41l-2.35-1.88-1.2-2.35h-.42l-1.2,2.35-2.35,1.88v1.41h.47v-.94h1.88v1.41h0l-3.77,1.88v1.41h.47v-.94h2.92l-1.79,10.36h4.99v-.47h-3.6l2.19-1.52,2.87,1.99h.71l-1.79-10.36h2.93v.94h.47v-1.41Zm-5.18,4.41l-1.77-1.23h0l1.77-1.23,1.77,1.23h0l-1.77,1.23Zm1.86-.72l.4,2.28-1.84-1.28,1.45-1Zm-2.28,1l-1.84,1.28,.4-2.28,1.45,1Zm-.98-3.71l.98,.68-1.25,.87,.27-1.55Zm3.06,1.55l-1.25-.87,.98-.68,.27,1.55Zm-1.66-1.15l-1.31-.9h2.61l-1.31,.9Zm-1.22-1.38l.24-1.41h1.96l.24,1.41h-2.45Zm2.64-3.77v-1.28l1.6,1.28h-1.6Zm-.47-1.41v1.41h-1.88v-1.41h1.88Zm-.94-2.2l.88,1.73h-1.77l.88-1.73Zm-3.01,3.61l1.6-1.28v1.28h-1.6Zm3.96,.47v1.41h-1.88v-1.41h1.88Zm-5.07,3.3l2.65-1.33-.23,1.33h-2.42Zm1.11,10.36l.65-3.73,2.37-1.64,2.37,1.64h0l-2.37,1.64-1.45-1.01-.27,.39,1.31,.91-2.6,1.8Zm3.43-1.8l2.05-1.42,.56,3.22-2.6-1.8Zm1.06-9.88l2.65,1.33h-2.42l-.23-1.33Z'
    //     />
    // </svg>
);
