import React from 'react';

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (props) => (
    <svg
        id='Layer_1'
        data-name='Layer 1'
        fill='white'
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 28 28'
    >
        <defs>
            <style className='cls-1'>fill={props.fill || '#fff'}</style>
        </defs>
        <path
            id='Path_2696'
            data-name='Path 2696'
            className='cls-1'
            d='M4.76,22.53A3.55,3.55,0,0,1,1.21,19h0V9A3.55,3.55,0,0,1,4.76,5.47H23.24A3.55,3.55,0,0,1,26.79,9h0V19a3.56,3.56,0,0,1-3.55,3.56H4.76ZM2.63,9V19A2.13,2.13,0,0,0,4.76,21.1H23.24A2.13,2.13,0,0,0,25.37,19h0V9a2.14,2.14,0,0,0-2.13-2.14H4.76A2.14,2.14,0,0,0,2.63,9Zm8.55.9L18.24,14l-7.06,4.07Zm1.43,5.68L15.4,14l-2.79-1.61Z'
        />
    </svg>
);
