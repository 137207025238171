import axios from 'axios';
import { getAuthHeader } from 'interface/AuthHeader';

export async function addContentdetails(data) {
    var token = await getAuthHeader().then((val) => {
        return val;
    })
    return axios.post(process.env.REACT_APP_SERVER_URL + '/content/add', data,
    {   
        headers: 
            {
                Authorization:token.Authorization,
                'Content-Type': 'multipart/form-data'
            }
    }).then(response => response.data);
}

export async function getAllContents(){
    var token = await getAuthHeader().then((val) => {
        return val;
    })

    return axios.get(process.env.REACT_APP_SERVER_URL + '/content/getContents', { headers: token }).then(response => response.data); 
}

export async function deleteContent(contentId) {

    var token = await getAuthHeader().then((val) => {
        return val;
    })

    return axios.delete(process.env.REACT_APP_SERVER_URL + '/content/remove?contentId=' + contentId, { headers: token }).then(response => response.data);
}

export async function updateContentdetails(data) {
    var token = await getAuthHeader().then((val) => {
        return val;
    })
    return axios.put(process.env.REACT_APP_SERVER_URL + '/content/update', data,
    {   
        headers: 
            {
                Authorization:token.Authorization
            }
    }).then(response => response.data);
}