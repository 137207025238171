/**
 * slugs.js defines all known path extensions
 */

const slugs = {
    // private
    dashboard: '/dashboard',
    settings: '/settings',
    logout: '/logout',
    equipmentDetail: '/equipment-detail/:id',
    compareEquipmentData: '/equipment-compare/:id',
    listuser: '/list-user',
    adduser: '/add-user',
    edituser: '/edit-user',
    equipmentsetttings: '/equipment-settings',
    aboutus: '/about-us',
    manageContent: '/content-manage',
    circularityConfig: '/circularity-config',
    addContent: '/add-content',
    evaluation: '/evaluation',
    // auth
    login: '/login',
    signup: '/signup',
    forgotPassword: '/forgot-password',
    changePassword: '/change-password',
    verifyCode: '/verify-code',
    resetPassword: '/reset-psw',
    sustainablityWiki: '/sustainability-wiki'
};

export default slugs;
