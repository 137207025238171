import axios from 'axios';
import { getAuthHeader } from 'interface/AuthHeader';

/**
 * Get request to get equipments list
 * @returns authentication response
 *
 */
export async function getAllEquipmentList() {
    var token = await getAuthHeader().then((val) => {
        return val;
    });

    return axios
        .get(process.env.REACT_APP_SERVER_URL + '/equipment/getEquipments', {
            headers: token
        })
        .then((response) => response.data);
}

/**
 * use to get Equipment detail by id
 * @param {*} equipmentId
 * @returns
 */
export async function getEquipmentDetailById(equipmentId) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });

    return axios
        .get(
            process.env.REACT_APP_SERVER_URL +
                '/equipment/getEquipmentDetails?externalId=' +
                equipmentId,
            { headers: token }
        )
        .then((response) => response.data);
}

export async function getMeasureDetailByEquipmentId(equipmentId) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });

    return axios
        .get(
            process.env.REACT_APP_SERVER_URL +
                '/circular/getMeasures?equipmentExternalId=' +
                equipmentId,
            {
                headers: token
            }
        )
        .then((response) => response.data);
}

export async function getAllEquipmentLists() {
    var token = await getAuthHeader().then((val) => {
        return val;
    });

    return axios
        .get(process.env.REACT_APP_SERVER_URL + '/equipment/getEquipmentList', { headers: token })
        .then((response) => response.data);
}

export async function getcomparisonModel(equipmentName) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });

    return axios
        .get(
            process.env.REACT_APP_SERVER_URL +
                '/equipment/comparisonModel?equipmentName=' +
                equipmentName,
            { headers: token }
        )
        .then((response) => response.data);
}

export async function updateEquipment(data) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .post(process.env.REACT_APP_SERVER_URL + '/equipment/updateEquipmentData', data, {
            headers: {
                Authorization: token.Authorization,
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => response.data);
}

export async function updateAllEquipments() {
    var token = await getAuthHeader().then((val) => {
        return val;
    });

    return axios
        .post(
            process.env.REACT_APP_SERVER_URL + '/equipment/updateAllEquipments',
            {},
            { headers: token }
        )
        .then((response) => response.data);
}

export async function getMaterialCompostionById(equipmentId) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });

    return axios
        .get(process.env.REACT_APP_SERVER_URL + '/equipment/materials?equipmentId=' + equipmentId, {
            headers: token
        })
        .then((response) => response.data);
}

export async function getDescriptiontemplate() {
    var token = await getAuthHeader().then((val) => {
        return val;
    });

    return axios
        .get(process.env.REACT_APP_SERVER_URL + '/equipment/download', { headers: token })
        .then((response) => response.data);
}

export async function sendEquipmentReport(data, equipmentName) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .post(
            process.env.REACT_APP_SERVER_URL + '/equipment/report?equipmentName=' + equipmentName,
            data,
            {
                headers: {
                    Authorization: token.Authorization,
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
        .then((response) => response.data);
}
export async function sendReportAlert(equipmentName) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });

    return axios
        .post(
            process.env.REACT_APP_SERVER_URL + '/equipment/report?equipmentName=' + equipmentName,
            {},
            { headers: token }
        )
        .then((response) => response.data);
}

export async function sendFavourite(favourite, equipmentId) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .put(
            process.env.REACT_APP_SERVER_URL +
                '/equipment/favourite?favourite=' +
                favourite +
                '&equipmentId=' +
                equipmentId,
            {},
            {
                headers: {
                    Authorization: token.Authorization
                }
            }
        )
        .then((response) => response.data);
}

export async function sendEquipmentStatus(status, equipmentId) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .put(
            process.env.REACT_APP_SERVER_URL +
                '/equipment/status?status=' +
                status +
                '&equipmentExternalId=' +
                equipmentId,
            {},
            {
                headers: {
                    Authorization: token.Authorization
                }
            }
        )
        .then((response) => response.data);
}
export async function sendEquipmentVerify(status, equipmentId) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .put(
            process.env.REACT_APP_SERVER_URL +
                '/equipment/verify?status=' +
                status +
                '&equipmentExternalId=' +
                equipmentId,
            {},
            {
                headers: {
                    Authorization: token.Authorization
                }
            }
        )
        .then((response) => response.data);
}
export async function updateEquipmentCalculation(equipmentId) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .put(
            process.env.REACT_APP_SERVER_URL +
                '/equipment/updateCalculation?equipmentExternalId=' +
                equipmentId,
            {},
            {
                headers: {
                    Authorization: token.Authorization
                }
            }
        )
        .then((response) => response.data);
}
export async function updateMaterialCompositionCalculation(equipmentId) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .put(
            process.env.REACT_APP_SERVER_URL +
                '/equipment/updateMaterial?equipmentExternalId=' +
                equipmentId,
            {},
            {
                headers: {
                    Authorization: token.Authorization
                }
            }
        )
        .then((response) => response.data);
}

export async function updateDeleteEquipment(equipmentId) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .delete(
            process.env.REACT_APP_SERVER_URL +
                '/equipment/delete?equipmentExternalId=' +
                equipmentId,
            {
                headers: token
            }
        )
        .then((response) => response.data);
}

export async function getAuthorizedEquipments(email) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });

    return axios
        .get(process.env.REACT_APP_SERVER_URL + '/user/equipments?email=' + email, {
            headers: token
        })
        .then((response) => response.data);
}

export async function sendReportAccess(reportStatus, equipmentId) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .put(
            process.env.REACT_APP_SERVER_URL +
                '/equipment/report/access?access=' +
                reportStatus +
                '&equipmentExternalId=' +
                equipmentId,
            {},
            {
                headers: {
                    Authorization: token.Authorization
                }
            }
        )
        .then((response) => response.data);
}

export async function enableCircularity(status, equipmentId) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .put(
            process.env.REACT_APP_SERVER_URL +
                '/equipment/setCircularEconomyStatus?status=' +
                status +
                '&equipmentExternalId=' +
                equipmentId,
            {},
            {
                headers: {
                    Authorization: token.Authorization
                }
            }
        )
        .then((response) => response.data);
}

export async function sendGwpAccess(reportStatus, equipmentId) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .put(
            process.env.REACT_APP_SERVER_URL +
                '/equipment/setGWPStatus?status=' +
                reportStatus +
                '&equipmentExternalId=' +
                equipmentId,
            {},
            {
                headers: {
                    Authorization: token.Authorization
                }
            }
        )
        .then((response) => response.data);
}
