import React, { useState } from 'react';
import IconHome from 'assets/icons/icon-home';
import arrow from 'assets/images/Arrow.png';
import Article from 'assets/images/Article.png';
import News from 'assets/images/Sustainability News.png';
import Video from 'assets/images/Video_2.png';
import Link from 'assets/images/Link.png';
import { useHistory } from 'react-router';
import { addContentdetails } from './ContentManagementService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import LoadingComponent from 'components/loading';
import Select, { components } from 'react-select';

const { SingleValue, Option } = components;

function AddContent() {
    const history = useHistory();
    const [showTitle, setShowTitle] = useState(false);
    const [showlinktxt, setShowlinktxt] = useState(false);
    const [showlinkURL, setShowlinkURL] = useState(false);
    const [showimageUpload, setShowimageUpload] = useState(false);
    const [showpdfUpload, setShowpdfUpload] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [showvideoUpload, setShowvideoUpload] = useState(false);
    const [contentValidate, setcontentValidate] = useState('');
    const [mandate, setMandate] = useState(false);

    const options = [
        {
            label: 'News',
            value: 'NEWS',
            image: News
        },
        {
            label: 'How to',
            value: 'VIDEO',
            image: Video
        },
        {
            label: 'Circular economy',
            value: 'ARTICLE',
            image: Article
        },
        {
            label: 'Life cycle assessment',
            value: 'LINK',
            image: Link
        }
    ];

    const IconSingleValue = (props) => (
        <SingleValue {...props}>
            <img
                src={props.data.image}
                style={{ height: '20px', width: '20px', borderRadius: '50%', marginRight: '10px' }}
            />
            {props.data.label}
        </SingleValue>
    );

    const IconOption = (props) => (
        <Option {...props}>
            <img
                src={props.data.image}
                style={{ height: '20px', width: '20px', borderRadius: '50%', marginRight: '10px' }}
            />
            {props.data.label}
        </Option>
    );

    const customStyles = {
        option: (provided) => ({
            ...provided,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        }),
        singleValue: (provide) => ({
            ...provide,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        })
    };

    const handleChange = (e) => {
        setenableSave(true);
        if (e.value === 'NEWS') {
            setShowTitle(true);
            setShowlinktxt(true);
            setShowlinkURL(true);
            setShowimageUpload(true);
            setShowpdfUpload(false);
            setShowDescription(true);
            setShowvideoUpload(false);
            setcontentValidate(e.value);
            setMandate(false);
        } else if (e.value === 'VIDEO') {
            setShowTitle(true);
            setShowlinktxt(false);
            setShowlinkURL(false);
            setShowimageUpload(false);
            setShowpdfUpload(false);
            setShowDescription(true);
            setShowvideoUpload(true);
            setMandate(false);
            setcontentValidate(e.value);
        } else if (e.value === 'ARTICLE') {
            setShowTitle(false);
            setShowlinktxt(true);
            setShowlinkURL(false);
            setShowimageUpload(false);
            setShowpdfUpload(true);
            setShowDescription(false);
            setShowvideoUpload(false);
            setMandate(true);
            setcontentValidate(e.value);
        } else if (e.value === 'LINK') {
            setShowTitle(false);
            setShowlinktxt(true);
            setShowlinkURL(true);
            setShowimageUpload(false);
            setShowpdfUpload(false);
            setShowDescription(false);
            setShowvideoUpload(false);
            setcontentValidate(e.value);
            setMandate(true);
        } else {
            setShowTitle(false);
            setShowlinktxt(false);
            setShowlinkURL(false);
            setShowimageUpload(false);
            setShowpdfUpload(false);
            setShowDescription(false);
            setShowvideoUpload(false);
            setenableSave(false);
            setMandate(false);
        }
    };
    const re =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    var validationSchema = {};
    if (contentValidate === 'NEWS') {
        validationSchema = Yup.object().shape(
            {
                contentTitle: Yup.string().required('Content title is required'),
                linkUrl: Yup.string()
                    .nullable()
                    .notRequired()
                    .when('linkUrl', {
                        is: (value) => value?.length,
                        then: (rule) => rule.matches(re, 'URL is not valid')
                    }),
                contentDescription: Yup.string().required('Content description is required'),
                picture: Yup.mixed()
                    .test('fileType', 'Please upload a supported format', function (value) {
                        if (value.length !== 0) {
                            const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
                            return SUPPORTED_FORMATS.includes(value[0].type);
                        }
                    })
                    .test('fileSize', 'File Size is too large', (value) => {
                        if (value.length !== 0) {
                            const sizeInBytes = 5245676; //5MB
                            return value[0].size <= sizeInBytes;
                        }
                    })
            },
            [
                // Add Cyclic deps here because when require itself
                ['linkUrl', 'linkUrl']
            ]
        );
    } else if (contentValidate === 'VIDEO') {
        validationSchema = Yup.object().shape({
            contentTitle: Yup.string().required('Content title is required'),
            contentDescription: Yup.string().required('Content description is required'),
            videofile: Yup.mixed()
                .test('fileType', 'Please upload a supported format', function (value) {
                    if (value.length !== 0) {
                        const SUPPORTED_FORMATS = ['video/mp4'];
                        return SUPPORTED_FORMATS.includes(value[0].type);
                    }
                })
                .test('fileSize', 'File size is too large', (item) => {
                    if (item.length !== 0) {
                        const sizeInBytes = 20971520; //20MB
                        return item[0].size <= sizeInBytes;
                    }
                })
        });
    } else if (contentValidate === 'ARTICLE') {
        validationSchema = Yup.object().shape({
            linkText: Yup.string().required('Link text is required'),
            pdffile: Yup.mixed()
                .test('fileType', 'Please upload a supported format', function (value) {
                    if (value.length !== 0) {
                        const SUPPORTED_FORMATS = ['application/pdf'];
                        return SUPPORTED_FORMATS.includes(value[0].type);
                    }
                })
                .test('fileSize', 'File size is too large', (pdfValue) => {
                    if (pdfValue.length !== 0) {
                        const sizeInBytes = 5245676; //5MB
                        return pdfValue[0].size <= sizeInBytes;
                    }
                })
        });
    } else if (contentValidate === 'LINK') {
        validationSchema = Yup.object().shape({
            linkText: Yup.string().required('Link text is required'),
            linkUrl: Yup.string().matches(re, 'URL is not valid')
        });
    }

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, register, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showLoader, setLoaderState] = useState(false);
    const [enableSave, setenableSave] = useState(false);
    function onSubmit(data) {
        setSuccessMessage('');
        setErrorMessage('');
        setLoaderState(true);

        var obj = {
            contentType: contentValidate,
            contentTitle: data.contentTitle,
            contentDescription: data.contentDescription,
            linkUrl: data.linkUrl,
            linkText: data.linkText
        };
        const formData = new FormData();

        formData.append('data', JSON.stringify(obj));
        if (contentValidate === 'NEWS') {
            formData.append('file', data.picture[0], data.picture[0].name);
        } else if (contentValidate === 'VIDEO') {
            formData.append('file', data.videofile[0], data.videofile[0].name);
        } else if (contentValidate === 'ARTICLE') {
            formData.append('file', data.pdffile[0], data.pdffile[0].name);
        }

        addContentdetails(formData)
            .then((response) => {
                setSuccessMessage(response.message);
                setLoaderState(false);
                reset();
            })
            .catch(function (error) {
                setLoaderState(false);
                setErrorMessage(error.response.data.message);
            });
    }
    return (
        <div className='m-10'>
            <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                    <li
                        className='breadcrumb-item'
                        data-testid='id'
                        onClick={() => history.push('/dashboard')}
                    >
                        <IconHome></IconHome>
                    </li>
                    <img src={arrow} />
                    <li
                        className='breadcrumb-item'
                        style={{ marginLeft: '5px' }}
                        aria-current='page'
                        onClick={() => history.push('/content-manage')}
                    >
                        Content management
                    </li>
                    <img src={arrow} />
                    <li
                        className='breadcrumb-item'
                        style={{ marginLeft: '5px', cursor: 'default' }}
                        aria-current='page'
                    >
                        Add content
                    </li>
                </ol>
            </nav>
            <div className='card-body'>
                <h2>Add landing page content</h2>
                <br></br>
                <LoadingComponent loading={showLoader} fullScreen='false' />
                <div>
                    {successMessage && (
                        <div>
                            <label className='text-success'> {successMessage}</label>
                        </div>
                    )}
                </div>
                <div>
                    {errorMessage && (
                        <div>
                            <label className='text-danger'> {errorMessage}</label>
                        </div>
                    )}
                </div>
                <form onSubmit={handleSubmit(onSubmit)} id='submitContent'>
                    <div className='form-row'>
                        <div className='form-group col-2' data-testid='content-select'>
                            <label>Select content type</label>
                            <label style={{ color: 'red' }}>
                                <b>*</b>
                            </label>

                            <Select
                                styles={customStyles}
                                name='contentType'
                                style={{ height: '10px' }}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                components={{ SingleValue: IconSingleValue, Option: IconOption }}
                                options={options}
                            />
                        </div>
                        {showTitle ? (
                            <div className='form-group col-2'>
                                <label>Content title</label>
                                <label style={{ color: 'red' }}>
                                    <b>*</b>
                                </label>
                                <input
                                    data-testid='titleId'
                                    name='contentTitle'
                                    type='text'
                                    {...register('contentTitle')}
                                    placeholder='Add title'
                                    className={`form-control ${
                                        errors.contentTitle ? 'is-invalid' : ''
                                    }`}
                                    maxLength='100'
                                />
                                <div className='invalid-feedback'>
                                    {errors.contentTitle?.message}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {showlinktxt ? (
                            <div className='form-group col-2'>
                                <label>Link text</label>{' '}
                                {mandate ? (
                                    <label style={{ color: 'red' }}>
                                        <b>*</b>
                                    </label>
                                ) : (
                                    ''
                                )}
                                <input
                                    data-testid='linkText'
                                    name='linkText'
                                    type='text'
                                    {...register('linkText')}
                                    placeholder='Add link'
                                    className={`form-control ${
                                        errors.linkText ? 'is-invalid' : ''
                                    }`}
                                    maxLength='30'
                                />
                                <div className='invalid-feedback'>{errors.linkText?.message}</div>
                            </div>
                        ) : (
                            ''
                        )}
                        {showlinkURL ? (
                            <div className='form-group col-2'>
                                <label>Link URL</label>{' '}
                                {mandate ? (
                                    <label style={{ color: 'red' }}>
                                        <b>*</b>
                                    </label>
                                ) : (
                                    ''
                                )}
                                <input
                                    data-testid='linkUrl'
                                    name='linkUrl'
                                    type='text'
                                    {...register('linkUrl')}
                                    className={`form-control ${errors.linkUrl ? 'is-invalid' : ''}`}
                                    placeholder='https://www.siemens-energy.com'
                                />
                                <div className='invalid-feedback'>{errors.linkUrl?.message}</div>
                            </div>
                        ) : (
                            ''
                        )}
                        {showimageUpload ? (
                            <div className='form-group col-2'>
                                <label>Upload image</label>
                                <label style={{ color: 'red' }}>
                                    <b>*</b>
                                </label>
                                <input
                                    data-testid='image'
                                    name='picture'
                                    type='file'
                                    accept='.png, .jpg, .jpeg'
                                    {...register('picture')}
                                    className={`form-control ${errors.picture ? 'is-invalid' : ''}`}
                                />
                                <div className='invalid-feedback'>{errors.picture?.message}</div>
                            </div>
                        ) : (
                            ''
                        )}
                        {showpdfUpload ? (
                            <div className='form-group col-2'>
                                <label>Upload PDF</label>
                                <label style={{ color: 'red' }}>
                                    <b>*</b>
                                </label>
                                <input
                                    data-testid='pdfFile'
                                    type='file'
                                    name='pdffile'
                                    accept='application/pdf'
                                    {...register('pdffile')}
                                    className={`form-control ${errors.pdffile ? 'is-invalid' : ''}`}
                                />
                                <div className='invalid-feedback'>{errors.pdffile?.message}</div>
                            </div>
                        ) : (
                            ''
                        )}
                        {showvideoUpload ? (
                            <div className='form-group col-2'>
                                <label>Upload video</label>
                                <label style={{ color: 'red' }}>
                                    <b>*</b>
                                </label>
                                <input
                                    data-testid='videoFile'
                                    type='file'
                                    name='videofile'
                                    accept='video/*'
                                    {...register('videofile')}
                                    className={`form-control ${
                                        errors.videofile ? 'is-invalid' : ''
                                    }`}
                                />
                                <div className='invalid-feedback'>{errors.videofile?.message}</div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    {showDescription ? (
                        <div className='form-row'>
                            <div className='form-group col-10'>
                                <label>Text message</label>
                                <label style={{ color: 'red' }}>
                                    <b>*</b>
                                </label>
                                <textarea
                                    data-testid='textMessage'
                                    name='contentDescription'
                                    rows='5'
                                    type='text'
                                    {...register('contentDescription')}
                                    placeholder='Add text here'
                                    className={`form-control ${
                                        errors.contentDescription ? 'is-invalid' : ''
                                    }`}
                                    minLength='100'
                                    maxLength='800'
                                />
                                <div className='invalid-feedback'>
                                    {errors.contentDescription?.message}
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className='form-group'>
                        <button
                            type='button'
                            className='btn btn-secondary mr-1'
                            data-testid='id1'
                            onClick={() => history.push('/content-manage')}
                        >
                            Cancel
                        </button>
                        <button
                            data-testid='submit'
                            type='submit'
                            className='btn btn-primary'
                            disabled={!enableSave}
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
export default AddContent;
