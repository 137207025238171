import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { FixedSizeList } from 'react-window';
import { getAllEquipmentList, sendFavourite } from './EquipmentService';
import LoadingComponent from 'components/loading';
import { color } from 'resources/theme';
import IconNull from 'assets/icons/icon-null';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import './EquipmentStyle.css';
import InactiveStar from 'assets/images/emptyStar.png';
import ActiveStar from 'assets/images/filledStar.png';
import LastSeen from 'assets/icons/icon-lastseen';
import RecentlyView from 'assets/icons/icon-recently';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        maxWidth: 300,
        overflowY: 'auto',
        overflowX: 'hidden'
    },

    containerList: {
        borderStyle: 'solid',
        borderWidth: 'thin',
        borderColor: color.white,
        height: '100%',
        weight: '100%',
        cursor: 'pointer'
    },

    itemSelected: {
        backgroundColor: color.Violet
    }
}));

/**
 * use to get e quipments list
 * @param {*} props
 * @returns
 */
function Equipments(props) {
    const classes = useStyles();
    const [equipmentList, setEquipmentList] = React.useState([]);
    const [showLoader, setLoaderState] = React.useState(true);
    const [originalEquipmentList, setOriginalEquipmentList] = React.useState([]);
    const [selection, setSelection] = React.useState(null);
    const history = useHistory();
    const [favouriteValues, setFavouriteValues] = React.useState([]);
    const [hover, setHover] = React.useState(null);
    const [listHeight, setListHeight] = React.useState(0);

    useEffect(() => {
        var originaltList = [...originalEquipmentList];
        var filteredItems;
        if (originaltList.length > 0) {
            var filterArrayList = [];
            if (props.filterValue.length > 0) {
                filteredItems = props.filterValue.forEach(function (filterBy) {
                    filteredItems = originaltList.filter(function (item) {
                        return item['equipmentType'] === filterBy;
                    });
                    filterArrayList.push.apply(filterArrayList, filteredItems);
                });
            } else {
                filterArrayList = [...originalEquipmentList];
            }
            if (props.searchValue) {
                var filterSearchArrayList = [];
                let searchResults = filterArrayList.filter((o) =>
                    o.name.toLowerCase().includes(props.searchValue.toLowerCase())
                );
                filterSearchArrayList.push.apply(filterSearchArrayList, searchResults);
                setEquipmentList(filterSearchArrayList);
            } else {
                setEquipmentList(filterArrayList);
            }
        } else {
            getAllEquipmentList()
                .then((response) => {
                    setLoaderState(false);
                    if (response.data) {
                        var arr = [];
                        response.data.forEach((item) => {
                            var itemObj = {};
                            itemObj.fav = item.favourite;
                            itemObj.hover = false;
                            arr.push(itemObj);
                        });
                        setFavouriteValues(arr);
                        setEquipmentList(response.data);
                        setOriginalEquipmentList(response.data);
                    }
                })
                .catch(function (error) {
                    setLoaderState(false);
                });
        }
    }, [props.filterValue, props.searchValue]);

    useEffect(() => {
        calculateHeightDynamic();
        window.addEventListener('resize', calculateHeightDynamic);
        return () => {
            window.removeEventListener('resize', calculateHeightDynamic);
        };
    }, [equipmentList]);
    /**
     * use to show equipment details
     * @param {id of an equipment} externalId
     * @param {index number} index
     */
    function ShowEquipmentDetail(externalId, index) {
        history.push(`/equipment-compare/${externalId}`);
        setSelection(index);
    }

    //calculating height based on system resolution
    function calculateHeightDynamic() {
        // Determine screen size
        const screenHeight = window.innerHeight;
        let calculatedHeight = Math.min(equipmentList.length * 50, screenHeight - 220);

        setListHeight(calculatedHeight);
    }
    /**
     *   this is used to update favourite status value
     * @param {event} e
     * @param {id of an equipment} equipmentId
     * @param {favourite value} favourite
     * @param {index number} index
     */
    const favouriteClick = (e, equipmentId, favourite, index) => {
        e.stopPropagation();
        setLoaderState(true);
        const updatedFavourites = [...favouriteValues];
        updatedFavourites[index].fav = !favourite;
        setFavouriteValues(updatedFavourites);
        sendFavourite(!favourite, equipmentId)
            .then((response) => {
                setLoaderState(false);
            })
            .catch(function (error) {
                setLoaderState(false);
            });
    };
    return (
        <div className={classes.root}>
            <FixedSizeList
                height={listHeight}
                width='100%'
                itemSize={50}
                itemCount={equipmentList.length}
                itemData={equipmentList}
                overflow='hidden'
            >
                {({ index, style }) => {
                    const item = equipmentList[index];

                    return (
                        <div
                            data-testid='id'
                            className={classNames(classes.containerList, {
                                [classes.itemSelected]: index === selection
                            })}
                            style={{ ...style, overflow: 'hidden' }}
                            onClick={() => ShowEquipmentDetail(item.externalId, index)}
                        >
                            <div
                                className={
                                    selection === index ? 'labelEquipmentActive' : 'labelEquipment'
                                }
                                style={
                                    selection !== index &&
                                    !favouriteValues[index].fav &&
                                    hover === index
                                        ? { backgroundColor: 'white' }
                                        : { backgroundColor: '' }
                                }
                                data-testid='mouseHover'
                                onMouseEnter={() => setHover(index)}
                                onMouseLeave={() => setHover(false)}
                            >
                                <div className='row'>
                                    {item.lastSeen ? (
                                        <div className='img-circle'>
                                            {selection !== index ? (
                                                <LastSeen></LastSeen>
                                            ) : (
                                                <RecentlyView></RecentlyView>
                                            )}
                                        </div>
                                    ) : (
                                        <div className='img-circle'>
                                            {item.imageUrl ? (
                                                <img src={item.imageUrl} className='imgLayout' />
                                            ) : (
                                                <span className='badge badge-circle badge-primary'>
                                                    <IconNull></IconNull>
                                                </span>
                                            )}
                                        </div>
                                    )}{' '}
                                    <label className='labelAlign col-sm-8'>{item.name}</label>
                                    <span
                                        className='alignStar'
                                        data-tooltip='Mark as favourite'
                                        data-tooltip-position='left'
                                    >
                                        <img
                                            data-testid='imgClick'
                                            className='starImage'
                                            src={
                                                favouriteValues[index].fav
                                                    ? ActiveStar
                                                    : hover === index
                                                    ? InactiveStar
                                                    : ''
                                            }
                                            onClick={(e) =>
                                                favouriteClick(
                                                    e,
                                                    item.id,
                                                    favouriteValues[index].fav,
                                                    index
                                                )
                                            }
                                            key={item.id}
                                            alt=''
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </FixedSizeList>
            <LoadingComponent loading={showLoader} fullScreen='false' />
        </div>
    );
}

export default Equipments;
