import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './Evaluation.css';

function EquipmentStatsChart() {
    const equipmentSeries = [
        {
            name: 'Features',
            data: [
                {
                    x: 'Bushing RIP',
                    y: 9.97
                },
                {
                    x: 'Large Power Transformer',
                    y: 5.37
                },
                {
                    x: 'ARC suppression coils',
                    y: 2.33
                }
            ]
        }
    ];

    const options = {
        chart: {
            type: 'bar',
            toolbar: { show: false },
            foreColor: '#fff'
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: 'top'
                }
            }
        },
        fill: {
            opacity: 1
        },

        dataLabels: {
            enabled: true,
            offsetY: -16,
            offsetX: 0
        },
        grid: {
            show: false
        },
        legend: {
            show: true
        },

        xaxis: {
            labels: {
                style: {
                    textAlign: 'center',
                    fontSize: '14px',
                    fontFamily: 'Siemens Sans',
                    letterSpacing: '0px',
                    color: '#FFFFFF'
                }
            }
        },
        yaxis: {
            show: false
        }
    };
    return (
        <div className='barChartBG'>
            <h3 className='statsHeader'>Top 3 most clicked equipments</h3>
            <ReactApexChart options={options} series={equipmentSeries} type='bar' height={300} />
        </div>
    );
}
export default EquipmentStatsChart;
