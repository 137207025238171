import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import './userTable.css';
import AwsCognitoIdentityServiceProvider from 'components/aws/AwsCognitoIdentityServiceProvider';
import LoadingComponent from 'components/loading';
import UserTable from 'components/usermanagement/UserTable';
import { useHistory } from 'react-router';
import { Modal, Button } from 'react-bootstrap';
import slugs from 'resources/slugs';
import IconHome from 'assets/icons/icon-home';
import arrow from 'assets/images/Arrow.png';
import deleteIcon from 'assets/images/Delete.png';
import editIcon from 'assets/images/Edit.png';
import useTokenPagination from 'token-pagination-hooks';
import IconPlus from 'assets/icons/icon-plus';
import { deleteUserDetail } from './UserManagementService';

const Styles = styled.div`
    table {
        border-spacing: 0;
        border: 1px solid #ada9c2;

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th,
        td {
            margin: 0;
            padding: 0.4rem;
            border-bottom: 1px solid #ada9c2;
            border-right: 1px solid #ada9c2;

            :last-child {
                border-right: 0;
            }
        }
    }
`;

function ListUser() {
    const [usersList, setUserslist] = useState([]);
    const [showLoader, setLoaderState] = useState(false);
    const { push } = useHistory();
    const [showModal, setShow] = useState(false);
    const [rowDelete, setRowdelete] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const handleClose = () => setShow(false);
    const [nextPage, setNextPage] = useState('');

    /**
     * pagination parameters
     */
    const [pageNumber, setPageNumber] = useState(1);
    const { currentToken, updateToken, hasToken } = useTokenPagination(pageNumber);

    const handleShow = (userDelete) => {
        setShow(true);
        setRowdelete(userDelete);
    };
    const history = useHistory();
    const usersPage = () => {
        history.push('/add-user');
    };
    const editUser = (userUpdate) => {
        push({
            pathname: slugs.edituser,
            state: { editUserObj: userUpdate }
        });
    };
    const deleteUser = (userDelete) => {
        setLoaderState(true);
        var params = {
            UserPoolId: process.env.REACT_APP_USERPOOL_ID,
            Username: userDelete.email
        };
        deleteUserDetail(userDelete.email)
            .then((response) => {
                AwsCognitoIdentityServiceProvider.adminDeleteUser(params, function (err, data) {
                    if (err) {
                        setLoaderState(false);
                        handleClose();
                        setErrorMessage('User is not properly deleted');
                    } else {
                        setLoaderState(false);
                        userList();
                        handleClose();
                        setErrorMessage(response.message);
                    }
                });
                setLoaderState(false);
            })
            .catch(function (error) {
                setLoaderState(false);
                setErrorMessage(error.response.data.message);
            });
    };

    const userList = (searchValue) => {
        setLoaderState(true);
        var params = '';
        if (searchValue) {
            params = {
                UserPoolId: process.env.REACT_APP_USERPOOL_ID,
                AttributesToGet: null,
                Filter: 'email ^= "' + searchValue + '"'
            };
        } else {
            params = {
                UserPoolId: process.env.REACT_APP_USERPOOL_ID,
                AttributesToGet: null,
                Filter: '',
                Limit: 10,
                PaginationToken: currentToken
            };
        }

        AwsCognitoIdentityServiceProvider.listUsers(params, function (err, data) {
            if (data) {
                const userArrayObj = [];
                data.Users.forEach((element) => {
                    var userObj = {};
                    element.Attributes.forEach((item) => {
                        userObj[item.Name] = item.Value;
                    });
                    userArrayObj.push(userObj);
                });
                setUserslist(userArrayObj);
                setNextPage(data.PaginationToken);
                updateToken(data.PaginationToken);
                setLoaderState(false);
            } else {
                setLoaderState(false);
            }
        });
    };
    useEffect(() => {
        userList();
    }, [currentToken, updateToken]);

    const handleChange = (event) => {
        userList(event.target.value);
    };
    const columns = React.useMemo(
        () => [
            {
                Header: 'Users',
                columns: [
                    {
                        Header: 'First name',
                        accessor: 'given_name'
                    },
                    {
                        Header: 'Last name',
                        accessor: 'family_name'
                    },
                    {
                        Header: 'Email',
                        accessor: 'email'
                    },
                    {
                        Header: 'Phone number',
                        accessor: 'phone_number'
                    },
                    {
                        Header: 'Action',
                        accessor: 'action',
                        Cell: ({ cell }) => (
                            <div className='form-row'>
                                <img src={editIcon} className='iconView' />
                                <span
                                    onClick={() => editUser(cell.row.values)}
                                    value={cell.row.values.name}
                                    className='rowEdit'
                                >
                                    Edit
                                </span>
                                &nbsp;&nbsp;&nbsp;
                                <img src={deleteIcon} className='iconView' />{' '}
                                <span
                                    onClick={() => handleShow(cell.row.values)}
                                    className='rowDelete'
                                    data-testid='deleteRow'
                                >
                                    Delete
                                </span>
                            </div>
                        )
                    }
                ]
            }
        ],
        []
    );
    return (
        <div className='m-10'>
            <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                    <li
                        className='breadcrumb-item'
                        data-testid='id'
                        onClick={() => history.push('/dashboard')}
                    >
                        <IconHome></IconHome>
                    </li>{' '}
                    <img src={arrow} />
                    <li
                        className='breadcrumb-item'
                        style={{ marginLeft: '5px', cursor: 'default' }}
                        aria-current='page'
                    >
                        User management
                    </li>
                </ol>
            </nav>
            <div className='card-body'>
                <h2>User management</h2>
                <LoadingComponent loading={showLoader} fullScreen='false' />
                <div>
                    {errorMessage && (
                        <div>
                            <label className='text-success'> {errorMessage}</label>
                        </div>
                    )}
                </div>

                <Styles>
                    <div>
                        <button
                            className='btn btn-primary'
                            data-testid='userRedirect'
                            onClick={usersPage}
                        >
                            {' '}
                            <IconPlus /> Add user
                        </button>
                        <input
                            placeholder='Search by email'
                            className='searchInputbox'
                            onChange={(e) => handleChange(e)}
                            data-testid='changeSearch'
                        />
                    </div>
                    <UserTable columns={columns} data={usersList} />
                </Styles>
                <div>
                    <button disabled={pageNumber <= 1} onClick={() => setPageNumber((p) => p - 1)}>
                        &lt;
                    </button>
                    {'  '}
                    <label style={{ fontSize: '20px', fontWeight: 'bold' }}>{pageNumber}</label>
                    {'  '}
                    <button
                        disabled={!hasToken(pageNumber + 1) || !nextPage}
                        onClick={() => setPageNumber((p) => p + 1)}
                    >
                        &gt;
                    </button>
                </div>
            </div>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closebutton='true'>
                    <Modal.Title>Delete user</Modal.Title>
                </Modal.Header>
                <Modal.Body>Would you like to delete the user?</Modal.Body>
                <br></br>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant='primary'
                        data-testid='userDelete'
                        onClick={() => deleteUser(rowDelete)}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default ListUser;
