/**
 * The GlobalContext enables cross-Component State-Management
 *
 * @author Abhishek
 */

import React, { useState, createContext, useEffect } from 'react';
import { getSession } from 'interface/UserSession';

export const GlobalContext = createContext([{}]);

/**
 * Defines a StateProvider, that passes down all its data to every child component.
 *
 * @param {*} props the previous state that has been used.
 */
export const GlobalStateProvider = (props) => {
    const [state, setState] = useState({
        userIsLoggedIn: false,
        email: '',
        firstName: '',
        lastName: '',
        userType: '',
        dataPrivacy: false
    });

    useEffect(() => {
        getSession()
            .then(function (result) {
                let acceptDataPrivacy = localStorage.getItem('dataPrivacy');
                if (acceptDataPrivacy) {
                    setState((oldState) => ({
                        ...oldState,
                        userIsLoggedIn: true,
                        email: result.idToken.payload.email,
                        firstName: result.idToken.payload.given_name,
                        lastName: result.idToken.payload.family_name,
                        userType: result.idToken.payload['cognito:groups']
                    }));
                } else {
                    setState((oldState) => ({ ...oldState, userIsLoggedIn: false }));
                }
            })
            .catch(function (error) {
                console.log('session:: ' + error);
            });
    }, []);

    return (
        <GlobalContext.Provider value={[state, setState]}>{props.children}</GlobalContext.Provider>
    );
};
