import React, { useContext } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Modal, Button } from 'react-bootstrap';
import UserPool from 'components/aws/UserPool';
import { GlobalContext } from 'hooks/GlobalContext';
import './AutologoutTimer.css';
import { getSession } from 'interface/UserSession';

const SESSION_IDEL_MINUTES = 60;

const AutoLagoutTimer = (props) => {
    const { ComposedClass } = props;
    const [showModal, setShow] = React.useState(false);
    const [state, setState] = useContext(GlobalContext);

    const handleOnIdle = (event) => {
        setShow(true);
        const cognitoUser = UserPool.getCurrentUser();
        if (cognitoUser) {
            cognitoUser.signOut();
        }
    };

    useIdleTimer({
        timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
        onIdle: handleOnIdle,
        debounce: 500,
        crossTab: true
    });

    const logoutApp = async () => {
        getSession()
            .then((result) => {
                setShow(false);
                setState({ ...state, userIsLoggedIn: true });
            })
            .catch((error) => {
                setState({ ...state, userIsLoggedIn: false });
                localStorage.removeItem('dataPrivacy');
            });
    };

    return (
        <div>
            <ComposedClass />
            <Modal
                show={showModal}
                size='sm'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Body className='modalBG'>
                    <label className='modalHeader'>Your session has expired.</label> <br></br>{' '}
                    <label className='modalContent'>
                        Please login again. Don't worry, we kept your work in place.
                    </label>
                    <Button
                        data-testid='showDialog'
                        variant='secondary'
                        className='autoTimerLogin'
                        onClick={() => logoutApp()}
                    >
                        Login
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AutoLagoutTimer;
