import React, { useEffect, useState } from 'react';
import IconHome from 'assets/icons/icon-home';
import arrow from 'assets/images/Arrow.png';
import { useHistory } from 'react-router';
import IconPlus from 'assets/icons/icon-plus';
import './content.css';
import { getAllContents, deleteContent, updateContentdetails } from './ContentManagementService';
import LoadingComponent from 'components/loading';
import { Modal, Button } from 'react-bootstrap';
import videoThumnail from 'assets/images/Video_1@2x.png';
import articleImage from 'assets/images/Document@2x.png';
import linkImage from 'assets/images/External link@2x.png';

function ManageContent() {
    const history = useHistory();
    const [newsContent, setNewsContent] = React.useState([]);
    const [videosContent, setVideoscontent] = React.useState([]);
    const [articleContent, setArticlecontent] = React.useState([]);
    const [linkContent, setLinkcontent] = React.useState([]);
    const [showLoader, setLoaderState] = React.useState(false);
    const [showModal, setShow] = React.useState(false);
    const [contenttypeid, setcontenttypeid] = React.useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const handleClose = () => setShow(false);

    useEffect(() => {
        updateAllcontents();
    }, []);

    const updateAllcontents = () => {
        setLoaderState(true);
        getAllContents()
            .then((response) => {
                setNewsContent(response.data.news);
                setVideoscontent(response.data.videos);
                setArticlecontent(response.data.articles);
                setLinkcontent(response.data.links);
                setLoaderState(false);
            })
            .catch(function () {
                setLoaderState(false);
            });
    };
    const handleShow = (e, id) => {
        e.preventDefault();
        setShow(true);
        setcontenttypeid(id);
    };
    const deleteContentType = (contentID) => {
        setSuccessMessage('');
        setErrorMessage('');
        setLoaderState(true);
        deleteContent(contentID)
            .then((response) => {
                setSuccessMessage(response.message);
                updateAllcontents();
                setLoaderState(false);
                handleClose();
            })
            .catch(function (error) {
                setErrorMessage(error.response.data.message);
                setLoaderState(false);
                handleClose();
            });
    };

    const handleSubmit = (event, index, evenType) => {
        setLoaderState(true);
        setSuccessMessage('');
        setErrorMessage('');
        event.preventDefault();
        var requestObj = {};

        if (evenType === 'Life cycle assessment') {
            requestObj.contentId = linkContent[index].contentId;
            requestObj.linkText = linkContent[index].linkText;
            requestObj.linkUrl = linkContent[index].linkUrl;
        } else if (evenType === 'How to') {
            requestObj.contentId = videosContent[index].contentId;
            requestObj.contentTitle = videosContent[index].title;
            requestObj.contentDescription = videosContent[index].description;
        } else if (evenType === 'Circular economy') {
            requestObj.contentId = articleContent[index].contentId;
            requestObj.linkText = articleContent[index].linkText;
        } else if (evenType === 'NEWS') {
            requestObj.contentId = newsContent[index].contentId;
            requestObj.contentTitle = newsContent[index].title;
            requestObj.linkText = newsContent[index].linkText;
            requestObj.linkUrl = newsContent[index].linkUrl;
            requestObj.contentDescription = newsContent[index].description;
        } else {
            return;
        }

        updateContentdetails(requestObj)
            .then((response) => {
                setSuccessMessage(response.message);
                setLoaderState(false);
                updateAllcontents();
            })
            .catch(function (error) {
                setLoaderState(false);
                setErrorMessage(error.response.data.message);
            });
    };
    const handleChange = (event, index, evenType) => {
        const { name, value } = event.target;

        if (evenType === 'Life cycle assessment') {
            linkContent[index] = Object.assign({}, linkContent[index], {
                [name]: value
            });
            setLinkcontent(linkContent);
        } else if (evenType === 'How to') {
            videosContent[index] = Object.assign({}, videosContent[index], {
                [name]: value
            });
            setVideoscontent(videosContent);
        } else if (evenType === 'Circular economy') {
            articleContent[index] = Object.assign({}, articleContent[index], {
                [name]: value
            });
            setArticlecontent(articleContent);
        } else if (evenType === 'NEWS') {
            newsContent[index] = Object.assign({}, newsContent[index], {
                [name]: value
            });
            setNewsContent(newsContent);
        } else {
            return;
        }
    };

    return (
        <div className='m-10'>
            <LoadingComponent loading={showLoader} fullScreen='false' />
            <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                    <li
                        className='breadcrumb-item'
                        data-testid='id'
                        onClick={() => history.push('/dashboard')}
                    >
                        <IconHome></IconHome>
                    </li>
                    <img src={arrow} />
                    <li
                        className='breadcrumb-item'
                        style={{ marginLeft: '5px', cursor: 'default' }}
                        aria-current='page'
                    >
                        Content management
                    </li>
                </ol>
            </nav>
            <div className='card-body'>
                <h2>Add / Edit content for the landing page</h2>
                <br></br>
                <div>
                    {successMessage && (
                        <div>
                            <label className='text-success'> {successMessage}</label>
                        </div>
                    )}
                </div>
                <div>
                    {errorMessage && (
                        <div>
                            <label className='text-danger'> {errorMessage}</label>
                        </div>
                    )}
                </div>
                <p className='contentsubHead'>
                    Please click on the plus button to add content. You can also edit or delete the
                    existing content.
                </p>
                <br></br>
                <button
                    className='btn btn-primary'
                    data-testid='id1'
                    onClick={() => history.push('/add-content')}
                >
                    <IconPlus /> Add content
                </button>
                <br></br>
                <br></br>
                {newsContent.map((newsItem, i) => (
                    <div key={i}>
                        <form onSubmit={(e) => handleSubmit(e, i, newsItem.contentType)}>
                            <div className='form-row'>
                                <div className='form-group col-1'>
                                    <img src={newsItem.fileUrl} className='thumbnail' />
                                </div>
                                <div className='form-group col-2'>
                                    <label>Select content type</label>
                                    <input
                                        name='contentType'
                                        className='form-control'
                                        value={newsItem.contentType}
                                        disabled
                                    />
                                </div>
                                <div className='form-group col-2'>
                                    <label>Content title</label>
                                    <label style={{ color: 'red' }}>
                                        <b>*</b>
                                    </label>
                                    <input
                                        data-testid='newsTitle'
                                        name='title'
                                        type='text'
                                        className='form-control'
                                        onChange={(e) => handleChange(e, i, newsItem.contentType)}
                                        key={newsItem.contentId}
                                        defaultValue={newsItem.title}
                                        maxLength='100'
                                        required
                                    />
                                </div>
                                <div className='form-group col-2'>
                                    <label>Link text</label>
                                    <input
                                        data-testid='newsLinkText'
                                        name='linkText'
                                        type='text'
                                        maxLength='30'
                                        className='form-control'
                                        onChange={(e) => handleChange(e, i, newsItem.contentType)}
                                        key={newsItem.contentId}
                                        defaultValue={newsItem.linkText}
                                    />
                                </div>
                                <div className='form-group col-2'>
                                    <label>Link URL</label>
                                    <input
                                        data-testid='newsLinkUrl'
                                        name='linkUrl'
                                        type='text'
                                        className='form-control'
                                        onChange={(e) => handleChange(e, i, newsItem.contentType)}
                                        key={newsItem.contentId}
                                        defaultValue={newsItem.linkUrl}
                                        pattern='https://.*'
                                        placeholder='https://www.siemens-energy.com'
                                    />
                                </div>
                                <div className='form-group col-3 align-self-center'>
                                    <div
                                        className='row'
                                        style={{ marginLeft: '5px', marginTop: '15px' }}
                                    >
                                        <button
                                            data-testid='submitNews'
                                            className='btn btn-primary'
                                            type='submit'
                                        >
                                            Save
                                        </button>
                                        &nbsp;
                                        <button
                                            data-testid='deleteNews'
                                            className='btn btn-secondary'
                                            onClick={(e) => handleShow(e, newsItem.contentId)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='form-group col-9'>
                                    <label>Text message</label>
                                    <label style={{ color: 'red' }}>
                                        <b>*</b>
                                    </label>
                                    <textarea
                                        data-testid='submitDescription'
                                        name='description'
                                        rows='3'
                                        type='text'
                                        className='form-control'
                                        onChange={(e) => handleChange(e, i, newsItem.contentType)}
                                        key={newsItem.contentId}
                                        defaultValue={newsItem.description}
                                        maxLength='800'
                                        required
                                    />
                                </div>
                            </div>
                            <hr className='horizontalLine col-10'></hr>
                        </form>
                    </div>
                ))}
                {videosContent.map((videosItem, i) => (
                    <div key={i}>
                        <form onSubmit={(e) => handleSubmit(e, i, 'How to')}>
                            <div className='form-row'>
                                <div className='form-group col-1'>
                                    <img src={videoThumnail} />
                                </div>
                                <div className='form-group col-2'>
                                    <label>Select content type</label>
                                    <input
                                        name='contentType'
                                        className='form-control'
                                        value='How to'
                                        disabled
                                    />
                                </div>
                                <div className='form-group col-2'>
                                    <label>Content title</label>
                                    <label style={{ color: 'red' }}>
                                        <b>*</b>
                                    </label>
                                    <input
                                        data-testid='videoTitle'
                                        name='title'
                                        type='text'
                                        className='form-control'
                                        onChange={(e) => handleChange(e, i, 'How to')}
                                        key={videosItem.contentId}
                                        defaultValue={videosItem.title}
                                        maxLength='100'
                                        required
                                    />
                                </div>
                                <div className='form-group col-2 align-self-center'>
                                    <div
                                        className='row'
                                        style={{ marginLeft: '5px', marginTop: '15px' }}
                                    >
                                        <button
                                            data-testid='submitVideo'
                                            className='btn btn-primary'
                                            type='submit'
                                        >
                                            Save
                                        </button>
                                        &nbsp;
                                        <button
                                            className='btn btn-secondary'
                                            onClick={(e) => handleShow(e, videosItem.contentId)}
                                            data-testid='deleteVideos'
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='form-group col-9'>
                                    <label>Text message</label>
                                    <label style={{ color: 'red' }}>
                                        <b>*</b>
                                    </label>
                                    <textarea
                                        data-testid='videoText'
                                        name='description'
                                        rows='3'
                                        type='text'
                                        onChange={(e) => handleChange(e, i, 'How to')}
                                        key={videosItem.contentId}
                                        className='form-control'
                                        defaultValue={videosItem.description}
                                        minLength='100'
                                        maxLength='800'
                                        required
                                    />
                                </div>
                            </div>
                            <hr className='horizontalLine col-10'></hr>
                        </form>
                    </div>
                ))}
                {articleContent.map((articleitem, i) => (
                    <div key={i}>
                        <form onSubmit={(e) => handleSubmit(e, i, 'Circular economy')}>
                            <div className='form-row'>
                                <div className='form-group col-1'>
                                    <img src={articleImage} />
                                </div>

                                <div className='form-group col-2'>
                                    <label>Select content type</label>
                                    <input
                                        name='contentType'
                                        className='form-control'
                                        value='Circular economy'
                                        disabled
                                    />
                                </div>

                                <div className='form-group col-2'>
                                    <label>Link text</label>
                                    <label style={{ color: 'red' }}>
                                        <b>*</b>
                                    </label>
                                    <input
                                        data-testid='articleText'
                                        name='linkText'
                                        type='text'
                                        onChange={(e) => handleChange(e, i, 'Circular economy')}
                                        key={articleitem.contentId}
                                        className='form-control'
                                        defaultValue={articleitem.linkText}
                                        maxLength='30'
                                        required
                                    />
                                </div>
                                <div className=' form-group col-2 align-self-center'>
                                    <div
                                        className='row'
                                        style={{ marginLeft: '5px', marginTop: '15px' }}
                                    >
                                        <button
                                            data-testid='submitArticle'
                                            className='btn btn-primary'
                                            type='submit'
                                        >
                                            Save
                                        </button>
                                        &nbsp;
                                        <button
                                            className='btn btn-secondary'
                                            onClick={(e) => handleShow(e, articleitem.contentId)}
                                            data-testid='deleteArticle'
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr className='horizontalLine col-10'></hr>
                        </form>
                    </div>
                ))}

                {linkContent.map((linkitem, i) => (
                    <div key={i}>
                        <form onSubmit={(e) => handleSubmit(e, i, 'Life cycle assessment')}>
                            <div className='form-row'>
                                <div className='form-group col-1'>
                                    <img src={linkImage} />
                                </div>
                                <div className='form-group col-2'>
                                    <label>Select content type</label>
                                    <input
                                        name='contentType'
                                        className='form-control'
                                        value={'Life cycle assessment'}
                                        disabled
                                    />
                                </div>
                                <div className='form-group col-2'>
                                    <label>Link text</label>
                                    <label style={{ color: 'red' }}>
                                        <b>*</b>
                                    </label>
                                    <input
                                        data-testid='linkTestId'
                                        name='linkText'
                                        type='text'
                                        onChange={(e) =>
                                            handleChange(e, i, 'Life cycle assessment')
                                        }
                                        key={linkitem.contentId}
                                        className='form-control'
                                        maxLength='30'
                                        defaultValue={linkitem.linkText}
                                        required
                                    />
                                </div>
                                <div className='form-group col-2'>
                                    <label>Link URL</label>
                                    <label style={{ color: 'red' }}>
                                        <b>*</b>
                                    </label>
                                    <input
                                        data-testid='linkTestUrl'
                                        name='linkUrl'
                                        type='text'
                                        onChange={(e) =>
                                            handleChange(e, i, 'Life cycle assessment')
                                        }
                                        key={linkitem.contentId}
                                        className='form-control'
                                        defaultValue={linkitem.linkUrl}
                                        pattern='https://.*'
                                        placeholder='https://www.siemens-energy.com'
                                        required
                                    />
                                </div>
                                <div className='form-group col-2 align-self-center'>
                                    <div
                                        className='row'
                                        style={{ marginLeft: '5px', marginTop: '15px' }}
                                    >
                                        <button
                                            data-testid='submitLink'
                                            className='btn btn-primary'
                                            type='submit'
                                        >
                                            Save
                                        </button>
                                        &nbsp;
                                        <button
                                            className='btn btn-secondary'
                                            data-testid='showDeletePopUp'
                                            onClick={(e) => handleShow(e, linkitem.contentId)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <hr className='horizontalLine col-10'></hr>
                        </form>
                    </div>
                ))}
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closebutton='true'>
                    <Modal.Title>Delete content</Modal.Title>
                </Modal.Header>
                <Modal.Body>Would you like to delete the content?</Modal.Body>
                <br></br>
                <Modal.Footer>
                    <Button
                        variant='secondary'
                        onClick={handleClose}
                        data-testid='closePopuponClick'
                    >
                        Close
                    </Button>
                    <Button
                        data-testid='submitDelete'
                        variant='primary'
                        onClick={() => deleteContentType(contenttypeid)}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default ManageContent;
