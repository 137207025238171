import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import AwsCognitoIdentityServiceProvider from 'components/aws/AwsCognitoIdentityServiceProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import LoadingComponent from 'components/loading';
import './bootstrap.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { MultiSelect } from 'components/multiselectAll/MultiSelect';
import { getSelectedEquipments, updateUserDetail } from './UserManagementService';
import { getAllEquipmentLists } from 'components/equipments/EquipmentService';
import { GetUserManagementBreadCrumb } from 'interface/projectInterface';

function EditUser() {
    const validationSchema = Yup.object().shape({
        given_name: Yup.string()
            .min(3, 'First name must be minimum 3 characters')
            .max(30, 'First name must be 30 characters')
            .required('First name is required'),
        family_name: Yup.string()
            .min(3, 'Last name must be minimum 3 characters')
            .max(30, 'Last name must be 30 characters')
            .required('Last name is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const userData = useLocation();
    const [showLoader, setLoaderState] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [roleUpdate, setRoleUpdate] = useState('');
    const [previousRole, setPreviousRole] = useState('');
    const history = useHistory();
    const [roleChange, setRoleChange] = useState(false);
    const [phoneValue, setPhoneValue] = useState(userData.state.editUserObj.phone_number);
    const [phoneValuePresent, setPhoneValuePresent] = useState(true);
    const [listedEquipments, setListedEquipments] = useState([]);
    const [selectedUserEquipments, setSelectedUserEquipments] = useState([]);
    const [requiredEquipments, setRequiredEquipments] = useState(true);

    const submitEditForm = (formData) => {
        setLoaderState(true);
        var userAttributes = [
            {
                Name: 'given_name',
                Value: formData.FirstName
            },
            {
                Name: 'family_name',
                Value: formData.LastName
            },
            {
                Name: 'email',
                Value: formData.Email
            },
            {
                Name: 'email_verified',
                Value: 'true'
            },
            {
                Name: 'phone_number',
                Value: !formData.Phone.startsWith('+') ? '+' + formData.Phone : formData.Phone
            },
            {
                Name: 'phone_number_verified',
                Value: 'true'
            }
        ];
        var params = {
            UserPoolId: process.env.REACT_APP_USERPOOL_ID,
            Username: formData.Email,
            UserAttributes: userAttributes
        };
        var groupParams = {
            UserPoolId: process.env.REACT_APP_USERPOOL_ID,
            Username: formData.Email,
            GroupName: previousRole
        };
        var updatedParams = {
            UserPoolId: process.env.REACT_APP_USERPOOL_ID,
            Username: formData.Email,
            GroupName: formData.Role
        };
        var selectedEquipmentIds = [];
        formData.ExternalId.forEach((element) => {
            selectedEquipmentIds.push(element.value);
        });
        var updatedUserDetails = {
            email: formData.Email,
            firstName: formData.FirstName,
            lastName: formData.LastName,
            phoneNumber: '+' + formData.Phone,
            equipmentsIds: selectedEquipmentIds
        };

        AwsCognitoIdentityServiceProvider.adminUpdateUserAttributes(params, function (err, data) {
            if (data) {
                if (roleChange !== true) {
                    updateEquipmentLists(updatedUserDetails);
                    setLoaderState(false);
                } else {
                    AwsCognitoIdentityServiceProvider.adminRemoveUserFromGroup(
                        groupParams,
                        function (error, removeData) {
                            if (removeData) {
                                AwsCognitoIdentityServiceProvider.adminAddUserToGroup(
                                    updatedParams,
                                    function (errResponse, groupData) {
                                        if (groupData) {
                                            updateEquipmentLists(updatedUserDetails);
                                            setLoaderState(false);
                                        } else {
                                            setErrorMessage(errResponse.message);
                                            setLoaderState(false);
                                        }
                                    }
                                );
                            } else {
                                setErrorMessage(error.message);
                                setLoaderState(false);
                            }
                        }
                    );
                }
            } else {
                setErrorMessage(err.message);
                setLoaderState(false);
            }
        });
    };
    function updateEquipmentLists(userDetailsObj) {
        updateUserDetail(userDetailsObj)
            .then((response) => {
                setSuccessMessage(response.message);
                setLoaderState(false);
            })
            .catch(function (error) {
                setLoaderState(false);
                setErrorMessage(error.response.data.message);
            });
    }
    function onSubmit(data) {
        if (!phoneValue) {
            setPhoneValuePresent(false);
            return;
        }
        if (selectedUserEquipments.length === 0) {
            setRequiredEquipments(false);
            return;
        }
        setPhoneValuePresent(true);
        //display form data on success
        const formdetails = {
            FirstName: data.given_name,
            LastName: data.family_name,
            Phone: phoneValue,
            Email: data.email,
            Role: data.role,
            ExternalId: selectedUserEquipments
        };
        submitEditForm(formdetails);
    }
    useEffect(() => {
        getAllEquipmentLists().then((response) => {
            var projectArray = [];
            response.data.forEach((list) => {
                if (list.isBaseline) {
                    let obj = {};
                    obj.id = list.id;
                    obj.value = list.externalId;
                    obj.label = list.name;
                    projectArray.push(obj);
                    setListedEquipments(projectArray);
                }
            });
        });
        var params = {
            UserPoolId: process.env.REACT_APP_USERPOOL_ID,
            Username: userData.state.editUserObj.email
        };
        AwsCognitoIdentityServiceProvider.adminListGroupsForUser(params, function (err, data) {
            data.Groups.forEach((item) => {
                setRoleUpdate(item.GroupName);
                setPreviousRole(item.GroupName);
            });
        });
        getSelectedEquipments(userData.state.editUserObj.email)
            .then((response) => {
                setSelectedUserEquipments(response.data);
            })
            .catch(function (error) {
                setErrorMessage(error.response.data.message);
            });
    }, []);

    const cancelUser = () => {
        history.push('/list-user');
    };
    const handleChange = (e) => {
        setRoleUpdate(e.target.value);
        setRoleChange(true);
    };
    const onChangeEquipment = (data, actionMetaData) => {
        setSelectedUserEquipments(data, actionMetaData);
        setRequiredEquipments(true);
    };
    return (
        <div className='m-10'>
            {GetUserManagementBreadCrumb('Edit')}
            <div className='card-body'>
                <h2>Edit user</h2>
                <br></br>
                <h4>Create user accounts, update their information and assign roles.</h4>
                <br></br>
                <LoadingComponent loading={showLoader} fullScreen='false' />
                <div>
                    {successMessage && (
                        <div>
                            <label className='text-success'>{successMessage}</label>
                        </div>
                    )}
                </div>
                <div>
                    {errorMessage && (
                        <div>
                            <label className='text-danger'> {errorMessage}</label>
                        </div>
                    )}
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-row'>
                        <div className='form-group col-5'>
                            <label>First name</label>
                            <input
                                name='given_name'
                                type='text'
                                {...register('given_name')}
                                defaultValue={userData.state.editUserObj.given_name}
                                className={`form-control ${errors.given_name ? 'is-invalid' : ''}`}
                                data-testid='updateFname'
                            />
                            <div className='invalid-feedback'>{errors.given_name?.message}</div>
                        </div>
                        <div className='form-group col-5'>
                            <label>Last name</label>
                            <input
                                name='family_name'
                                type='text'
                                {...register('family_name')}
                                defaultValue={userData.state.editUserObj.family_name}
                                className={`form-control ${errors.family_name ? 'is-invalid' : ''}`}
                                data-testid='familyname'
                            />
                            <div className='invalid-feedback'>{errors.family_name?.message}</div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group col-5'>
                            <label>Phone number</label>
                            <PhoneInput
                                value={phoneValue}
                                placeholder='Enter phone number'
                                onChange={(phone) => setPhoneValue(phone)}
                                name='phone'
                            />

                            {!phoneValuePresent && (
                                <div>
                                    <label className='invalid-error'>
                                        Phone number is required
                                    </label>
                                </div>
                            )}
                        </div>
                        <div className='form-group col-5'>
                            <label>Email</label>
                            <input
                                name='email'
                                type='text'
                                {...register('email')}
                                className={`form-control`}
                                defaultValue={userData.state.editUserObj.email}
                                data-testid='mailid'
                                readOnly
                            />
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group col-5'>
                            <label>User role</label>
                            <select
                                name='role'
                                {...register('role')}
                                value={roleUpdate}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                data-testid='selectRole'
                                className={`form-control`}
                            >
                                <option value='ROLE_ADMIN'>ROLE_ADMIN</option>
                                <option value='ROLE_USER'>ROLE_USER</option>
                            </select>
                        </div>
                        <div className='form-group col-5'>
                            <label>Select equipments</label>
                            <MultiSelect
                                options={listedEquipments}
                                value={selectedUserEquipments}
                                onChange={onChangeEquipment}
                            ></MultiSelect>
                            {!requiredEquipments && (
                                <div>
                                    <label className='invalid-error'>
                                        Select equipments is required
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='form-group'>
                        <button
                            type='button'
                            className='btn btn-secondary mr-1'
                            onClick={cancelUser}
                            data-testid='id1'
                        >
                            Cancel
                        </button>
                        <button type='submit' className='btn btn-primary' data-testid='updateUser'>
                            Update
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EditUser;
