import React from 'react';

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (props) => (
    <svg
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        width='18.111'
        height='20.736'
    >
        <defs>
            <style className='cls-1'>fill={props.fill || '#101027'}</style>
        </defs>
        <path d='M13.04,17.69c-1.12,0-2.03-.91-2.03-2.03s.91-2.03,2.03-2.03,2.03,.91,2.03,2.03-.91,2.03-2.03,2.03Zm0-3.33c-.71,0-1.3,.58-1.3,1.3s.58,1.3,1.3,1.3,1.3-.58,1.3-1.3-.58-1.3-1.3-1.3Z' />
        <path d='M16.1,22.62v-1.15c0-1.1-.9-2-2-2h-2.81v3.16h-.84v-2.77c0-1.1-.9-2-2-2h-2.42c-1.1,0-2,.9-2,2v2.77h-.84v-2.77c0-1.57,1.27-2.84,2.84-2.84h2.42c1.01,0,1.95,.54,2.46,1.42l.11,.19h3.08c1.57,0,2.84,1.27,2.84,2.84v1.15h-.84Z' />
        <path d='M6.98,16.07c-1.34,0-2.44-1.09-2.44-2.44s1.09-2.44,2.44-2.44,2.44,1.09,2.44,2.44-1.09,2.44-2.44,2.44Zm0-4.04c-.88,0-1.6,.72-1.6,1.6s.72,1.6,1.6,1.6,1.6-.72,1.6-1.6-.72-1.6-1.6-1.6Z' />
        <path
            className='cls-1'
            d='M15.46,8.17c-.12,0-.24-.05-.33-.14-.08-.08-.12-.19-.12-.3,0-.12,.04-.23,.12-.32,.08-.09,.2-.14,.32-.14,.14,0,.26,.05,.34,.13,.16,.18,.16,.45,0,.62-.08,.08-.2,.13-.33,.13Z'
        />
        <path d='M15.15,6.61c0-.19,.04-.38,.1-.56,.11-.19,.28-.37,.49-.5,.14-.1,.25-.22,.34-.35,.09-.14,.13-.31,.13-.48,.02-.23-.08-.47-.26-.64-.15-.11-.31-.16-.49-.16h0c-.32,0-.55,.12-.69,.32-.08,.14-.13,.29-.15,.44h-.63c.01-.36,.18-.71,.46-.95,.25-.2,.58-.31,.91-.31,.38,0,.68,.07,.94,.2,.4,.2,.64,.61,.6,1.05,0,.24-.06,.45-.18,.64-.15,.2-.33,.37-.53,.51-.13,.08-.25,.2-.34,.34-.07,.15-.1,.3-.11,.44h-.57Z' />
        <path
            className='cls-2'
            d='M13.4,9.36h-3.3V1.38h10.73v7.98h-3.16l-4.26,2.64v-2.64Zm.79,1.17l3.23-2.01,2.58,.02V2.2H10.91v6.34h3.28v1.99Z'
        />
    </svg>
);
