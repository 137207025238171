import { useHistory } from 'react-router';
import IconHome from 'assets/icons/icon-home';
import arrow from 'assets/images/Arrow.png';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import './Aboutus.css';
import React from 'react';

function AboutUsComponent() {
    const history = useHistory();

    return (
        <div className='m-6'>
            <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                    <li
                        className='breadcrumb-item'
                        data-testid='id'
                        onClick={() => history.push('/dashboard')}
                    >
                        <IconHome></IconHome>
                    </li>
                    <img src={arrow} />
                    <li
                        className='breadcrumb-item'
                        style={{ marginLeft: '5px', cursor: 'default' }}
                        aria-current='page'
                    >
                        About us
                    </li>
                </ol>
            </nav>
            <div className='card-body'>
                <h2 className='headStyle'>EcoTransparency app</h2>
                <br></br>
                <span>
                    A Step Towards CO<sub>2</sub> Neutral Future
                </span>
                <br></br>
                <span>
                    The EcoTransparency app provides life cycle assessment results of Siemens Energy
                    products, expressed in various impact categories like global warming potential,
                    ecotoxicity, ozone creation potential, etc. In addition, the EcoTransparency app
                    provides comparisons of different product related scenarios, to show how the
                    environmental impact changes, if we e.g. use ester instead of oil as an
                    insulation fluid in a transformer.
                </span>
                <br></br>
                <h6>Version</h6>
                <hr></hr>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                    >
                        <Typography>Version 1.6.1</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div>
                                <span className='fontStyle'>
                                    Graphical user interface improvements
                                </span>{' '}
                                <hr></hr>
                                <span className='fontStyle'>Product details page improvements</span>
                                <hr></hr>
                                <span className='fontStyle'>Circularity feature</span>
                                <hr></hr>
                                <span className='fontStyle'>Software components upgrades</span>{' '}
                                <hr></hr>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                    >
                        <Typography>Version 1.6.0</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div>
                                <span className='fontStyle'>
                                    Evaluation feature with related analytics
                                </span>{' '}
                                <hr></hr>
                                <span className='fontStyle'>PDF report improvements</span>
                                <hr></hr>
                                <span className='fontStyle'>
                                    Product details pages improvements
                                </span>
                                <hr></hr>
                                <span className='fontStyle'>
                                    Layout and graphical user interface improvements
                                </span>{' '}
                                <hr></hr>
                                <span className='fontStyle'>Software components upgrades</span>{' '}
                                <hr></hr>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                    >
                        <Typography>Version 1.5.1</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div>
                                <span className='fontStyle'>
                                    Layout user interface improvements
                                </span>{' '}
                                <hr></hr>
                                <span className='fontStyle'>User interface improvements</span>
                                <hr></hr>
                                <span className='fontStyle'>
                                    Feedback and 3rd party verification improvements
                                </span>
                                <hr></hr>
                                <span className='fontStyle'>
                                    ‘Preview report' option in equipment page for better
                                    confidentiality for admins
                                </span>{' '}
                                <hr></hr>
                                <span className='fontStyle'>
                                    Bug fixes and S/w components updates
                                </span>{' '}
                                <hr></hr>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                    >
                        <Typography>Version 1.5.0</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div>
                                <span className='fontStyle'>LCA graph features</span> <hr></hr>
                                <span className='fontStyle'>LCA table changes</span>
                                <hr></hr>
                                <span className='fontStyle'>User interface changes</span>
                                <hr></hr>
                                <span className='fontStyle'>Customer feedback section</span>{' '}
                                <hr></hr>
                                <span className='fontStyle'>Third party verification</span>{' '}
                                <hr></hr>
                                <span className='fontStyle'>
                                    Reduction in % values of single scenario vs baseline
                                </span>{' '}
                                <hr></hr>
                                <span className='fontStyle'>
                                    LCA template and report modifications
                                </span>{' '}
                                <hr></hr>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                    >
                        <Typography>Version 1.4.1</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div>
                                <span className='fontStyle'>User interface changes</span> <hr></hr>
                                <span className='fontStyle'>
                                    User management administration fix
                                </span>
                                <hr></hr>
                                <span className='fontStyle'>SE Report segregation fix</span>
                                <hr></hr>
                                <span className='fontStyle'>Data privacy fix</span> <hr></hr>
                                <span className='fontStyle'>Bug fixes</span> <hr></hr>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                    >
                        <Typography>Version 1.4.0</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div>
                                <h6>Feature 1</h6>
                                <span className='fontStyle'>
                                    Layout user interface and report template like naming,
                                    alignment, spacing issues{' '}
                                </span>
                                <hr></hr>
                                <h6>Feature 2</h6>
                                <span className='fontStyle'>SimaPro storage data into app</span>
                                <hr></hr>
                                <h6>Feature 3</h6>
                                <span className='fontStyle'>
                                    Update calculation equipment data including material composition
                                </span>
                                <hr></hr>
                                <h6>Feature 4</h6>
                                <span className='fontStyle'>
                                    Life cycle assessment, Detailed impact assessment, Material
                                    composition, Report, zooming life cycle assessment bar chart
                                </span>
                                <hr></hr>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                    >
                        <Typography>Version 1.3.1</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div>
                                <h6>Feature 1</h6>
                                <span className='fontStyle'>Introduction video about app</span>
                                <hr></hr>
                                <h6>Feature 2</h6>
                                <span className='fontStyle'>
                                    Frequently ask question (FAQ) in help menu
                                </span>
                                <hr></hr>
                                <h6>Feature 3</h6>
                                <span className='fontStyle'>Hover over elements</span>
                                <hr></hr>
                                <h6>Feature 4</h6>
                                <span className='fontStyle'>Display only scenario data</span>
                                <hr></hr>
                                <h6>Feature 5</h6>
                                <span className='fontStyle'>Landing, LCA page enhancement</span>
                                <hr></hr>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                    >
                        <Typography>Version 1.3.0</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div>
                                <h6>Feature 1</h6>
                                <span className='fontStyle'>User interface design changes</span>
                                <hr></hr>
                                <h6>Feature 2</h6>
                                <span className='fontStyle'>
                                    View impact categories based on selection
                                </span>
                                <hr></hr>
                                <h6>Feature 3</h6>
                                <span className='fontStyle'>Customization report</span>
                                <hr></hr>
                                <h6>Feature 4</h6>
                                <span className='fontStyle'>
                                    Enable/Disable equipment from Application
                                </span>
                                <hr></hr>
                                <h6>Feature 5</h6>
                                <span className='fontStyle'>
                                    Favorite and recently viewed equipment list
                                </span>
                                <hr></hr>
                                <h6>Feature 6</h6>
                                <span className='fontStyle'>Auto session logout</span>
                                <hr></hr>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                    >
                        <Typography>Version 1.2.0</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div>
                                <h6>Feature 1</h6>
                                <span className='fontStyle'>
                                    Compare product baseline with scenario model
                                </span>
                                <hr></hr>
                                <h6>Feature 2</h6>
                                <span className='fontStyle'>Sustainability wiki page</span>
                                <hr></hr>
                                <h6>Feature 3</h6>
                                <span className='fontStyle'>Emission calculator</span>
                                <hr></hr>
                                <h6>Feature 4</h6>
                                <span className='fontStyle'>Send report via email</span>
                                <hr></hr>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                    >
                        <Typography>Version 1.1.0</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div>
                                <h6>Feature 1</h6>
                                <span className='fontStyle'>Links in the login page</span>
                                <hr></hr>
                                <h6>Feature 2</h6>
                                <span className='fontStyle'>
                                    Content management in landing page
                                </span>
                                <hr></hr>
                                <h6>Feature 3</h6>
                                <span className='fontStyle'>
                                    Add 4 stages of lifecyle of an equipment{' '}
                                </span>
                                <hr></hr>
                                <h6>Feature 4</h6>
                                <span className='fontStyle'>Show sub-categories for LCA </span>
                                <hr></hr>
                                <h6>Feature 5</h6>
                                <span className='fontStyle'>
                                    Import functions to add descriptions
                                </span>
                                <hr></hr>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                    >
                        <Typography>Version 1.0.1</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div>
                                <h6>Feature 1</h6>
                                <span className='fontStyle'>
                                    Comparisons of product related scenario in a common bar chart
                                    plot area
                                </span>
                                <hr></hr>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                    >
                        <Typography>Version 1.0.0</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div>
                                <h6>Feature 1</h6>
                                <span className='fontStyle'>
                                    Carbon footprint of a given components
                                </span>
                                <hr></hr>
                                <h6>Feature 2</h6>
                                <span className='fontStyle'>Material composition monitoring</span>
                                <hr></hr>
                                <h6>Feature 3</h6>
                                <span className='fontStyle'>
                                    Compare baseline model with the enhanced model
                                </span>
                                <hr></hr>
                                <h6>Feature 4</h6>
                                <span className='fontStyle'>Generate app report</span>
                                <hr></hr>
                                <h6>Feature 5</h6>
                                <span className='fontStyle'>User management</span>
                                <hr></hr>
                                <h6>Feature 6</h6>
                                <span className='fontStyle'>Equipment settings</span>
                                <hr></hr>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
}

export default AboutUsComponent;
