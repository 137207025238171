import axios from 'axios';
import { getAuthHeader } from 'interface/AuthHeader';

export async function addUserDetail(data) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .post(process.env.REACT_APP_SERVER_URL + '/user/add', data, {
            headers: {
                Authorization: token.Authorization
            }
        })
        .then((response) => response.data);
}

export async function updateUserDetail(data) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });
    return axios
        .put(process.env.REACT_APP_SERVER_URL + '/user/update', data, {
            headers: {
                Authorization: token.Authorization
            }
        })
        .then((response) => response.data);
}

export async function getSelectedEquipments(email) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });

    return axios
        .get(process.env.REACT_APP_SERVER_URL + '/user/equipments?email=' + email, {
            headers: token
        })
        .then((response) => response.data);
}

export async function deleteUserDetail(mailId) {
    var token = await getAuthHeader().then((val) => {
        return val;
    });

    return axios
        .delete(process.env.REACT_APP_SERVER_URL + '/user/delete?email=' + mailId, {
            headers: token
        })
        .then((response) => response.data);
}
