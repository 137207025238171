import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PrivateSection from 'routes/PrivateSection';
import PublicRoutes from 'routes/PublicRoutes';
import { GlobalContext } from 'hooks/GlobalContext';

function Routes() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [state] = useContext(GlobalContext);
    return state.userIsLoggedIn ? <PrivateSection /> : <PublicRoutes />;
}

export default Routes;
