import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import './bootstrap.css';
import {
    getAllEquipmentLists,
    updateEquipment,
    updateAllEquipments,
    getDescriptiontemplate,
    sendEquipmentStatus,
    updateEquipmentCalculation,
    updateMaterialCompositionCalculation,
    sendEquipmentVerify,
    sendReportAccess,
    enableCircularity,
    updateDeleteEquipment,
    sendGwpAccess
} from './EquipmentService';
import LoadingComponent from 'components/loading';
import IconHome from 'assets/icons/icon-home';
import arrow from 'assets/images/Arrow.png';
import { useHistory } from 'react-router';
import downloadIcon from 'assets/images/pdfgenerate.png';
import trashIcon from 'assets/images/trash.svg';
import { Modal, Button } from 'react-bootstrap';
import IconTabinfo from 'assets/icons/icon-tabInfo';

function EquipmentSettings() {
    // form validation rules
    const validationSchema = Yup.object().shape({
        equipment: Yup.string().required('Equipment is required'),
        picture: Yup.mixed()
            .test('fileType', 'Please upload a supported format', function (value) {
                if (value.length !== 0) {
                    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
                    return SUPPORTED_FORMATS.includes(value[0].type);
                } else {
                    return true;
                }
            })
            .test('fileSize', 'File Size is too large', (value) => {
                if (value.length !== 0) {
                    const sizeInBytes = 5245676; //5MB
                    return value[0].size <= sizeInBytes;
                } else {
                    return true;
                }
            }),
        excelsheet: Yup.mixed().test('fileSize', 'File Size is too large', (item) => {
            if (item.length !== 0) {
                const sizeInBytes = 5245676; //5MB
                return item[0].size <= sizeInBytes;
            } else {
                return true;
            }
        })
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const [equipmentList, setEquipmentList] = React.useState([]);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [successMessage, setSuccessMessage] = React.useState('');
    const [showLoader, setLoaderState] = React.useState(false);
    const history = useHistory();
    const [showToggle, setShowToggle] = React.useState(false);
    const [pdfDetails, setPDFdetails] = useState();
    const [externalID, setExternalID] = useState('');
    const [enableStatus, setEnableStatus] = React.useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [updateMaterialPopupShow, setUpdateMaterialPopupShow] = React.useState(false);
    const [deletePopupShow, setDeletePopupShow] = React.useState(false);
    const handleClose = () => setModalShow(false);
    const handleUpdateMaterial = () => setUpdateMaterialPopupShow(false);
    const handleDeleteClose = () => setDeletePopupShow(false);
    const [changedEquipmentName, setChangedEqipmentName] = React.useState('');
    const [showVerifyBtn, setShowVerifyBtn] = React.useState(false);
    const [viewVerifyBox, setViewVerifyBox] = React.useState(false);
    const [showReportAccess, setShowReportAccess] = React.useState(false);
    const [showCircularity, setShowCircularity] = React.useState(false);
    const [showGWP, setShowGWP] = React.useState(false);
    const [showDelete, setShowDelete] = React.useState(false);

    useEffect(() => {
        setSuccessMessage('');
        setErrorMessage('');
        setLoaderState(true);
        getAllEquipmentLists().then((response) => {
            setEquipmentList(response.data);
        });
        getDescriptiontemplate()
            .then((response) => {
                setPDFdetails(response.data);
                setLoaderState(false);
            })
            .catch(function (error) {
                setLoaderState(false);
                setErrorMessage(error.response.data.message);
            });
    }, []);

    function onSubmit(data) {
        setSuccessMessage('');
        setErrorMessage('');
        setLoaderState(true);
        var obj = { id: externalID };
        const formData = new FormData();
        formData.append('data', JSON.stringify(obj));

        if (data.picture.length === 0 && data.excelsheet.length === 0) {
            setErrorMessage('Please upload either XLS or image');
            setLoaderState(false);
        } else if (data.picture.length === 0 && data.excelsheet.length !== 0) {
            formData.append('descriptionFile', data.excelsheet[0], data.excelsheet[0].name);
            equipmentUpdated(formData);
        } else if (data.picture.length !== 0 && data.excelsheet.length === 0) {
            formData.append('imageFile', data.picture[0], data.picture[0].name);
            equipmentUpdated(formData);
        } else {
            formData.append('descriptionFile', data.excelsheet[0], data.excelsheet[0].name);
            formData.append('imageFile', data.picture[0], data.picture[0].name);
            equipmentUpdated(formData);
        }

        function equipmentUpdated(formObj) {
            updateEquipment(formObj)
                .then((response) => {
                    setSuccessMessage(response.message);
                    setLoaderState(false);
                    reset();
                })
                .catch(function (error) {
                    setLoaderState(false);
                    setErrorMessage(error.response.data.message);
                });
        }
    }

    const updateEquipments = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setLoaderState(true);
        updateAllEquipments().then((response) => {
            if (response) {
                setSuccessMessage(response.message);
                setLoaderState(false);
            } else {
                setErrorMessage(response.message);
                setLoaderState(false);
            }
        });
    };
    const changeEquipmentStatus = (e) => {
        setShowToggle(true);
        if (e.target.value) {
            const listObj = JSON.parse(e.target.value);
            setExternalID(listObj.externalId);
            setEnableStatus(listObj.status);
            setChangedEqipmentName(listObj.isBaseline ? listObj.name : listObj.scenarioName);
            setShowVerifyBtn(!listObj.verify || !listObj.isBaseline ? true : false);
            setShowReportAccess(!listObj.reportAccess || !listObj.isBaseline ? true : false);
            setShowCircularity(listObj.circularEconomyFeature && listObj.isBaseline ? true : false);
            setShowGWP(listObj.gwpStatus && listObj.isBaseline ? true : false);
            setShowDelete(listObj.delete && listObj.isBaseline ? true : false);
            setViewVerifyBox(listObj.isBaseline ? true : false);
        } else {
            setShowToggle(false);
        }
    };
    const toggleEquipment = (e) => {
        setSuccessMessage('');
        setErrorMessage('');
        setEnableStatus(!e.target.checked);
        setLoaderState(true);
        sendEquipmentStatus(!e.target.checked, externalID)
            .then((response) => {
                setSuccessMessage(response.message);
                setLoaderState(false);
            })
            .catch(function (error) {
                setLoaderState(false);
                setErrorMessage(error.response.data.message);
            });
    };
    const verifyEquipment = (e) => {
        setSuccessMessage('');
        setErrorMessage('');
        setLoaderState(true);
        sendEquipmentVerify(e.target.checked, externalID)
            .then((response) => {
                setSuccessMessage(response.message);
                setLoaderState(false);
            })
            .catch(function (error) {
                setLoaderState(false);
                setErrorMessage(error.response.data.message);
            });
        if (e.target.checked) {
            setShowVerifyBtn(false);
        } else {
            setShowVerifyBtn(true);
        }
    };
    const reportAccessbility = (e) => {
        setSuccessMessage('');
        setErrorMessage('');
        setLoaderState(true);
        sendReportAccess(e.target.checked, externalID)
            .then((response) => {
                setSuccessMessage(response.message);
                setLoaderState(false);
            })
            .catch(function (error) {
                setLoaderState(false);
                setErrorMessage(error.response.data.message);
            });
        if (e.target.checked) {
            setShowReportAccess(false);
        } else {
            setShowReportAccess(true);
        }
    };
    const onCircularityChange = (e) => {
        setSuccessMessage('');
        setErrorMessage('');
        setLoaderState(true);
        enableCircularity(e.target.checked, externalID)
            .then((response) => {
                setSuccessMessage(response.message);
                setLoaderState(false);
            })
            .catch(function (error) {
                setLoaderState(false);
                setErrorMessage(error.response.data.message);
            });
        if (e.target.checked) {
            setShowCircularity(true);
        } else {
            setShowCircularity(false);
        }
    };
    const setGwpAccess = (e) => {
        setSuccessMessage('');
        setErrorMessage('');
        setLoaderState(true);
        sendGwpAccess(e.target.checked, externalID)
            .then((response) => {
                setSuccessMessage(response.message);
                setLoaderState(false);
            })
            .catch(function (error) {
                setLoaderState(false);
                setErrorMessage(error.response.data.message);
            });
        if (e.target.checked) {
            setShowGWP(true);
        } else {
            setShowGWP(false);
        }
    };

    const deleteChange = () => {
        setDeletePopupShow(true);
    };

    const updateDeleteData = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setLoaderState(true);
        updateDeleteEquipment(externalID)
            .then((response) => {
                setSuccessMessage(response.message);
                getAllEquipmentLists().then((response) => {
                    setEquipmentList(response.data);
                });
                setLoaderState(false);
                handleDeleteClose();
            })
            .catch(function (error) {
                setLoaderState(false);
                setErrorMessage(error.response.data.message);
                handleDeleteClose();
            });
    };

    const openCalculationPopup = () => {
        setModalShow(true);
    };
    const openUpdateMaterial = () => {
        setUpdateMaterialPopupShow(true);
    };

    const updateMaterialCompositionData = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setLoaderState(true);
        updateMaterialCompositionCalculation(externalID)
            .then((response) => {
                setSuccessMessage(response.message);
                setLoaderState(false);
                handleUpdateMaterial();
            })
            .catch(function (error) {
                setLoaderState(false);
                setErrorMessage(error.response.data.message);
                handleUpdateMaterial();
            });
    };

    const updateCalulation = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setLoaderState(true);
        updateEquipmentCalculation(externalID)
            .then((response) => {
                setSuccessMessage(response.message);
                setLoaderState(false);
                handleClose();
            })
            .catch(function (error) {
                setLoaderState(false);
                setErrorMessage(error.response.data.message);
                handleClose();
            });
    };
    return (
        <div className='m-10'>
            <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                    <li
                        className='breadcrumb-item'
                        data-testid='id'
                        onClick={() => history.push('/dashboard')}
                    >
                        <IconHome></IconHome>
                    </li>
                    <img src={arrow} />
                    <li
                        className='breadcrumb-item'
                        style={{ marginLeft: '5px', cursor: 'default' }}
                        aria-current='page'
                    >
                        Equipment management
                    </li>
                </ol>
            </nav>
            <div className='card-body'>
                <h2>Equipment management</h2>

                <LoadingComponent loading={showLoader} fullScreen='false' />
                <div>
                    {errorMessage && (
                        <div>
                            <label className='text-danger'> {errorMessage}</label>
                        </div>
                    )}
                </div>
                <div>
                    {successMessage && (
                        <div>
                            <label className='text-success'> {successMessage}</label>
                        </div>
                    )}
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <br></br>
                    <div className='form-row'>
                        <button
                            data-testid='updateEquipment'
                            type='button'
                            className='btn btn-primary float-right'
                            onClick={() => updateEquipments()}
                        >
                            Update equipments
                        </button>
                        &nbsp;
                        <span
                            className='align-self-center'
                            title='If equipment list is not available please click on update equipments button.'
                        >
                            <IconTabinfo></IconTabinfo>
                        </span>
                    </div>

                    <div className='form-row'>
                        <div className='form-group' data-testid='content-select'>
                            <label className='labelBold'>Select equipment</label>
                            <label style={{ color: 'red' }}>
                                <b>*</b>
                            </label>
                            <select
                                data-testid='equipment_testId'
                                name='equipment'
                                {...register('equipment')}
                                className={`form-control ${errors.equipment ? 'is-invalid' : ''}`}
                                onChange={(e) => changeEquipmentStatus(e)}
                            >
                                <option value=''>Select equipments </option>
                                {equipmentList.map((list) => (
                                    <option
                                        key={list.externalId}
                                        value={JSON.stringify(list)}
                                        className={`${list.isBaseline ? 'selectFont' : ''}`}
                                    >
                                        {!list.isBaseline
                                            ? list.name + '(Scenario) :' + list.scenarioName
                                            : list.name}
                                    </option>
                                ))}
                            </select>
                            <div className='invalid-feedback'>{errors.equipment?.message}</div>
                        </div>
                        &nbsp;&nbsp;
                        {showToggle && (
                            <div className='toggleAlign'>
                                <label className='switch'>
                                    <input
                                        data-testid='toggleStatus'
                                        type='checkbox'
                                        onChange={(e) => toggleEquipment(e)}
                                        checked={!enableStatus}
                                    />
                                    <span className='slider'></span>
                                </label>{' '}
                                {!enableStatus ? (
                                    <label className='align-self-center switchLabel'>
                                        Equipment enabled
                                    </label>
                                ) : (
                                    <label className='align-self-center switchLabel'>
                                        Equipment disabled
                                    </label>
                                )}
                            </div>
                        )}
                    </div>

                    {showToggle && (
                        <div className='form-row'>
                            <button
                                className='btn btn-primary'
                                type='button'
                                onClick={() => openCalculationPopup()}
                                data-testid='calculationClick'
                            >
                                Update calculation
                            </button>{' '}
                            &nbsp;&nbsp;
                            <span
                                className='align-self-center'
                                title='Click to update calculation result data if something change in simapro.'
                            >
                                <IconTabinfo></IconTabinfo>
                            </span>{' '}
                            &nbsp;&nbsp;{' '}
                            <button
                                className='btn btn-primary'
                                type='button'
                                onClick={() => openUpdateMaterial()}
                                data-testid='updateMaterialCLick'
                            >
                                Update material composition
                            </button>{' '}
                            &nbsp;&nbsp;
                            <span
                                className='align-self-center'
                                title='Click to update the material composition of selected equipment.'
                            >
                                <IconTabinfo></IconTabinfo>
                            </span>{' '}
                            &nbsp;&nbsp;
                            {viewVerifyBox && (
                                <div className='align-self-end'>
                                    <input
                                        type='checkbox'
                                        id='verify'
                                        name='verify'
                                        value='verify'
                                        checked={!showVerifyBtn}
                                        onChange={(e) => verifyEquipment(e)}
                                        data-testid='verifyClick'
                                    />
                                    &nbsp;
                                    <label>Third party verified</label>
                                    &nbsp;&nbsp;
                                </div>
                            )}
                            {viewVerifyBox && (
                                <div className='align-self-end'>
                                    <input
                                        type='checkbox'
                                        id='access'
                                        name='access'
                                        value='access'
                                        onChange={(e) => reportAccessbility(e)}
                                        checked={!showReportAccess}
                                        data-testid='accessReportClick'
                                    />
                                    &nbsp;
                                    <label>Preview report</label>
                                    &nbsp;&nbsp;
                                </div>
                            )}
                            {viewVerifyBox && (
                                <div className='align-self-end'>
                                    <input
                                        type='checkbox'
                                        id='gwpAccess'
                                        name='gwpAccess'
                                        value='gwpAccess'
                                        onChange={(e) => setGwpAccess(e)}
                                        checked={showGWP}
                                        data-testid='gwpClick'
                                    />
                                    &nbsp;
                                    <label>GWP only</label>
                                    &nbsp;&nbsp;
                                </div>
                            )}
                            {viewVerifyBox && (
                                <div className='align-self-end'>
                                    <input
                                        type='checkbox'
                                        id='circularity'
                                        name='circularity'
                                        value='circularity'
                                        onChange={(e) => onCircularityChange(e)}
                                        checked={showCircularity}
                                        data-testid='circularityClick'
                                    />
                                    &nbsp;
                                    <label>Circularity potential</label>
                                    &nbsp;&nbsp;
                                </div>
                            )}
                            <div
                                className='align-self-end'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <label
                                    data-testid='deleteButton'
                                    onClick={deleteChange}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <img
                                        src={trashIcon}
                                        style={{ marginRight: '5px', cursor: 'pointer' }}
                                    />{' '}
                                    Delete equipment
                                </label>
                                &nbsp;&nbsp;
                            </div>
                        </div>
                    )}

                    <div className='form-row'>
                        <div className='form-group'>
                            <label className='labelBold'>Upload description</label>
                            <input
                                data-testid='excelFile'
                                name='excelsheet'
                                type='file'
                                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv'
                                {...register('excelsheet')}
                                className={`form-control ${errors.excelsheet ? 'is-invalid' : ''}`}
                            />
                            <div className='invalid-feedback'>{errors.excelsheet?.message}</div>
                        </div>
                        &nbsp;&nbsp;
                        <img src={downloadIcon} style={{ objectFit: 'none' }} />
                        <a className='template align-self-center' href={pdfDetails} download>
                            Description template
                        </a>
                        &nbsp;&nbsp;
                        <span
                            className='align-self-center'
                            title='Please click "Description template" link to download the required file
                            format for adding description.'
                        >
                            <IconTabinfo></IconTabinfo>
                        </span>
                    </div>
                    <div className='form-row'>
                        <div className='form-group'>
                            <label className='labelBold'>Upload image</label>
                            <input
                                data-testid='image'
                                name='picture'
                                type='file'
                                accept='.png, .jpg, .jpeg'
                                {...register('picture')}
                                className={`form-control ${errors.picture ? 'is-invalid' : ''}`}
                            />
                            <div className='invalid-feedback'>{errors.picture?.message}</div>
                        </div>
                    </div>
                    <label>
                        File size should not exceed 5MB, approved formats are .PNG,.JPEG,.JPG
                    </label>
                    <br></br>
                    <div className='form-group'>
                        <button
                            type='button'
                            onClick={() => reset()}
                            className='btn btn-secondary mr-1'
                            data-testid='formReset'
                        >
                            Cancel
                        </button>
                        <button
                            type='submit'
                            className='btn btn-primary'
                            data-testid='submitSettings'
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
            <Modal
                size='sm'
                aria-labelledby='contained-modal-title-vcenter'
                show={modalShow}
                onHide={handleClose}
                centered
                closebutton='true'
            >
                {' '}
                <Modal.Header closebutton='true'> </Modal.Header>
                <Modal.Body>
                    Would you like to update the {changedEquipmentName} data from simapro ?<br></br>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={handleClose} data-testid='closeInfo'>
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            data-testid='updateClick'
                            onClick={() => updateCalulation()}
                        >
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
            <Modal
                size='sm'
                aria-labelledby='contained-modal-title-vcenter'
                show={updateMaterialPopupShow}
                onHide={handleUpdateMaterial}
                centered
                closebutton='true'
            >
                {' '}
                <Modal.Header closebutton='true'> </Modal.Header>
                <Modal.Body>
                    Would you like to update the {changedEquipmentName} material composition data
                    from simapro ?<br></br>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            onClick={handleUpdateMaterial}
                            data-testid='closeMaterialClick'
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            data-testid='updateMaterialBtnClick'
                            onClick={() => updateMaterialCompositionData()}
                        >
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
            <Modal
                size='md'
                aria-labelledby='contained-modal-title-vcenter'
                show={deletePopupShow}
                onHide={handleDeleteClose}
                centered
                closebutton='true'
            >
                {' '}
                <Modal.Header closebutton='true' style={{ fontSize: '19px', fontWeight: 'bold' }}>
                    {' '}
                    Delete equipment?
                </Modal.Header>
                <Modal.Body style={{ fontSize: '19px' }}>
                    <br></br>
                    <br></br>
                    The selected equipment will be permanently removed from the application.
                    <br></br>
                    <br></br>
                    <br></br>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            onClick={handleDeleteClose}
                            data-testid='closeDeleteClick'
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='primary'
                            data-testid='deleteBtnClick'
                            onClick={() => updateDeleteData()}
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default EquipmentSettings;
