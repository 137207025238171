import AWS from 'aws-sdk';

AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
    })
});

const AwsCognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-18'
});

export default AwsCognitoIdentityServiceProvider;
