/**
 * The PrivedRoutes Component handels all possible routes.
 * Routing is used for linking the Sidebar-MenuItems to Components
 * on the right handside canvas.
 *
 * @author Sivaguru
 */

import React, { Suspense, lazy } from 'react';
import { Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import LoadingComponent from 'components/loading';
import AutoLogoutTimer from 'components/autoLogout/AutoLagoutTimer';

const DashboardComponent = lazy(() => import('../components/dashboard'));
const CompareEquipmentData = lazy(() =>
    import('../components/compareEquipments/CompareEquipmentDetail')
);
const ListUser = lazy(() => import('../components/usermanagement/ListUser'));
const AddUser = lazy(() => import('../components/usermanagement/AddUser'));
const EditUser = lazy(() => import('../components/usermanagement/EditUser'));
const EquipmentSettings = lazy(() => import('../components/equipments/EquipmentSettings'));
const Aboutus = lazy(() => import('../components/aboutus/AboutUsComponent'));
const ContentManage = lazy(() => import('../components/contentmanagement/ManageContent'));
const CircularityConfig = lazy(() => import('../components/circularityConfig/CircularityConfig'));
const AddContent = lazy(() => import('../components/contentmanagement/AddContent'));

/**;
 * Defining new Routes using private routes function
 * linking Components to the selected Menuitem in the "Sidebar component"
 */
function PrivateRoutes() {
    return (
        <Router history={useHistory()}>
            <Suspense fallback={<LoadingComponent loading />}>
                <Switch>
                    <Route exact path={SLUGS.dashboard} element={<DashboardComponent />}>
                        <AutoLogoutTimer ComposedClass={DashboardComponent} />
                    </Route>

                    <Route
                        exact
                        path={SLUGS.compareEquipmentData}
                        element={<CompareEquipmentData />}
                    >
                        <AutoLogoutTimer ComposedClass={CompareEquipmentData} />
                    </Route>

                    <Route exact path={SLUGS.listuser} element={<ListUser />}>
                        <AutoLogoutTimer ComposedClass={ListUser} />
                    </Route>

                    <Route exact path={SLUGS.adduser} element={<AddUser />}>
                        <AutoLogoutTimer ComposedClass={AddUser} />
                    </Route>

                    <Route exact path={SLUGS.edituser} element={<EditUser />}>
                        <AutoLogoutTimer ComposedClass={EditUser} />
                    </Route>

                    <Route exact path={SLUGS.equipmentsetttings} element={<EquipmentSettings />}>
                        <AutoLogoutTimer ComposedClass={EquipmentSettings} />
                    </Route>

                    <Route exact path={SLUGS.aboutus} element={<Aboutus />}>
                        <AutoLogoutTimer ComposedClass={Aboutus} />
                    </Route>

                    <Route exact path={SLUGS.manageContent} element={<ContentManage />}>
                        <AutoLogoutTimer ComposedClass={ContentManage} />
                    </Route>

                    <Route exact path={SLUGS.circularityConfig} element={<CircularityConfig />}>
                        <AutoLogoutTimer ComposedClass={CircularityConfig} />
                    </Route>

                    <Route exact path={SLUGS.addContent} element={<AddContent />}>
                        <AutoLogoutTimer ComposedClass={AddContent} />
                    </Route>

                    <Redirect to={SLUGS.dashboard} />
                </Switch>
            </Suspense>
        </Router>
    );
}

export default PrivateRoutes;
