import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import AwsCognitoIdentityServiceProvider from 'components/aws/AwsCognitoIdentityServiceProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import LoadingComponent from 'components/loading';
import './bootstrap.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { MultiSelect } from 'components/multiselectAll/MultiSelect';
import { getAllEquipmentLists } from 'components/equipments/EquipmentService';
import { addUserDetail } from './UserManagementService';
import { GetUserManagementBreadCrumb } from 'interface/projectInterface';

function AddUser() {
    // form validation rules
    const validationSchema = Yup.object().shape({
        given_name: Yup.string()
            .min(3, 'First name must be minimum 3 characters')
            .max(30, 'First name must be 30 characters')
            .required('First Name is required'),
        family_name: Yup.string()
            .min(3, 'Last name must be minimum 3 characters')
            .max(30, 'Last name must be 30 characters')
            .required('Last name is required'),
        email: Yup.string().required('Email is required').email('Email is invalid'),
        role: Yup.string().required('User Role is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const [showLoader, setLoaderState] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const history = useHistory();
    const [phoneValue, setPhoneValue] = useState('');
    const [phoneValuePresent, setPhoneValuePresent] = useState(true);
    const [projectPresent, setProjectPresent] = useState(true);
    const [visibleEquipment, setVisibleEquipment] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);

    useEffect(() => {
        getAllEquipmentLists().then((response) => {
            var projectArray = [];
            response.data.forEach((list) => {
                if (list.isBaseline) {
                    let obj = {};
                    obj.id = list.id;
                    obj.value = list.externalId;
                    obj.label = list.name;
                    obj.isBaseline = list.isBaseline;
                    projectArray.push(obj);
                    setVisibleEquipment(projectArray);
                }
            });
        });
    }, []);
    const submitForm = (formData) => {
        setLoaderState(true);
        var userAttributes = [
            {
                Name: 'given_name',
                Value: formData.FirstName
            },
            {
                Name: 'family_name',
                Value: formData.LastName
            },
            {
                Name: 'email',
                Value: formData.Email
            },
            {
                Name: 'email_verified',
                Value: 'true'
            },
            {
                Name: 'phone_number',
                Value: '+' + formData.Phone
            },
            {
                Name: 'phone_number_verified',
                Value: 'true'
            }
        ];
        var params = {
            UserPoolId: process.env.REACT_APP_USERPOOL_ID,
            Username: formData.Email,
            UserAttributes: userAttributes,
            DesiredDeliveryMediums: ['EMAIL']
        };
        var listedProjects = [];
        formData.ExternalId.forEach((element) => {
            listedProjects.push(element.value);
        });
        var userDetails = {
            email: formData.Email,
            firstName: formData.FirstName,
            lastName: formData.LastName,
            phoneNumber: '+' + formData.Phone,
            equipmentsIds: listedProjects
        };

        AwsCognitoIdentityServiceProvider.adminCreateUser(params, function (err, data) {
            if (err) {
                setLoaderState(false);
                setErrorMessage(err.message);
            } else {
                var groupParamName = {
                    UserPoolId: process.env.REACT_APP_USERPOOL_ID,
                    Username: formData.Email,
                    GroupName: formData.Role
                };
                AwsCognitoIdentityServiceProvider.adminAddUserToGroup(
                    groupParamName,
                    function (errObj, dataObj) {
                        if (errObj) {
                            setErrorMessage(errObj.message);
                            setLoaderState(false);
                        } else {
                            var mfaRequest = {
                                SMSMfaSettings: {
                                    Enabled: true,
                                    PreferredMfa: true
                                },
                                UserPoolId: process.env.REACT_APP_USERPOOL_ID,
                                Username: formData.Email
                            };

                            AwsCognitoIdentityServiceProvider.adminSetUserMFAPreference(
                                mfaRequest,
                                function (errMfaObj, dataMfaObj) {
                                    if (errMfaObj) {
                                        setLoaderState(false);
                                        setErrorMessage(errMfaObj.message);
                                    } else {
                                        addUserDetail(userDetails)
                                            .then((response) => {
                                                setSuccessMessage(response.message);
                                                reset();
                                                setLoaderState(false);
                                                setPhoneValue('');
                                                setSelectedProjects([]);
                                            })
                                            .catch(function (error) {
                                                setLoaderState(false);
                                                setErrorMessage(error.response.data.message);
                                            });
                                    }
                                }
                            );
                        }
                    }
                );
            }
        });
    };
    function onSubmit(data) {
        setErrorMessage('');
        setSuccessMessage('');
        if (!phoneValue) {
            setPhoneValuePresent(false);
            return;
        }
        if (selectedProjects.length === 0) {
            setProjectPresent(false);
            return;
        }
        setPhoneValuePresent(true);
        setProjectPresent(true);
        // display form data on success
        const formdetails = {
            FirstName: data.given_name,
            LastName: data.family_name,
            Phone: phoneValue,
            Email: data.email,
            Role: data.role,
            ExternalId: selectedProjects
        };
        submitForm(formdetails);
    }
    const cancelUser = () => {
        history.push('/list-user');
    };
    const onChangeEquipmentlist = (data, actionMetaData) => {
        setSelectedProjects(data, actionMetaData);
    };
    return (
        <div className='m-10'>
            {GetUserManagementBreadCrumb('Add')}
            <div className='card-body'>
                <h2>Onboard user</h2>
                <br></br>
                <h4>Create user accounts, update their information and assign roles.</h4>
                <br></br>
                <LoadingComponent loading={showLoader} fullScreen='false' />
                <div>
                    {successMessage && (
                        <div>
                            {' '}
                            <label className='text-success'>{successMessage}</label>{' '}
                        </div>
                    )}
                </div>
                <div>
                    {errorMessage && (
                        <div>
                            <label className='text-danger'> {errorMessage}</label>
                        </div>
                    )}
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-row'>
                        <div className='form-group col-5'>
                            <label>First name</label>
                            <input
                                name='given_name'
                                type='text'
                                {...register('given_name')}
                                className={`form-control ${errors.given_name ? 'is-invalid' : ''}`}
                                data-testid='givenname'
                            />
                            <div className='invalid-feedback'>{errors.given_name?.message}</div>
                        </div>
                        <div className='form-group col-5'>
                            <label>Last name</label>
                            <input
                                name='family_name'
                                type='text'
                                {...register('family_name')}
                                className={`form-control ${errors.family_name ? 'is-invalid' : ''}`}
                                data-testid='familyname'
                            />
                            <div className='invalid-feedback'>{errors.family_name?.message}</div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group col-5'>
                            <label>Phone number</label>
                            <PhoneInput
                                country={'de'}
                                value={phoneValue}
                                placeholder='Enter phone number'
                                onChange={(phone) => setPhoneValue(phone)}
                                name='phone'
                                data-testid='phonedata'
                            />
                            {!phoneValuePresent && (
                                <div>
                                    <label className='invalid-error'>
                                        Phone number is required
                                    </label>
                                </div>
                            )}
                        </div>
                        <div className='form-group col-5'>
                            <label>Email</label>
                            <input
                                name='email'
                                type='text'
                                {...register('email')}
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                data-testid='mailid'
                            />
                            <div className='invalid-feedback'>{errors.email?.message}</div>
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className='form-group col-5'>
                            <label>User role</label>
                            <select
                                name='role'
                                {...register('role')}
                                data-testid='selectRole'
                                className={`form-control ${errors.role ? 'is-invalid' : ''}`}
                            >
                                <option value=''></option>
                                <option value='ROLE_ADMIN'>ROLE_ADMIN</option>
                                <option value='ROLE_USER'>ROLE_USER</option>
                            </select>
                            <div className='invalid-feedback'>{errors.role?.message}</div>
                        </div>
                        <div className='form-group col-5'>
                            <label>Select equipments</label>
                            <MultiSelect
                                options={visibleEquipment}
                                value={selectedProjects}
                                onChange={onChangeEquipmentlist}
                            ></MultiSelect>
                            {!projectPresent && (
                                <div>
                                    <label className='invalid-error'>
                                        Select equipments is required
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='form-group'>
                        <button
                            type='button'
                            className='btn btn-secondary mr-1'
                            data-testid='id1'
                            onClick={cancelUser}
                        >
                            Cancel
                        </button>
                        <button type='submit' className='btn btn-primary'>
                            Send invite
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddUser;
